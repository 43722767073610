const hostViewReducer = (state, action) => {
    switch(action.type){
        case 'shows':
            return {
                ...state,
                ...{
                    shows: action.shows
                }
            }
        case 'pastShows':
            return {
                ...state,
                ...{
                    pastShows: action.pastShows
                }
            }
        case 'experiences':
            return {
                ...state,
                ...{
                    experiences: action.experiences
                }
            }
        case 'hosts':
            return {
                ...state,
                ...{
                    hosts: action.hosts
                }
            }
        case 'privateShows':
            return {
                ...state,
                ...{
                    privateShows: action.privateShows
                }
            }
        case 'calendarValue':
            return {
                ...state,
                ...{
                    calendarValue: action.calendarValue,
                    formatDate: action.formatDate
                }
            }
        case 'receivedTickets':
            return {
                ...state,
                ...{
                    tickets: action.tickets
                }
            }
        case 'shouldShowHostCreatedBy':
            return {
                ...state,
                ...{
                    shouldShowHostCreatedBy: action.shouldShowHostCreatedBy
                }
            }
        case 'newSelectedRow':
            return {
                ...state,
                ...{
                    selectedRow: action.selectedRow
                }
            }
        case 'shouldShowMoreShowInfo':
            return {
                ...state,
                ...{
                    shouldShowMoreShowInfo: action.shouldShowMoreShowInfo
                }
            }
        case 'shouldShowShowsCreateEdit':
            return {
                ...state,
                ...{
                    shouldShowShowsCreateEdit: action.shouldShowShowsCreateEdit
                }
            }
        case 'shouldShowSpeedDialOpen':
            return {
                ...state,
                ...{
                    shouldShowSpeedDialOpen: action.shouldShowSpeedDialOpen
                }
            }
        case 'showExperienceEditor':
            return {
                ...state,
                ...{
                    showExperienceEditor: action.showExperienceEditor
                }
            }
        case 'isShowEditMode':
            return {
                ...state,
                ...{
                    isShowEditMode: action.isShowEditMode
                }
            }
        case 'newShowCreated':
            return {
                ...state,
                ...{
                    shows: [action.newShowCreated, ...state.shows]
                }
            }
        case 'newBookedShowsCreated':
            return {
                ...state,
                ...{
                    bookedShows: [action.newBookedShowsCreated, ...state.bookedShows]
                }
            }
        case 'showDeleted':
            return {
                ...state,
                ...{
                    shows: state.shows.filter(show => show.id !== action.showDeleted.id)
                }
            }
            
        case 'showUpdated':
            return {
                ...state,
                ...{
                    shows: state.shows.map(show => {
                        if (show.id === action.showUpdated.id) {
                            return action.showUpdated;
                        } else {
                            return show;
                        }
                    })
                }
            }
        case 'searchTerms':
            return {
                ...state,
                ...{
                    searchTerms: action.searchTerms
                }
            }
        case 'timeZoneValue':
            return {
                ...state,
                ...{
                    timeZoneValue: action.timeZoneValue
                }
            }
        case 'showProfileEditor':
            return {
                ...state,
                ...{
                    showProfileEditor: action.showProfileEditor
                }
            }
        case 'showEmailEditor':
            return {
                ...state,
                ...{
                    showEmailEditor: action.showEmailEditor
                }
            }
        case 'showCalendarEditor':
            return {
                ...state,
                ...{
                    showCalendarEditor: action.showCalendarEditor
                }
            }
        case 'showAdministration':
            return {
                ...state,
                ...{
                    showAdministration: action.showAdministration
                }
            }
        case 'profileKey':
            return {
                ...state,
                ...{
                    profileKey: action.profileKey
                }
            }
        case 'showShowsList':
            return {
                ...state,
                ...{
                    showShowsList: action.showShowsList
                }
            }
        case 'showShowsCalendar':
            return {
                ...state,
                ...{
                    showShowsCalendar: action.showShowsCalendar
                }
            }
        case 'emailState':
            return {
                ...state,
                ...{
                    emailState: {
                        ...state.emailState,
                        ...action.emailState
                    }
                }
            }
        case 'calendarState':
            return {
                ...state,
                ...{
                    calendarState: {
                        ...state.calendarState,
                        ...action.calendarState
                    }
                }
            }
        default:
            console.log("Bad Log: " + action);
            return state;
    }
}

export default hostViewReducer;