import React, {useContext, useState, useRef, useEffect} from 'react';

import GuestViewContext from '../../../Context/GuestViewContext';

import jasper from '../../../images/jasper.jpg';
import max from '../../../images/max.jpg';
import ari from '../../../images/ari.jpg';
import jacquie from '../../../images/jacquie.jpg';
import genevieve from '../../../images/genevieve.jpg';

import crumb_image from '../../../images/crumb_image.jpg';
import dragonslayer from '../../../images/dragonslayer.jpg';
import shadows from '../../../images/shadows.jpg';
import bragging_rights from '../../../images/bragging-rights.jpg';
import haunted from '../../../images/haunted_house.png';
import {
    Avatar,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AirplaneTicket, AttachMoney, Info, LocalActivity, People } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import Order from '../Order/Order';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactPlayer from "react-player"
import HostInfoDialog from '../Dialog/HostInfoDialog';
import ShowInfoDialog from '../Dialog/ShowInfoDialog';
import moment from 'moment';

// TODO:  All of this should be coming from the database not here:
const IMAGES = {
    jasper: jasper,
    max: max,
    jacquie: jacquie,
    ari: ari,
    genevieve: genevieve
}

const NUMBER_OF_TICKETS = 8;

const EXPERIENCES = {
    "dr. crumb": {
        text: <span>Dr. Crumb's School for disobedient Pets</span>,
        fullText: "Dr. Crumb's School for disobedient Pets",
        image: crumb_image
    },
    "dragon slayer": {
        text: <span>Dragon Slayer</span>,
        fullText: "Dr. Crumb's School for disobedient Pets",
        image: dragonslayer
    },
    "shadows": {
        text: <span>Shadows</span>,
        fullText: "Shadows",
        image: shadows
    },
    "bragging rights": {
        text: <span>Bragging Rights</span>,
        fullText: "Bragging Rights",
        image: bragging_rights
    },
    "haunted": {
        text: <span>Haunted</span>,
        fullText: "Haunted",
        image: haunted
    }
}

const HOSTS = {
    jasper: 'Jasper',
    max: 'Max',
    jacquie: 'Jacquie',
    ari: 'Ari',
    genevieve: 'Genevieve'
}

const TIME_TILL_RELOAD_MS = 60000;

const useStyles = makeStyles((theme) => {
    return {
    root: {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary,
        textDecoration: 'none',
        filter: props => {
            if (!props.isShowBookable || props.getNumberOfTicketsLeft() === 0) {
                return 'grayscale(100%)'
            }
        },
        minHeight: '250px',
    },
    avatar: {
        outline: "10px solid " + theme.palette.primary 
    },
    avatarLabel: {
        
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    media: {
        minHeight: '150px',
        paddingTop: 56.25 / 4 + '%', // 16:9
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end'
    },
    textTitle: {
        color: 'white',
        padding: '3%',
        fontWeight: 'bold',
        textDecoration: 'none'
    },
    link: {
        textDecoration: 'none'
    },
    booked: {
        position: 'absolute',
        zIndex: 100,
        bottom: '0',
        left: '10%'
    },
    description: {
        whiteSpace: 'pre-wrap'
    },
    cardAction: {
        minHeight: '75px',
    },
    closeIcon: {
        position: 'absolute',
        top: '0.5%',
        right: '0.5%'
    },
    buyButton: {
        width: '100%'
    },
    cardContent: {
        padding: '1% 1%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    cardContentInner: {
        padding: '1% 1%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    cardHeaderOveride: {
        [theme.breakpoints.between('md', 1000)]: {
            padding: 0
        },
    },
    cardActionInfo: {
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'relative',
        top: '1.0em',
    },
    cardAvatar: {
        [theme.breakpoints.between('md', 1000)]: {
            marginRight: 0
        },
    },
    container: {
        minWidth: '50vw',
    },
    iconBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    chip: {
        margin: '0 1% 0 1%',
    },
    title: {
        textAlign: 'center',
        marginRight: '25%'
    },
    subheader: {
        textAlign: 'center',
        marginRight: '25%'
    }
  };}
);

function EventBoardShow({show, day}){
    const guestViewState = useContext(GuestViewContext);
    const experience = show.experience;

    const params = new URLSearchParams(window.location.search);
    const disableBookableCheck = params.get("disableBookableCheck") == "true";
    
    let isShowBookable = true;
    if (!disableBookableCheck) {
        const momentShowDate = moment(show.showtime);
        const momentNowDate = moment(Date.now());
        const momentDiff = momentShowDate.diff(momentNowDate, 'days');
        isShowBookable = momentDiff > 0;
    }
    const classes = useStyles({show, isShowBookable, getNumberOfTicketsLeft});

    const [showDescription, setShowDescription] = useState(false);
    const [showHostInfo, setShowHostInfo] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [timeLoaded, setTimeLoaded] = useState(Date.now());
    const [avatarImage, setAvatarImage] = useState(null);
    const [buyPrivateShowClicked, setBuyPrivateShowClicked] = useState(false);

    const dialogShowInfoRef = useRef(null);
    const dialogHostInfoRef = useRef(null);
    
    const greaterThanSm = useMediaQuery(theme => theme.breakpoints.up("sm"));

    function handleBuyShow(shouldBuyPrivateShow){
        guestViewState.dispatch({
            type: 'buyShowClicked',
            showToOrder: show
        })
        setShowOrder(true);
        if (shouldBuyPrivateShow) {
            setBuyPrivateShowClicked(true);
        }
    }

    function onDialogEntered(ref){
        if (greaterThanSm) {
            disableBodyScroll(ref.current);
        }
    }

    function onCloseDialog(setCloseFunction){
        setCloseFunction(false);
        clearAllBodyScrollLocks();
        setTimeLoaded(Date.now());
    }

    function showHostButtonClicked(){
        window.dataLayer.push({'event': 'host-info-clicked'});
        setShowHostInfo(true);
    }

    function showInfoButtonCliecked(){
        window.dataLayer.push({'event': 'show-info-clicked', 'host': show.host.name})
        setShowDescription(true);
    }

    function getNumberOfTicketsLeft(){
        if (!isShowBookable) {
            return 0; 
        }
        const validOrders = show.orders?.items?.filter(order => order.status === "PAID");
        
        let totalTicketsPurchased = 0;
        validOrders.forEach(order => {
            totalTicketsPurchased += order.totalTicketsPurchased;
        })
        return show.experience.maxPlayers - totalTicketsPurchased;
    }

    return <>
        <Card className={classes.root} raised={true}>
            <CardHeader
                classes={{
                    root: classes.cardHeaderOveride,
                    avatar: classes.cardAvatar,
                    action: classes.cardActionInfo,
                    title: classes.title,
                    subheader: classes.subheader
                }}
                className={classes.cardHeader}
                avatar={
                    <IconButton onClick={showHostButtonClicked} size="large">
                        <div className={classes.iconBlock}>
                            <Avatar 
                                src={guestViewState.hostImages[show.host.hostName]} 
                                classes={{circular: classes.avatar}} 
                                variant="circular"
                                size="small"
                            />
                            <Typography color="primary" className={classes.avatarLabel} variant="button">
                                Host Info
                            </Typography>
                        </div>
                    </IconButton>
                }
                action={
                    <IconButton onClick={showInfoButtonCliecked} color="primary" size="medium">
                        <Info fontSize="large"/>
                    </IconButton>
                }
                title={`${show.displayTime.replace(":00", "")}`}
                subheader={show.host.hostName}
            >
            </CardHeader>

            <CardContent className={classes.cardContent}>
                <div className={classes.cardContentInner}>
                    <Chip className={classes.chip} size="small" icon={<People/>} label={`${experience.minPlayers} - ${experience.maxPlayers}`} variant="outlined" />
                    <Chip className={classes.chip} size="small" icon={<AttachMoney/>} label={`${experience.singleTicketPrice / 100}/ticket`} variant="outlined" />
                    {getNumberOfTicketsLeft() === experience.maxPlayers &&
                    <Chip className={classes.chip} size="small" icon={<AttachMoney/>} label={`${experience.privateShowBookingPrice / 100}/private show`} variant="outlined" />
                    }
                </div>
                <Chip className={classes.chip} size="small" icon={<LocalActivity/>} label={`Tickets Left: ${getNumberOfTicketsLeft()}`} variant="outlined" />
            </CardContent>
            
            <CardActionArea onClick={handleBuyShow} disabled={show.status !== 'OPEN' || !isShowBookable}>
                <CardMedia
                    image={guestViewState.experienceImages[show.experience.shortName]}
                    title="test"
                    className={classes.media}
                >
                    <Typography  variant="h5" className={classes.textTitle}>
                        {experience.longName}
                    </Typography>
                </CardMedia>
            </CardActionArea>

            <CardActions className={classes.cardAction}>
                {isShowBookable && getNumberOfTicketsLeft() > 0 ?
                    <>
                        <Button onClick={() => handleBuyShow(false)} variant="contained" color="primary" className={classes.buyButton}>
                            <Typography align="center" variant="h6" style={{color: 'white'}}>
                                Buy TICKET
                            </Typography>
                        </Button>
                    </>
                    :
                    <Typography align="center" variant="h6" color="primary" style={{width: '100%'}}>
                        SOLD OUT
                    </Typography>
                }
            </CardActions>
        </Card>
        
        <ShowInfoDialog showDescription={showDescription} onClose={() => onCloseDialog(setShowDescription)} experience={experience} show={show}/>

        <HostInfoDialog showHostInfo={showHostInfo} onClose={() => onCloseDialog(setShowHostInfo)} show={show}/>

        {showOrder &&
            <Order 
                show={Date.now() - timeLoaded < TIME_TILL_RELOAD_MS ? guestViewState.showToOrder : null} onClose={()=>{
                    setShowOrder(false);
                    setBuyPrivateShowClicked(false);
                }} 
                buyPrivateShowClicked={buyPrivateShowClicked}
                showIDForCache={show.id} 
            />
        }
    </>;
}

export default EventBoardShow;