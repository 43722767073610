/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateShow = /* GraphQL */ `
  subscription OnCreateShow {
    onCreateShow {
      id
      showtime
      hostID
      experienceID
      status
      price
      createdByUserId
      roomCode
      guestCalendarEventId
      hostCalendarEventId
      instanceId
      onePlayerWarningSent
      preshowSent
      highlightsLink
      type
      duration
      recurringShowID
      privateShowID
      showType
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          showID
          hostID
          guestID
          experienceID
          status
          tipAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          commentForHost
          commentForPublic
          commentForPrivate
          rating
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateShow = /* GraphQL */ `
  subscription OnUpdateShow {
    onUpdateShow {
      id
      showtime
      hostID
      experienceID
      status
      price
      createdByUserId
      roomCode
      guestCalendarEventId
      hostCalendarEventId
      instanceId
      onePlayerWarningSent
      preshowSent
      highlightsLink
      type
      duration
      recurringShowID
      privateShowID
      showType
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          showID
          hostID
          guestID
          experienceID
          status
          tipAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          commentForHost
          commentForPublic
          commentForPrivate
          rating
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteShow = /* GraphQL */ `
  subscription OnDeleteShow {
    onDeleteShow {
      id
      showtime
      hostID
      experienceID
      status
      price
      createdByUserId
      roomCode
      guestCalendarEventId
      hostCalendarEventId
      instanceId
      onePlayerWarningSent
      preshowSent
      highlightsLink
      type
      duration
      recurringShowID
      privateShowID
      showType
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          showID
          hostID
          guestID
          experienceID
          status
          tipAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          commentForHost
          commentForPublic
          commentForPrivate
          rating
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateTicket = /* GraphQL */ `
  subscription OnCreateTicket {
    onCreateTicket {
      id
      guestID
      showID
      orderID
      clientTimezone
      additionalNote
      platform
      howDidYouHearAboutAL
      howMuchVRExperience
      status
      playerName
      ticketCode
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      order {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateTicket = /* GraphQL */ `
  subscription OnUpdateTicket {
    onUpdateTicket {
      id
      guestID
      showID
      orderID
      clientTimezone
      additionalNote
      platform
      howDidYouHearAboutAL
      howMuchVRExperience
      status
      playerName
      ticketCode
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      order {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteTicket = /* GraphQL */ `
  subscription OnDeleteTicket {
    onDeleteTicket {
      id
      guestID
      showID
      orderID
      clientTimezone
      additionalNote
      platform
      howDidYouHearAboutAL
      howMuchVRExperience
      status
      playerName
      ticketCode
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      order {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      showID
      totalTicketsPurchased
      guestID
      totalAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      status
      clientTimezone
      coupon
      teamName
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      showID
      totalTicketsPurchased
      guestID
      totalAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      status
      clientTimezone
      coupon
      teamName
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      showID
      totalTicketsPurchased
      guestID
      totalAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      status
      clientTimezone
      coupon
      teamName
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview {
    onCreateReview {
      id
      showID
      hostID
      guestID
      experienceID
      status
      tipAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      commentForHost
      commentForPublic
      commentForPrivate
      rating
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview {
    onUpdateReview {
      id
      showID
      hostID
      guestID
      experienceID
      status
      tipAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      commentForHost
      commentForPublic
      commentForPrivate
      rating
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview {
    onDeleteReview {
      id
      showID
      hostID
      guestID
      experienceID
      status
      tipAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      commentForHost
      commentForPublic
      commentForPrivate
      rating
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      email
      isAdmin
      hostID
      profilePicture {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      notifications {
        items {
          id
          date
          fromID
          toID
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      email
      isAdmin
      hostID
      profilePicture {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      notifications {
        items {
          id
          date
          fromID
          toID
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      email
      isAdmin
      hostID
      profilePicture {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      notifications {
        items {
          id
          date
          fromID
          toID
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      date
      fromID
      toID
      message
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      date
      fromID
      toID
      message
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      date
      fromID
      toID
      message
      createdAt
      updatedAt
    }
  }
`;
export const onCreateExperience = /* GraphQL */ `
  subscription OnCreateExperience {
    onCreateExperience {
      id
      longName
      shortName
      idName
      coverImage {
        bucket
        region
        key
      }
      defaultPrice
      singleTicketPrice
      featuredTicketPrice
      privateShowBookingPrice
      longDescription
      shortDescription
      maxPlayers
      minPlayers
      minInitialTicketPurchase
      platforms
      youTubeLink
      createdAt
      updatedAt
      emailTemplates {
        items {
          id
          type
          html
          rawData
          subjectLine
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
      calendarTemplates {
        items {
          id
          type
          html
          rawData
          summary
          location
          attendees
          creator {
            displayName
            email
            id
          }
          organizer {
            displayName
            email
            id
          }
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateExperience = /* GraphQL */ `
  subscription OnUpdateExperience {
    onUpdateExperience {
      id
      longName
      shortName
      idName
      coverImage {
        bucket
        region
        key
      }
      defaultPrice
      singleTicketPrice
      featuredTicketPrice
      privateShowBookingPrice
      longDescription
      shortDescription
      maxPlayers
      minPlayers
      minInitialTicketPurchase
      platforms
      youTubeLink
      createdAt
      updatedAt
      emailTemplates {
        items {
          id
          type
          html
          rawData
          subjectLine
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
      calendarTemplates {
        items {
          id
          type
          html
          rawData
          summary
          location
          attendees
          creator {
            displayName
            email
            id
          }
          organizer {
            displayName
            email
            id
          }
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteExperience = /* GraphQL */ `
  subscription OnDeleteExperience {
    onDeleteExperience {
      id
      longName
      shortName
      idName
      coverImage {
        bucket
        region
        key
      }
      defaultPrice
      singleTicketPrice
      featuredTicketPrice
      privateShowBookingPrice
      longDescription
      shortDescription
      maxPlayers
      minPlayers
      minInitialTicketPurchase
      platforms
      youTubeLink
      createdAt
      updatedAt
      emailTemplates {
        items {
          id
          type
          html
          rawData
          subjectLine
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
      calendarTemplates {
        items {
          id
          type
          html
          rawData
          summary
          location
          attendees
          creator {
            displayName
            email
            id
          }
          organizer {
            displayName
            email
            id
          }
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateGuest = /* GraphQL */ `
  subscription OnCreateGuest {
    onCreateGuest {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateGuest = /* GraphQL */ `
  subscription OnUpdateGuest {
    onUpdateGuest {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteGuest = /* GraphQL */ `
  subscription OnDeleteGuest {
    onDeleteGuest {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateHost = /* GraphQL */ `
  subscription OnCreateHost {
    onCreateHost {
      id
      firstName
      lastName
      email
      hostName
      slackName
      bio
      profilePicture {
        bucket
        region
        key
      }
      youTubeLink
      status
      rate
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateHost = /* GraphQL */ `
  subscription OnUpdateHost {
    onUpdateHost {
      id
      firstName
      lastName
      email
      hostName
      slackName
      bio
      profilePicture {
        bucket
        region
        key
      }
      youTubeLink
      status
      rate
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteHost = /* GraphQL */ `
  subscription OnDeleteHost {
    onDeleteHost {
      id
      firstName
      lastName
      email
      hostName
      slackName
      bio
      profilePicture {
        bucket
        region
        key
      }
      youTubeLink
      status
      rate
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateCoupon = /* GraphQL */ `
  subscription OnCreateCoupon {
    onCreateCoupon {
      id
      couponCode
      discount
      totalTimesCanBeUsed
      totalTimesUsed
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoupon = /* GraphQL */ `
  subscription OnUpdateCoupon {
    onUpdateCoupon {
      id
      couponCode
      discount
      totalTimesCanBeUsed
      totalTimesUsed
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoupon = /* GraphQL */ `
  subscription OnDeleteCoupon {
    onDeleteCoupon {
      id
      couponCode
      discount
      totalTimesCanBeUsed
      totalTimesUsed
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePrivateShow = /* GraphQL */ `
  subscription OnCreatePrivateShow {
    onCreatePrivateShow {
      id
      description
      name
      type
      createdByUserID
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const onUpdatePrivateShow = /* GraphQL */ `
  subscription OnUpdatePrivateShow {
    onUpdatePrivateShow {
      id
      description
      name
      type
      createdByUserID
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const onDeletePrivateShow = /* GraphQL */ `
  subscription OnDeletePrivateShow {
    onDeletePrivateShow {
      id
      description
      name
      type
      createdByUserID
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const onCreateRecurringShow = /* GraphQL */ `
  subscription OnCreateRecurringShow {
    onCreateRecurringShow {
      id
      type
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateRecurringShow = /* GraphQL */ `
  subscription OnUpdateRecurringShow {
    onUpdateRecurringShow {
      id
      type
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteRecurringShow = /* GraphQL */ `
  subscription OnDeleteRecurringShow {
    onDeleteRecurringShow {
      id
      type
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateEmailTemplates = /* GraphQL */ `
  subscription OnCreateEmailTemplates {
    onCreateEmailTemplates {
      id
      type
      html
      rawData
      subjectLine
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateEmailTemplates = /* GraphQL */ `
  subscription OnUpdateEmailTemplates {
    onUpdateEmailTemplates {
      id
      type
      html
      rawData
      subjectLine
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteEmailTemplates = /* GraphQL */ `
  subscription OnDeleteEmailTemplates {
    onDeleteEmailTemplates {
      id
      type
      html
      rawData
      subjectLine
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCalendarTemplates = /* GraphQL */ `
  subscription OnCreateCalendarTemplates {
    onCreateCalendarTemplates {
      id
      type
      html
      rawData
      summary
      location
      attendees
      creator {
        displayName
        email
        id
      }
      organizer {
        displayName
        email
        id
      }
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCalendarTemplates = /* GraphQL */ `
  subscription OnUpdateCalendarTemplates {
    onUpdateCalendarTemplates {
      id
      type
      html
      rawData
      summary
      location
      attendees
      creator {
        displayName
        email
        id
      }
      organizer {
        displayName
        email
        id
      }
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCalendarTemplates = /* GraphQL */ `
  subscription OnDeleteCalendarTemplates {
    onDeleteCalendarTemplates {
      id
      type
      html
      rawData
      summary
      location
      attendees
      creator {
        displayName
        email
        id
      }
      organizer {
        displayName
        email
        id
      }
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
