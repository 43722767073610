import React, {useContext} from 'react';
import './DisplayPicture.css';
import crumb_icon from '../../../images/crumb_icon.png'
import { Button } from '@mui/material';

import { S3Image } from 'aws-amplify-react';
import UserContext from '../../../Context/UserContext';

function DisplayPicture({width="100%", height="100%", previewImage}){
    const user = useContext(UserContext)
    return (
        <div id="Display-Main">
            {
                previewImage ?
                <img id="Display-Icon" style={{width: width, height: height}} src={previewImage}></img>
                :
                user && user.dbUser?.host?.profilePicture ?
                    <S3Image 
                        imgKey={user.dbUser.host.profilePicture.key}
                        theme={{
                            photoImg: { width: width, height: height, objectFit: 'cover' }
                        }}
                    />

                    :
                    <img id="Display-Icon" style={{width: width, height: height}} src={crumb_icon}></img>        
                }
        </div>
    )
}

export default DisplayPicture;