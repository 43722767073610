import API, { graphqlOperation } from '@aws-amplify/api';
import React, { useContext, useEffect, useState } from 'react';
import { listExperiences } from '../../../graphql/queries';
import HostContext from '../../../Context/HostViewContext';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel, Save, Visibility } from '@mui/icons-material';
import UserContext from '../../../Context/UserContext';
import { updateEmailTemplates } from '../../../graphql/mutations';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML,  } from "draft-js-export-html";
import draftToHtml from 'draftjs-to-html';
import 'draft-js/dist/Draft.css';


function EmailEditor(){
    const hostViewState = useContext(HostContext);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorRawData, setEditorRawData] = useState(null);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showPreviewDialog, setShowPreviewDialog] = useState(false);
    const [subjectLine, setSubjectLine] = useState("");

    const editor = React.useRef(null);
    
    const useStyles = makeStyles(theme => ({
        emailEditorContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        },
        experienceSelect: {
            width: '25%'
        },
        formLabel: {
            width: '100%'
        },
        text: {
            width: '33%',
            margin: '0.5rem'
        },
        editButton: {
            position: 'absolute',
            bottom: '5%',
            right: '5%',
            background: 'white'
        },
        subjectLine: {
            width: '50vw',
            margin: '1rem 0 1rem 0'
        }
    }))

    function onEditorStateChange(editorState){
        setEditorState(editorState);
        setEditorRawData((convertToRaw(editorState.getCurrentContent())));
    }  

    useEffect(
        ()=> {
            if (!hostViewState.emailState?.emailTemplate) return;

            let contentBlock = hostViewState.emailState.emailTemplate.rawData;
            if (typeof contentBlock === 'string') {
                contentBlock = (() => { try { return JSON.parse(contentBlock) } catch(e) { console.log("error reading content block", e)} })();
            } 
            if (contentBlock && typeof contentBlock === 'object' && Object.keys(contentBlock).length > 0) {
                const contentState = convertFromRaw(contentBlock);
                let editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            } else {
                setEditorState(EditorState.createEmpty());
            }

            setSubjectLine(hostViewState.emailState.emailTemplate?.subjectLine || "")
    }, [hostViewState.emailState.emailTemplate])

    useEffect(()=>{
        if (!hostViewState.experiences.length) {
            getExperiences();
        }
    }, [])


    useEffect(() => {
        if (!hostViewState.experiences?.length) return;

        let foundIndex = hostViewState.experiences?.findIndex(
            experience => experience.id === hostViewState.emailState.experience?.id);
        foundIndex = foundIndex > -1 
            ? foundIndex
            :  0;

        const experience = hostViewState.experiences[foundIndex];
        if (experience) {
            const transformedEmails = returnTransformedEmailList(experience.emailTemplates.items);
            
            let foundEmailTemplateIndex = transformedEmails.findIndex(
                email => email.id === hostViewState.emailState?.emailTemplate?.id);

            foundEmailTemplateIndex =  foundEmailTemplateIndex > -1 
                ? foundEmailTemplateIndex
                : 0;

            hostViewState.dispatch({
                type: "emailState",
                emailState: {
                    experience: experience,
                    emailTemplates: transformedEmails,
                    emailTemplate: transformedEmails[foundEmailTemplateIndex],
                }
            })

            setSubjectLine(transformedEmails[foundEmailTemplateIndex].subjectLine  || "")
        }
    }, [hostViewState.experiences])

    const EMAIL_TEMPLATES_ORDER = ["ORDER_EMAIL", "TICKET_EMAIL", "PRESHOW_EMAIL", "POSTSHOW_EMAIL", "CANCEL_EMAIL", "REFUND_EMAIL", "TIP_RECEIPT_EMAIL"];

    const classes = useStyles();
    async function getExperiences(){
        try {
            let experiences = await API.graphql(graphqlOperation(listExperiences));
            experiences = experiences.data.listExperiences.items;
    
            hostViewState.dispatch({type: 'experiences', experiences: 
            experiences});

        } catch (e) {
            console.log("Error getting experiences: ", e)
        }
    }

    function handleCloseEmailEditor(){
        hostViewState.dispatch({ type: "showEmailEditor", showEmailEditor: false})
    }

    function handleExperienceChange(event){
        const foundIndex = hostViewState.experiences.findIndex(experience => event.target.value.id === experience.id) || 0;
        const experience = hostViewState.experiences[foundIndex];

        const templates = returnTransformedEmailList(experience.emailTemplates.items);
        hostViewState.dispatch({
            type: "emailState",
            emailState: {
                emailTemplate: templates[0],
                emailTemplates: templates,
                experience: experience,
            }
        })
        setSubjectLine(templates[0].subjectLine  || "");
    }

    function handleSubjectLineChange(event){
        
        setSubjectLine(event.target.value);

    }

    function handleEmailChange(event){
        const foundIndex = hostViewState.emailState.emailTemplates.findIndex(emails => event.target.value.id === emails.id) || 0;
        const emailTemplate = hostViewState.emailState.emailTemplates[foundIndex];

        hostViewState.dispatch({
            type: "emailState",
            emailState: {
                emailTemplate: emailTemplate,
            }
        })
        setSubjectLine(emailTemplate.subjectLine  || "");

    }

    function returnTransformedEmailList(emailTemplates){
        if (!emailTemplates) {
            return [];
        }

        return EMAIL_TEMPLATES_ORDER.map(emailType => {
            return emailTemplates.find(email => email.type === emailType)
        })
    }

    async function handleSaveEmailTemplate(){
        const stateToHtmlOptions = {};
        const input = {
            id: hostViewState.emailState.emailTemplate.id,
            rawData: JSON.stringify(editorRawData),
            html: draftToHtml(JSON.parse(hostViewState.emailState.emailTemplate?.rawData)),
            experienceID: hostViewState.emailState.experience.id,
            subjectLine
        }

        try {
            const handleUpdateEmailTemplate = await API.graphql(graphqlOperation(updateEmailTemplates, {input}));
            await getExperiences();
        } catch (e) {
            console.log("error updating experience; ", e);
        }

    }

    return (
        <Box className={classes.emailEditorContainer} >
            {hostViewState.emailState.experience && <> 
            <Typography variant="h4" align="center">
                Email Editor
            </Typography>

            <Grid container>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1em 0 1em 0'}}>
                    <FormControl className={classes.experienceSelect}>
                        <InputLabel id="demo-simple-select-label">Experiences</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={hostViewState.emailState.experience}
                            onChange={handleExperienceChange}
                        >
                            {
                                hostViewState.experiences.map(experience => (
                                    <MenuItem key={experience.shortName || experience.createNew} value={experience}>{experience.shortName || 'Create New'}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                { hostViewState.emailState.emailTemplates && 
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', margin: '1em 0 1em 0'}}>
                        <FormControl className={classes.emailSelect}>
                            <InputLabel id="demo-simple-select-label">Emails</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={hostViewState.emailState.emailTemplate}
                                onChange={handleEmailChange}
                            >
                                {
                                    hostViewState.emailState.emailTemplates.map(email => {
                                        return <MenuItem key={email.type} value={email}>{email.type}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12}>
                    <div style={{width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FormControl style={{width: '50vw', margin: '1rem 0 1rem 0'}}>
                            <InputLabel htmlFor="my-input">Subject Line</InputLabel>
                            <Input id="my-input" aria-describedby="my-helper-text" value={subjectLine} onChange={handleSubjectLineChange}/>
                        </FormControl>

                        {/* <TextField
                            variant="filled"
                            label="Subject Line"
                            
                            value={subjectLine}
                            defaultValue="test"
                            
                        /> */}
                    </div>
                <div
                        style={{ 
                            border: '1px solid black', 
                            cursor: 'text',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '96vw',
                            marginLeft: '2vw',
                            marginRight: '2vw',
                        }} 
                    >
                        <Editor
                            ref={editor}
                            editorState={editorState}
                            wrapperStyle={{
                            }}
                            editorStyle={{
                                height: '60vh'
                            }}
                            onEditorStateChange={onEditorStateChange}
                        />
                    </div>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center">
                <Button color="primary" onClick={() => handleSaveEmailTemplate()}>
                    <Save /> Save
                </Button>
                <Button color="primary" onClick={handleCloseEmailEditor}>
                    <Cancel /> Cancel
                </Button>
                <Button color="primary" onClick={() => setShowPreviewDialog(true)}>
                    <Visibility /> Preview
                </Button>
            </Box>
            
            <Dialog open={showPreviewDialog}>
                {hostViewState.emailState.emailTemplate?.rawData &&
                <DialogContent>
                    <div dangerouslySetInnerHTML={{
                        __html: draftToHtml(JSON.parse(hostViewState.emailState.emailTemplate?.rawData))
                    }}
                    >

                    </div>
                </DialogContent>
                }
                <DialogActions>
                    <Button color="primary" onClick={()=>setShowPreviewDialog(false)}>
                        <Cancel /> Cancel
                    </Button>
                </DialogActions>
            </Dialog>
         </>}
        </Box>
    )
}

export default EmailEditor;
