import API, { graphqlOperation } from '@aws-amplify/api';
import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel, Repeat, Save, Visibility } from '@mui/icons-material';
import UserContext from '../../../Context/UserContext';
import HostContext from '../../../Context/HostViewContext';
import moment from 'moment';
// import { GridLinkOperator, GridToolbar, XGrid } from '@material-ui/x-grid';
import { DataGridPro, GridToolbar, GridLinkOperator } from '@mui/x-data-grid-pro';

let todaysDate = moment.utc(Date.now());
let yearAndMonth = todaysDate.format('YYYY-MM');

function Administration(){
    const hostViewState = useContext(HostContext);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [year, setYear] = useState('2022');
    const [month, setMonth] = useState('01');
    const [filteredShows, setFilteredShows] = useState([]);

    const editor = React.useRef(null);
    
    function makeColumns(){

        const columns = [
            { width: 150, field: 'hostFirstName', headerName: 'first name', type: 'string' },
            { width: 150, field: 'hostSecondName', headerName: 'last name', type: 'string' },
            { width: 150, field: 'totalShows', headerName: 'total shows', type: 'number' },
            { width: 175, field: 'grossRevenue', headerName: 'gross revenue', type: 'number' },
            { width: 150, field: 'rate', headerName: 'rate', type: 'number' },
            { width: 150, field: 'ticketsSold', headerName: 'tickets sold', type: 'number' },
            { width: 200, field: 'totalOwedByShow', headerName: 'total owed by show', type: 'number' },
            { width: 210, field: 'totalOwedByTicketsSold', headerName: 'total owed by tickets', type: 'number' },
            { width: 150, field: 'tipsEarned', headerName: 'tips earned', type: 'number' },
        ];
        
        return columns;
    }

    const useStyles = makeStyles(theme => ({
        administrationContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        },
        experienceSelect: {
            width: '25%'
        },
        formLabel: {
            width: '100%'
        },
        text: {
            width: '33%',
            margin: '0.5rem'
        },
        editButton: {
            position: 'absolute',
            bottom: '5%',
            right: '5%',
            background: 'white'
        },
        subjectLine: {
            width: '50vw',
            margin: '1rem 0 1rem 0'
        },
        basicStyle: {
            display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1em 0 1em 0'
        },
        grid: {
            margin: '1em auto 1em auto', width: '90%'
        },
        textField: {
            width: '20%',
            margin: '0 1em 0 1em'
        }
    }))

    const classes = useStyles();

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthBuilder = months.map((month, index) => {
        return {t: month, v: (`${index + 1}`.length < 2 ? "0" : "") + (index + 1)}
    })

    const IGNORE_LIST = ["milad", "max planck"]

    function handleCloseAdministration(){
        hostViewState.dispatch({ type: "showAdministration", showAdministration: false})
    }

    function returnThisMonthsShows(inYearAndMonth = yearAndMonth, hasOrders){
        let finalShowsList;
        const showstoCheck = [...hostViewState.shows, ...hostViewState.pastShows];
        finalShowsList = showstoCheck.filter(show => {
            if (IGNORE_LIST.includes(show.host.hostName.toLowerCase())) return null;
            return show.showtime.includes(inYearAndMonth);
        })

        if (hasOrders) {
            finalShowsList = finalShowsList.filter(show => {
                return show.orders.items.length;
            })
        }
        return finalShowsList;
    }

    function getMonthsRevenue(){
        let total = 0;
        filteredShows.forEach(show => {
            if (show.orders.items.length) {
                show.orders.items.forEach(order => {
                    total += order.totalAmount;
                })
            }
        })

        return total;
    }

    function buildHostMap(){
        const hostMap = {};
        filteredShows.forEach(show => {
            if (!hostMap[show.host.hostName]) {
                hostMap[show.host.hostName] = {
                    shows: [],
                    host: show.host,
                }
            }
            hostMap[show.host.hostName].shows.push(show);
        })
        return hostMap;
    }

    function buildHostArray(hostMap){
        const hostArray = [];
        for (const host in hostMap) {
            const hostToUse = hostMap[host];
            hostArray.push(hostToUse);
        }
        return hostArray;
    }

    function getRevenue(host){
        const revenue = host.shows.reduce((prev, current) => {
            const showRevenue = current.orders.items.reduce((prev, current) => {
                prev = prev + current.totalAmount;
                return prev;
            }, 0)
            prev = prev + showRevenue;
            return prev;
        }, 0)

        return revenue
    }

    function getTicketsSold(host) {
        let totalTicketsSold = 0;
        const revenue = host.shows.forEach(show => {
            const showTickets = show.orders.items.forEach(order => {
                totalTicketsSold += order.totalTicketsPurchased;
            })
            show = show + showTickets;
            return show;
        })
        return totalTicketsSold;
    }

    function getTipsEarned(host){
        let tips = 0;

        host.shows.forEach(show => {
            show.reviews?.items?.forEach(review => {
                if (review.tipAmount) {
                    tips = tips + review.tipAmount;
                }
            })
        })
        return tips;
    }

    useEffect(()=>{
        const shows = returnThisMonthsShows(`${year}-${month}`, true);
        setFilteredShows(shows);
    }, [hostViewState.shows, hostViewState.pastShows, year, month])

    function renderStats() {
        const hostMap = buildHostMap();
        const hostArray = buildHostArray(hostMap);
        const TESTING_TICKET_AMOUNT = 20;
        const HOST_PERCENTAGE_OF_TICKETS = 0.8;

        let transformedData = hostArray.map((hostToRender, index) => {

            const hostRate = (hostToRender.host.rate || 5000) / 100;
            const revenue = getRevenue(hostToRender);
            const totalTicketsSold = getTicketsSold(hostToRender);
            const totalShows = hostToRender.shows.length;
            const totalOwedByTicketsSold = totalTicketsSold * TESTING_TICKET_AMOUNT;
            const tipsEarned = getTipsEarned(hostToRender);
            return {
                id: hostToRender.host.hostName,
                hostFirstName: hostToRender.host.firstName,
                hostSecondName: hostToRender.host.lastName,
                totalShows: totalShows,
                grossRevenue: `$${(revenue / 100).toFixed(2)}`,
                rate: `$${hostRate.toFixed(2)}`,
                ticketsSold: totalTicketsSold,
                totalOwedByShow: `$${(hostRate * totalShows).toFixed(2)}`,
                totalOwedByTicketsSold: `$${(totalOwedByTicketsSold).toFixed(2)}`,
                tipsEarned: `$${(tipsEarned / 100).toFixed(2)}`

            }
        })

        if (!transformedData) {
            transformedData = [];
        }
        return transformedData;
    }
    let stats = renderStats();

    function handleRowClicked(row){
        // hostViewState.dispatch({type: 'newSelectedRow', selectedRow: row.row})
        // hostViewState.dispatch({type: 'shouldShowMoreShowInfo', shouldShowMoreShowInfo: true})
    }

    return (
        <Box className={classes.administrationContainer} >
            <Typography variant="h4" align="center">
                Administration
            </Typography>

            <Grid container>
                <Grid item xs={12} className={classes.basicStyle}>
                    <TextField
                        className={classes.textField}
                        select
                        label="Which Year do you want to view?"
                        required
                        value={year}
                        onChange={e => setYear(e.target.value)}
                    >
                        {['2021', '2022'].map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        className={classes.textField}
                        select
                        label="Which Month do you want to view?"
                        required
                        value={month}
                        onChange={e => setMonth(e.target.value)}
                    >
                        {monthBuilder.map((option, index) => {
                            return (<MenuItem key={option.t} value={option.v}>
                                {option.t}
                            </MenuItem>
                            )
                        })}
                    </TextField>
                </Grid>
                
                <Grid item xs={12} >
                    <DataGridPro
                        autoHeight
                        className={classes.grid}
                        rows={stats} 
                        disableColumnResize={false}
                        columns={makeColumns()} 
                        components={{
                            Toolbar: GridToolbar
                        }}
                        showCellRightBorder
                        loading={stats.length <= 0}
                        onRowClick={handleRowClicked}
                    />
                </Grid>
                

                <Grid item xs={12} className={classes.basicStyle}>
                    <span>
                        <Typography>
                            Shows this month: {filteredShows.length}
                        </Typography>
                        <Typography>
                            Revenue: {`$${getMonthsRevenue() / 100}`}
                        </Typography>
                    </span>

                </Grid>
            </Grid>
        </Box>
    )
}

export default Administration;
