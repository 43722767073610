import React, { useContext, useState, useRef, useEffect } from 'react';

import GuestViewContext from '../../../Context/GuestViewContext';

import { API } from 'aws-amplify';

import moment from 'moment';
import 'moment-timezone';

import { useParams } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';

import { Link as MuiLink, Button, Accordion, AccordionSummary , AccordionDetails, Typography, TextField, Divider, FormControlLabel, Checkbox, MenuItem, Dialog, DialogTitle, DialogContent, Card, CardContent, CircularProgress, useMediaQuery, Container, IconButton } from '@mui/material';

import {Add, NavigateNext, ArrowUpward as ArrowUpwardIcon} from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { disableBodyScroll } from 'body-scroll-lock';
import Payment from '../Payment/Payment';

const SHOULD_USE_SANDBOX = false;

const useStyles = makeStyles(theme => {
    return ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: 'calc(100vh - calc(100vh - 100%))',
        width: '100vw',
        overflowY: 'auto'
    },
    contactForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1) / 2,
            width: 'calc(100%)',
        }
    },
    numberOfTickets: {
        width: 'calc(100% - (100% - 30ch))',
    },
    divider: {
        margin: "2% 0"
    },
    tos: {
        display: "flex",
        flexDirection: 'column',
        marginTop: "2%"
    },
    attendeeRelated: {
        marginTop: "2%"
    },
    guestInfo: {
        marginTop: "2%"
    },
    paymentInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
    },
    confirmation: {
        display: "flex",
        flexDirection: 'column'
    },
    descriptionCard: {
        marginBottom: '1%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary[50]
    },
    accordianEnable: {
        '& .MuiAccordionSummary-root:hover, .MuiButtonBase-root:hover': {
            cursor: 'default',
          },
    },
    accordianDisable: {
        '& .MuiAccordionSummary-root:hover, .MuiButtonBase-root:hover': {
            cursor: 'default',
          },
          backgroundColor: 'rgba(75,75,75,0.2)'
    },
    square: {
        height: '100%',
    },
    closeIcon: {
        position: 'absolute',
        top: '0.5%',
        right: '0.5%'
    },
    goBackButton: {
        position: 'absolute',
        top: '0.5%',
        right: '0.5%'
    },
    squareForm: {
        marginTop: '2%'
    },
    testingNotification: {
        backgroundColor: theme.palette.primary.main,
        padding: '1%',
        color: 'white'
    },
    description: {
        whiteSpace: 'pre-wrap'
    },
    addFriends: {
        display: "flex",
        alignItems: "center"
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    chips: {
        margin: theme.spacing(1, 0),
    },
    handleAddFriendButton: {
        height: '1em',
        width: '1em'
    },
    error: {
        color: theme.palette.error.main
    },
    coupon: {
        display: "flex"
    },
    textField: {
        margin: '0.5em 0 0.5em 0'
    }
})})

function Order({show: cachedShow, onClose, showIDForCache, isFeaturedShow=false, buyPrivateShowClicked=false}) {
    const guestViewState = useContext(GuestViewContext);

    const classes = useStyles();

    const { showID } = useParams();

    const [show, setShow] = useState(cachedShow)
    const [day, setDay] = useState(null)
    const [displayTime, setDisplayTime] = useState(null)

    const formRef = useRef();
    const windowRef = useRef();
    const DEFAULT_TICKETS_BEING_PURCHASED = 8;

    const greaterThanXs = useMediaQuery(theme => theme.breakpoints.up("xs"));
    const greaterThanSm = useMediaQuery(theme => theme.breakpoints.up("sm"));
    const greaterThanMd = useMediaQuery(theme => theme.breakpoints.up("md"));
    const greaterThanLg = useMediaQuery(theme => theme.breakpoints.up("lg"));
    const greaterThanXl = useMediaQuery(theme => theme.breakpoints.up("xl"));

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        teamName: '',
        additionalNote: '',
        readTOS: false,
        okToUseForPromo: false,
        platform: '',
        playerName: '',
        howDidYouHearAboutAL: '',
        isAttendee: true,
        friendEmails: [],
        howMuchVRExperience: '',
        totalTicketsPurchased: buyPrivateShowClicked ? 8 : 1
    })

    const [discount, setDiscount] = useState(0.0);

    const [couponApplied, setCouponApplied] = useState(false);

    const [couponCode, setCouponCode] = useState('');

    const [couponError, setCouponError] = useState(false);

    const [isProcessingCoupon, setIsProcessingCoupon] = useState(null);

    const [formErrors, setFormErrors] = useState({});

    const [showLoaded, setshowLoaded] = useState(false);

    const [validSubmit, setValidSubmit] = useState(true);

    const [validPayment, setValidPayment] = useState(false);

    const [currentShowTime, setCurrentShowTime] = useState(null);

    const [errorMessages, setErrorMessages] = useState([]);

    const [isProcessing, setIsProcessing] = useState(null);
    
    const [currentFriendBeingAdded, setCurrentFriendBeingAdded] = useState('');

    const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
    
    const [finalPriceToAdjust, setFinalPriceToAdjust] = useState(0);
    useEffect(() => {
        if (form.totalTicketsPurchased === 8) {
            setFinalPriceToAdjust(show?.experience.privateShowBookingPrice);
        } else if (isFeaturedShow) {
            setFinalPriceToAdjust(show?.experience.featuredTicketPrice);
        } else {
            setFinalPriceToAdjust(show?.experience.singleTicketPrice * form.totalTicketsPurchased);
        }
    }, [form.totalTicketsPurchased, show])


    async function getShows(){
        try {
            const showList = await API.get('alplatformapi', '/shows');

            guestViewState.dispatch({type: 'shows', shows: showList.data.items})
            
        } catch (e) {
            console.error("Error getting shows: ", e.response);
        }
    }

    function handleGoBackToGeneral(){
        guestViewState.dispatch({type: 'showGeneralInfo', showGeneralInfo: true })
        guestViewState.dispatch({type: 'showPayForm', showPayForm: false })
    }

    function validate(){
        let temp = {};
        temp.firstName = form.firstName ? "" : "This field is required";
        temp.playerName = !form.isAttendee || (form.isAttendee && form.playerName) ? "" : "This field is required";
        temp.lastName = form.lastName ? "" : "This field is required";
        temp.email = (/.+@.+\..+/).test(form.email) ? "" : "This email is not valid";
        temp.teamName = form.teamName || (form.totalTicketsPurchased < 8) ? "" : "This field is required";
        temp.readTOS = form.readTOS ? "" : "This field is required";
        temp.howMuchVRExperience = !form.isAttendee || (form.isAttendee && form.howMuchVRExperience) ? "" : "This field is required";
        // temp.okToUseForPromo = !form.isAttendee || (form.isAttendee && form.okToUseForPromo) ? "" : "This field is required";
        temp.platform = !form.isAttendee || (form.isAttendee && form.platform) ? "" : "This field is required";
        
        setFormErrors(temp);
        if (Object.values(temp).every(x => x === "")) {
            setValidSubmit(true);
            return true;
        } else {
            setValidSubmit(false);
            return false;
        }
    }

    function handleSubmit(e) {
        setFormHasBeenSubmitted(true);
        e.preventDefault();
        if (validate()) {
            try {
                const input = { 
                    ...form, 
                    ...{ show_id: showID },
                    // Check in case someone went to 8, put in a team name, and then went to another ticket amount
                    ...{ teamName: form.totalTicketsPurchased === 8 ? form.teamName : '' } 
                }

                guestViewState.dispatch({type: 'showGeneralInfo', showGeneralInfo: false })
                guestViewState.dispatch({type: 'showPayForm', showPayForm: true })
            } catch (e) {
                console.log("error saving booked show", e);
            }
        }
    }

    function onChange(key, value) {
        setForm(Object.assign({}, form, { [key]: value}))
    }

    function handleReset(e) {
        e.preventDefault();

        formRef.current.resetFields();
    }



    function handleAddFriend() {
        // Don't do anything if there is no input
        if (currentFriendBeingAdded === "") return;
        // Don't do anything if the email doesn't pass the normal
        if (!(/.+@.+\..+/).test(currentFriendBeingAdded.toLowerCase())) return;
        // Clear it out if they try to put their own email in there
        if (currentFriendBeingAdded.toLowerCase() === form.email.toLowerCase()) {
            setCurrentFriendBeingAdded('');
            return;
        }
        // Make sure we only get unique emails
        setForm(Object.assign({}, form, { 
            friendEmails: Array.from(new Set([...form.friendEmails, currentFriendBeingAdded.toLowerCase()]))
        }));

        setCurrentFriendBeingAdded('');
    }

    function removeFriend(index) {
        let newFriends = [...form.friendEmails.slice(0, index), ...form.friendEmails.slice(index + 1)];
        setForm(Object.assign({}, form, { friendEmails: newFriends}));
    }

    async function handlePostData(postData){
        sendOrderToServer(postData.body.nonce, postData.body.buyerVerificationToken, discount, postData.body.location_id);
    }

    async function sendOrderToServer(nonce="", buyerVerificationToken="", inDiscount=discount, location_id){
        const finalAmount = Math.ceil(finalPriceToAdjust * (1.0 - inDiscount));
        const postData = {
            body: {
                nonce,
                buyerVerificationToken,
                location_id,
                clientTimezone: guestViewState.timeZoneValue.value,
                amount: finalAmount,
                note: `${show.experience.shortName} w/ ${show.host.hostName} @ ${displayTime} on ${day} for ${finalAmount / 100}`,
                coupon: couponCode,
                form,
                show
            }
        };

        try {
            setIsProcessing(true);
            const response = await API.post('alplatformapi', '/orders', postData);
            console.log("response", response);
            setValidPayment(true);
            setIsProcessing(false);
            guestViewState.dispatch({type: 'showPayForm', showPayForm: false })
            getShows();
            window.dataLayer.push({'event': 'show-booked', 'couponCode': couponCode, 'host': show.host.hostName, 'price': finalAmount});
        } catch(e) {
            setIsProcessing(false);
            console.log("Error making order: ", e.response)
        }
    }

    async function handleApplyCoupon(){
        setIsProcessingCoupon(true);
        try {
            const postData = {
                body: {
                    coupon: couponCode,
                }
            };

            const response = await API.post('alplatformapi', '/coupons', postData);

            if (!response.discount) {
                throw new Error("discount not supplied");
            }
            window.dataLayer.push({'event': 'coupon-used', 'couponCode': couponCode});

            setDiscount(response.discount);
            setCouponApplied(true);
            setCouponError(false);
            setIsProcessingCoupon(false);

            if (response.discount === 1.0) {
                await sendOrderToServer("", "", response.discount);
            }
        } catch (e) {
            console.log("e: ", e, "response: ", e.response);
            setCouponError(true);
            setIsProcessingCoupon(false);
        }
    }

    function adjustTime(time){
        let momentDate = moment.utc(time);
        let tzValue = guestViewState.timeZoneValue?.value || moment.tz.guess();
        let momentDay = momentDate.tz(tzValue).format("ddd MMM Do");
        setDay(momentDay);
        let momentDisplayTime = momentDate.tz(tzValue).format("h:mmA z");
        setDisplayTime(momentDisplayTime);
    }

    async function getShow(){
        const showIDToUse = showIDForCache ? showIDForCache : showID;
        try {
            let startDate = Date.now();
            const returnedShow = await API.get('alplatformapi', '/shows/' + showIDToUse);
            let endDate = Date.now();
            setCurrentShowTime(returnedShow.data.showtime)

            adjustTime(returnedShow.data.showtime);
            setShow(returnedShow.data);
            setshowLoaded(true);
        } catch (e) {
            console.error("Error getting shows: ", e);
        }
    }
    
    function resetForms(){
        guestViewState.dispatch({type: 'resetForms' })
    }

    function getNumberOfTicketsLeft(){
        const validTickets = show.tickets?.items?.filter(ticket => ticket.status === "VALID" || ticket.status === "USED").length || 0;
        return show.experience.maxPlayers - validTickets;
    }

    function makeTicketOptions(totalTicketsPurchased){
        const marks = [];
        const numberOfTicketsLeft = getNumberOfTicketsLeft();
        for (let i = show.experience.minPlayers; i <= (numberOfTicketsLeft === show.experience.maxPlayers ? 5 : numberOfTicketsLeft); i++) {

            marks.push({ label: 
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Typography>
                        {i}
                    </Typography>
                    <Typography color="primary" component="div">
                        ${((show.experience.singleTicketPrice * i / 100) ).toFixed(2)}
                    </Typography>
                </div>,
                value: i});
        }
        {numberOfTicketsLeft === show.experience.maxPlayers &&
        marks.push({ label:
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <Typography>
                    {8} 
                </Typography>
                <Typography variant={greaterThanSm ? "body1" : "subtitle2"} color="primary" component="div">
                (PRIVATE SHOW FOR 6 + 2 FREE) ${((show.experience.privateShowBookingPrice / 100) ).toFixed(2)}
                </Typography>
            </div>,
            value: 8});
        }
        return marks;
    }

    useEffect( () => {
        if (currentShowTime) {
            adjustTime(currentShowTime);
        }
    }, [guestViewState.timeZoneValue])
    
    useEffect( ()=> {
        // TODO: Logic for cached show
        if (cachedShow) {
            setShow(cachedShow);
            
            setCurrentShowTime(cachedShow.showtime)
            adjustTime(cachedShow.showtime);
            setshowLoaded(true);
        } else {
            getShow();
        }
        resetForms();
    }, [])

    function onDialogEntered(){
        if (greaterThanSm) {
            disableBodyScroll(windowRef.current);
        }
    }

    let countOfFriendsLeftToAddToShow = form.totalTicketsPurchased - (form.friendEmails.length + (form.isAttendee ? 1 : 0));
    
    return (
        <Dialog
            fullWidth={true}
            fullScreen={greaterThanSm ? false : true}
            open={guestViewState.showOrder}
            onClose={onClose}
            className={`${classes.root}`}
            maxWidth={greaterThanMd ? 'md' : false}
            ref={windowRef}
            TransitionProps={{
                onEntered: () => onDialogEntered()
            }}>
            <DialogTitle>
                {(showLoaded && show) ?
                    <>
                        <Typography variant="h4" component="p" align='center'>
                            {show.experience.longName}
                        </Typography> 
                        <Typography variant="h5" component="p" align='center'>
                            w/ <strong>{show.host.hostName}</strong> @ <strong>{displayTime}</strong> on <strong>{day}</strong> 
                        </Typography>
                    </>
                    :
                        null
                }
                <IconButton className={classes.closeIcon} onClick={onClose} size="large">
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={'style-4'}>
                {(showLoaded && show) ?
                    <div>

                        <Card variant="outlined" className={classes.descriptionCard}>
                            {/* <CardHeader subheader="Show Details" /> */}
                            <CardContent>
                                <Typography variant="body1" component="p" className={classes.description}>
                                    {show.experience.longDescription}
                                </Typography>
                            </CardContent>
                        </Card>

                        <Typography variant="h5" align='left'>
                            Please fill out our questionnaire and payment info
                        </Typography>

                        <Accordion expanded={guestViewState.showGeneralInfo} className={guestViewState.showGeneralInfo ? classes.accordianEnable : classes.accordianDisable}>

                            <AccordionSummary >
                                <Typography variant="h5" align='left'>
                                    1. General
                                </Typography>
                            </AccordionSummary >

                            <AccordionDetails>
                                <form className={classes.contactForm} ref={formRef}>

                                    <div>
                                        <TextField 
                                            required 
                                            error={!!formErrors.firstName}
                                            helperText={formErrors.firstName}
                                            label="First Name" 
                                            value={form.firstName} 
                                            onChange={(e) => onChange('firstName', e.target.value)}
                                            onBlur={() => formHasBeenSubmitted && validate()}
                                            InputLabelProps={{
                                                style: { color: '#aaa' },
                                              }}
                                            className={classes.textField}
                                        />
                                        <TextField 
                                            required 
                                            error={!!formErrors.lastName}
                                            helperText={formErrors.lastName}
                                            label="Last Name" 
                                            value={form.lastName} 
                                            onChange={(e) => onChange('lastName', e.target.value)}
                                            onBlur={() => formHasBeenSubmitted && validate()}
                                            className={classes.textField}
                                        />
                                        <TextField 
                                            required
                                            error={!!formErrors.email}
                                            helperText={formErrors.email}
                                            label="Email" 
                                            type="email" 
                                            value={form.email} 
                                            onChange={(e) => onChange('email', e.target.value)}
                                            onBlur={() => formHasBeenSubmitted && validate()}
                                            className={classes.textField}
                                        />
                                    </div>

                                    {/* <Divider className={classes.divider}/> */}

                                    <div className={classes.tos}>
                                        <Typography variant="h6" align='left' gutterBottom>
                                            Terms of Service
                                        </Typography>
                                        <MuiLink target="_blank" href="https://www.adventurelab.fun/tos" component="a">
                                            https://www.adventurelab.fun/tos
                                        </MuiLink>
                                        <FormControlLabel
                                            required
                                            control={<Checkbox required checked={form.readTOS} onChange={(e) => onChange('readTOS', e.target.checked)} name="readTOS"/>}
                                            label="I have read and agree to the terms above*"
                                            classes={{
                                                label: formErrors.readTOS ? classes.error: ''
                                            }}
                                            onBlur={() => formHasBeenSubmitted && validate()}
                                        />
                                    </div>

                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={form.isAttendee ? false : true} 
                                                onChange={(e) => onChange('isAttendee', !e.target.checked)} name="isAttendee"
                                            />}
                                        label="I am buying this show for others and I am NOT an attendee"
                                    />
                                    {!isFeaturedShow &&
                                    <div>
                                        <TextField className={classes.numberOfTickets}
                                            select
                                            label="Number of Tickets"
                                            helperText="How many guests are joining this game"
                                            required
                                            value={form.totalTicketsPurchased}
                                            onChange={(e) => onChange('totalTicketsPurchased', e.target.value)}
                                        >
                                            {makeTicketOptions().map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    }
                                    {form.totalTicketsPurchased === 8 &&
                                    <TextField
                                        required
                                        error={!!formErrors.teamName}
                                        helperText={formErrors.teamName}
                                        label="Team Name"
                                        value={form.teamName}
                                        onChange={(e) => onChange('teamName', e.target.value)}
                                        onBlur={() => formHasBeenSubmitted && validate()}
                                        className={classes.textField}
                                    />}
                                    {form.isAttendee && 
                                        <div className={classes.attendeeRelated}>
                                    {/* <Divider className={classes.divider}/> */}

                                            <Typography variant="h6" align='left' gutterBottom>
                                                Attendee Related
                                            </Typography>
                                            {/*commenting this out for now */}
                                            {/* <FormControlLabel
                                                required
                                                control={
                                                    <Checkbox required checked={form.okToUseForPromo} 
                                                        onChange={(e) => onChange('okToUseForPromo', e.target.checked)} 
                                                        name="okToUseForPromo"
                                                    />}
                                                label="It's okay if Adventure Lab uses gameplay footage of my session, including my voice and virtual likeness, in any of its promotional materials!*"
                                                classes={{
                                                    label: formErrors.okToUseForPromo ? classes.error: ''
                                                }}
                                                onBlur={() => formHasBeenSubmitted && validate()}
                                            /> */}
                                            <TextField 
                                                helperText="Birthday party? Language barrier? Age specific?..." 
                                                label="Additional Info for your host" 
                                                multiline 
                                                rows={3} 
                                                type="textarea" 
                                                value={form.additionalNote} 
                                                onChange={(e) => onChange('additionalNote', e.target.value)}
                                                className={classes.textField}
                                            />
            
                                            <TextField 
                                                required 
                                                error={!!formErrors.playerName}
                                                helperText={"Tip: include your first name if you want people to know who you are - ex Agent Fluffy (Mike)"}
                                                label="What would you like your secret agent name to be?" 
                                                value={form.playerName} 
                                                onChange={(e) => onChange('playerName', e.target.value)}
                                                onBlur={() => formHasBeenSubmitted && validate()}
                                                InputLabelProps={{
                                                    style: { color: '#aaa' },
                                                }}
                                                className={classes.textField}
                                            />

                                            <TextField
                                                select
                                                label="What VR device do you use?"
                                                helperText={"The Valve Index is supported, but not recommended. Please use a Quest, Rift, or Vive if possible. " + (formErrors.platform ? formErrors.platform : "")}
                                                required
                                                value={form.platform}
                                                error={!!formErrors.platform}
                                                onChange={(e) => onChange('platform', e.target.value)}
                                                onBlur={() => formHasBeenSubmitted && validate()}
                                                className={classes.textField}
                                            >
                                                {["Oculus Quest", "Oculus Rift/Rift S", "HTC Vive", "Valve Index"].map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                            <TextField
                                                select
                                                label="How did you hear about us?"
                                                value={form.howDidYouHearAboutAL}
                                                onChange={(e) => onChange('howDidYouHearAboutAL', e.target.value)}
                                                className={classes.textField}
                                            >
                                                {["Facebook", "Instagram", "Twitter", "Oculus", "News Article", "Family or Friend", "Google Search", "Other"].map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                            <TextField
                                                select
                                                error={!!formErrors.howMuchVRExperience}
                                                helperText={formErrors.howMuchVRExperience}
                                                label="VR experience level?"
                                                required
                                                value={form.howMuchVRExperience}
                                                onChange={(e) => onChange('howMuchVRExperience', e.target.value)}
                                                onBlur={() => formHasBeenSubmitted && validate()}
                                                className={classes.textField}
                                            >
                                                {["None", "Just got a headset", "I know my virtual way around", "God Mode"].map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div> 
                                    }
                                    {/* GUEST INFO FOR V2
                                    <div className={classes.guestInfo}>
                                        <Typography variant="h6" align='left' gutterBottom>
                                            Other guest information
                                        </Typography>
                                        <div className={classes.addFriends}>
                                            <TextField
                                                disabled={countOfFriendsLeftToAddToShow === 0}
                                                type="email"
                                                label="Friends emails"
                                                onChange={(e) => setCurrentFriendBeingAdded(e.target.value) }
                                                helperText={`Enter them one at a time to auto-invite. You can also manually send an invite link later.`}
                                                value={currentFriendBeingAdded}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton onClick={handleAddFriend} className={classes.handleAddFriendButton}>
                                                            <Add />
                                                        </IconButton>
                                                    </InputAdornment>,
                                                  }}
                                                onKeyPress={(ev) => {
                                                    if (ev.key === 'Enter') {
                                                    // Do code here
                                                    ev.preventDefault();
                                                    handleAddFriend();
                                                    }
                                                }}
                                            />

                                        </div>
                                        <div className={classes.chips}>
                                            {countOfFriendsLeftToAddToShow === 0 &&
                                                <Typography color="error" variant="subtitle2" align='left' gutterBottom>
                                                    You've reached your maximum guest limit
                                                </Typography>
                                            }

                                            {form.friendEmails.map((email, index) => (
                                                <Chip
                                                    key={email}
                                                    label={email}
                                                    onDelete={() => removeFriend(index)}
                                                    className={classes.chip}
                                                    color="secondary"
                                                    size="small"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    */}
                                    <div>
                                        <Button 
                                            variant="contained"
                                            // disabled={!validSubmit}
                                            color="primary" 
                                            onClick={handleSubmit}
                                            endIcon={<NavigateNext />}
                                        >
                                            Pay
                                        </Button>
                                    </div>
                                </form>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={guestViewState.showPayForm} className={guestViewState.showPayForm ? classes.accordianEnable : classes.accordianDisable}>

                            <AccordionSummary >
                                <Typography variant="h5" align='left'>
                                    2. Payment Info
                                </Typography>
                            </AccordionSummary >

                            <AccordionDetails className={classes.paymentInfo}>
                                <IconButton
                                    onClick={handleGoBackToGeneral}
                                    className={classes.goBackButton}
                                    size="large">
                                    <ArrowUpwardIcon />
                                </IconButton>
                                {!isFeaturedShow &&
                                    <>
                                        <div className={classes.coupon}>
                                            <TextField
                                                error={couponError}
                                                label="Coupon Code"
                                                value={couponCode}
                                                onChange={(e) => setCouponCode(e.target.value)}
                                                disabled={couponApplied}
                                                style={{
                                                    flexGrow: 1
                                                }}
                                            />
                                            <Button 
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                disabled={couponApplied}
                                                onClick={handleApplyCoupon}
                                            >
                                                Apply Coupon
                                            </Button>
                                        </div>
                                    </>
                                }
                                {isProcessingCoupon && <CircularProgress />}

                                {couponApplied &&
                                    <Typography variant="subtitle1" color="secondary">
                                        A discount of {discount * 100}% has been applied!
                                    </Typography>
                                }
                                <Payment {...{
                                        form, 
                                        formErrors, 
                                        onChange, 
                                        formHasBeenSubmitted, 
                                        validate, 
                                        show, 
                                        setIsProcessing, 
                                        windowRef, 
                                        setValidPayment, 
                                        validSubmit, 
                                        isReview: false, 
                                        amount: String(Math.ceil(finalPriceToAdjust * (1.0 - discount))),
                                        handlePostData
                                    }}
                                />

                                {isProcessing && 
                                    <>  
                                        <div>
                                            <Typography variant="h6" color="inherit">
                                                Please wait as we complete the order.  This could take a few moments.
                                            </Typography>
                                        </div>
                                        <div>
                                            <CircularProgress />
                                        </div>
                                    </>
                                }
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={validPayment} className={classes.accordian} className={validPayment ? classes.accordianEnable : classes.accordianDisable}>

                            <AccordionSummary>
                                <Typography variant="h5" align='left'>
                                    3. Confirmation
                                </Typography>
                                
                            </AccordionSummary>

                            <AccordionDetails className={classes.confirmation}>
                                <Typography variant="h4" align='left'>
                                    THANK YOU {form.firstName}!
                                </Typography>
                                <Typography variant="h5" align='left'>
                                    Please check your email for further information on your show. 
                                </Typography>
                            </AccordionDetails>

                        </Accordion>

                    </div>:
                    <Container>
                        <CircularProgress />
                    </Container>

                }
            </DialogContent>
        </Dialog>
    );
}

export default Order;


// code for number of tickets
// 



