import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import React, { useRef, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(theme => {
    return({
        container: {
            minWidth: '50vw',
        },
        closeIcon: {
            position: 'absolute',
            top: '0.5%',
            right: '0.5%'
        },
        reactPlayer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        description: {
            whiteSpace: 'pre-wrap'
        },
    })
})


export default function HostInfoDialog({showHostInfo, onClose, show}) {
    const classes = useStyles();
    const greaterThanSm = useMediaQuery(theme => theme.breakpoints.up("sm"));
    const [timeLoaded, setTimeLoaded] = useState(Date.now());

    function onDialogEntered(ref){
        if (greaterThanSm) {
            disableBodyScroll(ref.current);
        }
    }

    const dialogHostInfoRef = useRef(null);

    return (
        <Dialog
            classes={{paper: classes.container}}
            fullWidth={true}
            open={showHostInfo}
            onClose={onClose}
            ref={dialogHostInfoRef}
            TransitionProps={{
                onEntered: () => onDialogEntered(dialogHostInfoRef)
            }}>
            <IconButton className={classes.closeIcon} onClick={onClose} size="large">
                    <CancelIcon />
            </IconButton>
            <DialogTitle>
                <Typography variant="h5">
                    {show.host.hostName.toUpperCase()}
                </Typography>
            </DialogTitle>
            <Divider light/>
            <DialogContent >
                {show.host.youTubeLink && 
                    <div className={classes.reactPlayer}>
                        <ReactPlayer
                            playing={true}
                            url={show.host.youTubeLink}
                        />
                    </div>
                }
                <Typography variant="h6" component="p" className={classes.description}>
                    {show.host.bio}
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

