import {Auth} from 'aws-amplify';

function userReducer(state, action){
    // console.log("state: ", state, "action: ", action);
    switch(action.type){
        case "dbUser":
            return {
                ...state,
                ...{
                    dbUser: action.dbUser
                }
            }
        case 'awsUser':
            return {
                ...state,
                ...{
                    awsUser: action.awsUser
                }
            }
        case 'signOut':
            try {
                return {
                    ...state,
                    ...{
                        awsUser: null,
                        dbUser: null
                    }
                }
            } catch (e) {
                console.log('error signing out: ', e);
                return state;
            }
        case 'host':
            return {
                ...state,
                ...{
                    dbUser: {...state.dbUser, ...{host: action.host}}
                }
            }
        default:
            console.log("unrecognized user reducer action");
    }
}

export default userReducer;