import React, { useEffect, useReducer, useState } from 'react';

import { API} from 'aws-amplify';

import EventBoard from './EventBoard/EventBoard.js';

import guestViewInitialState from '../../Context/guestViewInitialState';
import guestViewReducer from '../../Context/guestViewReducer';
import GuestViewContext from '../../Context/GuestViewContext';

import { Redirect, Route, useRouteMatch } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';

import Header from '../Common/Header';
import Ticket from './Ticket/Ticket';
import Review from './Review/Review';

import { CircularProgress, Container, Typography, Paper } from '@mui/material';
import { Storage, S3Image } from 'aws-amplify';

import Intercom from './Intercom/Intercom';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: '100%',
    },
    loader: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '80vh'
    }

}))

const TIME_TILL_FETCH_NEW_SHOW_MS = 180000;

function GuestView(){
    const [guestViewState, guestViewDispatch] = useReducer(guestViewReducer, guestViewInitialState);
    const [showHeader, setShowHeader] = useState(true);
    const classes = useStyles();

    const { url } = useRouteMatch();

    async function getImages(shows){
        const experienceImages = {};
        const hostImages = {};

        for (const show of shows) {
            if (show.experience.coverImage?.key) {
                if (!experienceImages[show.experience.shortName]) {
                    experienceImages[show.experience.shortName] = await Storage.get(show.experience.coverImage?.key)
                }
            }

            if (show.host.profilePicture?.key) {
                if (!hostImages[show.host.hostName]) {
                    hostImages[show.host.hostName] = await Storage.get(show.host.profilePicture.key)
                }
            }
        }

        guestViewDispatch({type: "updateExperienceImages", experienceImages: experienceImages})

        guestViewDispatch({type: "updateHostImages", hostImages: hostImages})

    }

    async function getShows(){
        try {
            const showList = await API.get('alplatformapi', '/shows');

            getImages(showList.data.items);
            guestViewDispatch({type: 'shows', shows: showList.data.items})
        } catch (e) {
            console.error("Error getting shows: ", e.response);
        }
    }

    useEffect( ()=> {
        getShows();
        let timeout = setInterval(getShows, TIME_TILL_FETCH_NEW_SHOW_MS);

        return () => clearInterval(timeout);
    }, [])

    function onTimeZoneChange(event, value){
        guestViewDispatch({type: "timeZoneValue", timeZoneValue: value})
    }

    function onSearchTermChange(event, value){
        guestViewDispatch({type: "searchTerms", searchTerms: event.target.value})
    }

    function returnShows(){
        return (
            guestViewState.shows.length > 0 ?
            <EventBoard /> :
            <Container className={classes.loader}>
                <Paper className={classes.callout} elevation={0}>
                    <Typography color="black" variant="h3" align="center">
                        No public shows are available at this time.  If you are interested in booking a private show, please contact hello@adventurelab.fun
                    </Typography>
                </Paper>
            </Container>
        )
    }

    return (
        <div className={classes.root}>
            <GuestViewContext.Provider value={{ ...guestViewState, ...{ dispatch: guestViewDispatch }}}>
                {showHeader &&
                <Header
                    timeZoneValue={guestViewState.timeZoneValue}
                    onTimeZoneChange={onTimeZoneChange}
                    searchTermValue={guestViewState.searchTermValue}
                    onSearchTermChange={onSearchTermChange}
                />
                }
                <Intercom />
                <Route path={`${url}/ticket/:orderID`}>
                    <Ticket />
                </Route>
                <Route path={`${url}/review/:showID`} render={() => {
                    setShowHeader(false);
                    return <Review />
                }}>
                </Route>
                <Route path={`${url}/private/:privateShowID`}>
                    {returnShows()}
                </Route>
                <Route exact path={`${url}/ticket`}>
                    <Redirect to="/guest" />
                </Route>
                <Route exact path={`${url}`}>
                    {returnShows()}

                </Route>
            </GuestViewContext.Provider>
        </div>
    )
}

export default GuestView;
