import { getTimeZones } from "@vvo/tzdb";
import moment from 'moment';
import 'moment-timezone';

const timeZone = moment.tz.guess();
const offset = moment.tz(timeZone).format('Z');
const label = `(GMT${offset}) ${timeZone.replace("_", " ")}`

const hostViewInitialState = {
    shows: [],
    hosts: [],
    experiences: [],
    privateShows: [],
    shouldShowMoreShowInfo: false,
    shouldShowShowsCreateEdit: false,
    isShowEditMode: false,
    showOrder: false,
    searchTerms: null,
    timeZoneValue: {label: label, value: timeZone},
    showProfileEditor: false,
    profileKey: null,
    showExperienceEditor: false,
    showShowsList: true,
    showShowsCalendar: true,
    calendarValue: new Date(),
    showEmailEditor: false,
    showCalendarEditor: false,
    emailState: {
        emailTemplate: null,
        experience: null,
        emailTemplates: [],
    },
    calendarState: {
        calendarTemplate: null,
        experience: null,
        calendarTemplates: [],
    },
    shouldShowSpeedDialOpen: false,
    pastShows: []

}

export default hostViewInitialState;