import API, { graphqlOperation } from '@aws-amplify/api';
import React, { useContext, useEffect, useState } from 'react';
import { listExperiences } from '../../../graphql/queries';
import HostContext from '../../../Context/HostViewContext';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import crumb_icon from '../../../images/crumb_icon.png'
import { PhotoPicker, S3Image } from 'aws-amplify-react';
import { Cancel, Delete, Edit, Save } from '@mui/icons-material';
import Auth from '@aws-amplify/auth';
import aws_exports from '../../../aws-exports';
import UserContext from '../../../Context/UserContext';
import { Storage } from 'aws-amplify';
import { createEmailTemplates, createCalendarTemplates, createExperience, deleteEmailTemplates, deleteCalendarTemplates, deleteExperience, updateExperience } from '../../../graphql/mutations';


function ExperienceEditor(){
    const hostViewState = useContext(HostContext);
    const userState = useContext(UserContext);

    const [experience, setExperience] = useState(hostViewState.experiences[0]);
    const [newLongName, setNewLongName] = useState(hostViewState.experiences[0].longName);
    const [newShortName, setNewShortName] = useState(hostViewState.experiences[0].shortName);
    const [newIdName, setNewIdName] = useState(hostViewState.experiences[0].idName);
    const [newCoverImage, setNewCoverImage] = useState(hostViewState.experiences[0].coverImage || {});
    const [newDefaultPrice, setNewDefaultPrice] = useState(hostViewState.experiences[0].defaultPrice);
    const [newSingleTicketPrice, setNewSingleTicketPrice] = useState(hostViewState.experiences[0].singleTicketPrice);
    const [newFeaturedTicketPrice, setNewFeaturedTicketPrice] = useState(hostViewState.experiences[0].featuredTicketPrice);
    const [newPrivateShowBookingPrice, setNewPrivateShowBookingPrice] = useState(hostViewState.experiences[0].privateShowBookingPrice);
    const [newLongDescription, setNewLongDescription] = useState(hostViewState.experiences[0].longDescription);
    const [newShortDescription, setNewShortDescription] = useState(hostViewState.experiences[0].shortDescription);
    const [newMaxPlayers, setNewMaxPlayers] = useState(hostViewState.experiences[0].maxPlayers);
    const [newMinPlayers, setNewMinPlayers] = useState(hostViewState.experiences[0].minPlayers);
    const [newMinInitialTicketPurchase, setNewMinInitialTicketPurchase] = useState(hostViewState.experiences[0].minInitialTicketPurchase);
    const [newPlatforms, setNewPlatforms] = useState(hostViewState.experiences[0].platforms);
    const [newYouTubeLink, setNewYouTubeLink] = useState(hostViewState.experiences[0].youTubeLink) || "";

    const [showPhotoPicker, setShowPhotoPicker] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [isCreateNew, setIsCreateNewExperience] = useState(false)
    const [isUploading, setIsUploading] = useState(false);
    const [image, setImage] = useState(false);

    const [uploadedFile, setUploadedFile] = useState(null);
    const [coverPicture, setCoverPicture] = useState(userState ? userState.dbUser?.host?.profilePicture : null);
    const [s3File, setS3File] = useState(null);

    const platforms = experience.platforms || [];
    const [platformsCheckBox, setPlatformsCheckBox] = useState({
        "Oculus Quest": platforms.includes("Oculus Quest"), 
        "Oculus Rift/Rift S": platforms.includes("Oculus Rift/Rift S"), 
        "HTC Vive": platforms.includes("HTC Vive"), 
        "Valve Index": platforms.includes("Valve Index"),
        "Mobile": platforms.includes("Mobile"),
        "Browser": platforms.includes("Browser"),
        "Desktop": platforms.includes("Desktop")
    });

    const [previewImage, setPreviewImage] = useState(null);
    const forceUpdate = React.useReducer(() => ({}))[1]
    const useStyles = makeStyles(theme => ({
        experienceEditorContainer: {
            width: '100%',
            // backgroundColor: 'yellow'
        },
        experienceSelect: {
            width: '25%'
        },
        formLabel: {
            width: '100%'
        },
        text: {
            width: '33%',
            margin: '0.5rem'
        },
        editButton: {
            position: 'absolute',
            bottom: '5%',
            right: '5%',
            background: 'white'
        },
    }))

    const classes = useStyles();
    async function getExperiences(){
        try {
            let experiences = await API.graphql(graphqlOperation(listExperiences));
            experiences = experiences.data.listExperiences.items;
    
            hostViewState.dispatch({type: 'experiences', experiences: 
            experiences});

        } catch (e) {
            console.log("Error getting experiences: ", e)
        }
    }

    function handleCloseExperiences(){
        hostViewState.dispatch({ type: "showExperienceEditor", showExperienceEditor: false})
    }

    function handleExperienceChange(event){
        const foundIndex = hostViewState.experiences.findIndex(experience => event.target.value.id === experience.id) || 0;
        const experience = hostViewState.experiences[foundIndex]
        setExperience(experience);

        setNewLongName(experience.longName);
        setNewShortName(experience.shortName);
        setNewIdName(experience.idName);
        setNewCoverImage(experience.coverImage || {});
        setNewDefaultPrice(experience.defaultPrice);
        setNewSingleTicketPrice(experience.singleTicketPrice);
        setNewFeaturedTicketPrice(experience.featuredTicketPrice);
        setNewPrivateShowBookingPrice(experience.privateShowBookingPrice);
        setNewLongDescription(experience.longDescription);
        setNewShortDescription(experience.shortDescription);
        setNewMaxPlayers(experience.maxPlayers);
        setNewMinPlayers(experience.minPlayers);
        setNewYouTubeLink(experience.youTubeLink || "");
        setNewMinInitialTicketPurchase(experience.minInitialTicketPurchase);
        setNewPlatforms(experience.platforms)
        const platforms = experience.platforms || [];
        setPlatformsCheckBox({
            "Oculus Quest": platforms.includes("Oculus Quest"), 
            "Oculus Rift/Rift S": platforms.includes("Oculus Rift/Rift S"), 
            "HTC Vive": platforms.includes("HTC Vive"), 
            "Valve Index": platforms.includes("Valve Index"),
            "Mobile": platforms.includes("Mobile"),
            "Browser": platforms.includes("Browser"),
            "Desktop": platforms.includes("Desktop")
        });
        setPreviewImage(null);
        setIsCreateNewExperience(false);
    }

    useEffect(()=>{
        if (!hostViewState.experiences.length) {
            getExperiences();
        }
    }, [])

    async function handleSaveExperience(){
        const input = {
            longName: newLongName,
            shortName: newShortName,
            idName: newIdName,
            defaultPrice: newDefaultPrice,
            singleTicketPrice: newSingleTicketPrice,
            featuredTicketPrice: newFeaturedTicketPrice,
            privateShowBookingPrice: newPrivateShowBookingPrice,
            longDescription: newLongDescription,
            shortDescription: newShortDescription,
            maxPlayers: newMaxPlayers,
            minPlayers: newMinPlayers,
            youTubeLink: newYouTubeLink,
            minInitialTicketPurchase: newMinInitialTicketPurchase,
            platforms: newPlatforms
        }

        if (Object.keys(newCoverImage).length){
            input.coverImage = newCoverImage;
        }

        try {
            if (isCreateNew) {
                const handleCreateExperience = await API.graphql(graphqlOperation(createExperience, {input}));
                const experienceID = handleCreateExperience.data.createExperience.id;
                const emailTemplateMap = ["ORDER_EMAIL", "TICKET_EMAIL", "PRESHOW_EMAIL", "POSTSHOW_EMAIL"];
                for (const emailTemplateType of emailTemplateMap) {
                    const emailTemplateTypeResponse = await API.graphql(graphqlOperation(createEmailTemplates, {input: { type: emailTemplateType, experienceID: experienceID}}))
                }

                const calendarTemplateMap = ["HOST", "GUEST", "ADMIN"];
                for (const calendarTemplateType of calendarTemplateMap) {
                    const calendarTemplateTypeResponse = await API.graphql(graphqlOperation(createCalendarTemplates, {input: { type: calendarTemplateType, experienceID: experienceID}}))
                }

                // setExperience(experiences[experiences.length])
            } else {
                input.id = experience.id;
                const handleUpdateExperience = await API.graphql(graphqlOperation(updateExperience, {input}));
            }
            await getExperiences();

        } catch (e) {
            console.log("error updating experience; ", e);
        }

    }

    async function handleDeleteExperience(){
        const input = {
            id: experience.id
        };
        try {
            if (experience.emailTemplates.items.length) {
                
                for (const email of experience.emailTemplates.items) {
                    const input = {
                        id: email.id
                    }

                    const deleteEmailTemplateResponse = await API.graphql(graphqlOperation(deleteEmailTemplates, {input}))

                }
            }
            if (experience.calendarTemplates.items.length) {
                
                for (const calendar of experience.calendarTemplates.items) {
                    const input = {
                        id: calendar.id
                    }

                    const deleteCalendarTemplateResponse = await API.graphql(graphqlOperation(deleteCalendarTemplates, {input}))
                }
            }
            const handleDeleteExperience = await API.graphql(graphqlOperation(deleteExperience, { input }))
            setShowConfirmationDialog(false);

            getExperiences();
        } catch (e) {
            console.log('problem handling experience');
        }

    }

    function handleCreateNewExperience(){
        setIsCreateNewExperience(true);

        setExperience(experience);

        setNewLongName(null);
        setNewShortName(null);
        setNewIdName(null);
        setNewCoverImage({});
        setNewDefaultPrice(null);
        setNewSingleTicketPrice(null);
        setNewFeaturedTicketPrice(null);
        setNewPrivateShowBookingPrice(null);
        setNewLongDescription(null);
        setNewShortDescription(null);
        setNewMaxPlayers(null);
        setNewMinPlayers(null);
        setNewYouTubeLink(null);
        setNewMinInitialTicketPurchase(null);
        setNewPlatforms(null)
        const platforms = [];
        setPlatformsCheckBox({
            "Oculus Quest": platforms.includes("Oculus Quest"), 
            "Oculus Rift/Rift S": platforms.includes("Oculus Rift/Rift S"), 
            "HTC Vive": platforms.includes("HTC Vive"), 
            "Valve Index": platforms.includes("Valve Index"),
            "Mobile": platforms.includes("Mobile"),
            "Browser": platforms.includes("Browser"),
            "Desktop": platforms.includes("Desktop")
        });
        setPreviewImage(null);
    }

    async function onPickFile(file){
        setImage(file);
        setIsUploading(false);
        setShowPhotoPicker(false);
        const visibility = 'public';
        const {identityId} = await Auth.currentCredentials();
        const filename = `${identityId}/${Date.now()}-${file.name}`;

        try {
            const uploadedFile = await Storage.put(filename, file.file, {contentType: file.type});

            setUploadedFile(uploadedFile);
            const s3File = {
                key: uploadedFile.key,
                bucket: aws_exports.aws_user_files_s3_bucket,
                region: aws_exports.aws_project_region
            }
            setS3File(s3File);
            setNewCoverImage(s3File);
        } catch (e) {
            console.log("e uploading pictures: ", e)
        }

    }

    const checkboxOnChange = (platform) => {
        const newPlatforms = {
            ...platformsCheckBox, 
            ...{
                [platform]: !platformsCheckBox[platform]
            }
        }
        const platformArray = Object.keys(newPlatforms).filter(key => {
            return newPlatforms[key]
        });
        setPlatformsCheckBox(
            newPlatforms
        )
        setNewPlatforms(platformArray);
    }

    return (
        <Box className={classes.experienceEditorContainer} m={2}>
            <Typography variant="h4" align="center">
                Experience Editor
            </Typography>
            <Grid container>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', margin: '1em 0 1em 0'}}>
                    <FormControl className={classes.experienceSelect}>
                        <InputLabel id="demo-simple-select-label">Experiences</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={experience}
                            onChange={handleExperienceChange}
                        >
                            {
                                hostViewState.experiences.map(experience => (
                                    <MenuItem key={experience.shortName || experience.createNew} value={experience}>{experience.shortName || 'Create New'}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <Button color="primary" variant="contained" onClick={handleCreateNewExperience}>Create new experience</Button>
                </Grid>

                <Grid item xs={12} position="relative">
                    <Box mb={5} position="relative" style={{width: 'calc(5vw *1.333 + 1vh * 1.333)', height: 'calc(5vw + 1vh)'}}>
                    {newCoverImage?.key && !previewImage?
                        <S3Image
                            imgKey={newCoverImage.key}
                            theme={{
                                photoImg: { width: 'calc(5vw *1.333 + 1vh * 1.333)', height: 'calc(5vw + 1vh)', objectFit: 'cover' }
                            }}
                        />

                        :
                        <img id="Display-Icon" style={{width: '100%', minHeight: 100}} src={previewImage || crumb_icon}></img>
                    }
                        <Button color="primary" className={classes.editButton} onClick={()=>setShowPhotoPicker(true)}>
                            <Edit fontSize="small" color="primary"/> Edit
                        </Button>
                    </Box>
                    <Dialog open={showPhotoPicker}>
                            <DialogTitle>
                                <Typography variant="h4">
                                    Cover Image
                                </Typography>
                            </DialogTitle>
                            <PhotoPicker
                                title="User Image"
                                preview="hidden"
                                onLoad={url => setPreviewImage(url)}
                                onPick={file => onPickFile(file)}
                                theme={{
                                    formContainer: {
                                        margin: 0,
                                        padding: '0.8em'
                                    },
                                    formSection: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    },
                                    sectionBody: {
                                        margin: 0,
                                        width: "250px"
                                    },
                                    sectionHeader: {
                                        padding: "0.2em"
                                    },
                                    photoPickerButton: {
                                        padding: '1em',
                                        borderRadius: '50%'
                                    }
                                }}
                            />
                        <DialogActions>
                            <Button color="primary" onClick={()=>setShowPhotoPicker(false)}>
                                <Cancel /> Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                   
                </Grid>
                <Grid item style={{display: 'flex'}} xs={8}>
                    <TextField
                            id="newShortName-input"
                            label="newShortName"
                            rows={10}
                            defaultValue=""
                            variant="outlined"
                            className={classes.text}
                            value={newShortName || ''}
                            onChange={(event)=>setNewShortName(event.target.value)}
                    />
                    <TextField
                            id="newLongName-input"
                            label="newLongName"
                            rows={10}
                            defaultValue=""
                            variant="outlined"
                            className={classes.text}
                            value={newLongName || ''}
                            onChange={(event)=>setNewLongName(event.target.value)}
                    />

                    <TextField
                            id="newIdName-input"
                            label="newIdName"
                            rows={10}
                            defaultValue=""
                            variant="outlined"
                            className={classes.text}
                            value={newIdName || ''}
                            onChange={(event)=>setNewIdName(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} style={{display: 'flex'}}>
                    <TextField
                            id="newShortDescription-input"
                            label="newShortDescription"
                            rows={10}
                            defaultValue=""
                            multiline
                            variant="outlined"
                            className={classes.text}
                            value={newShortDescription || ''}
                            onChange={(event)=>setNewShortDescription(event.target.value)}
                    />
                    <TextField
                            id="newLongDescription-input"
                            label="newLongDescription"
                            rows={10}
                            defaultValue=""
                            variant="outlined"
                            multiline
                            className={classes.text}
                            value={newLongDescription || ''}
                            onChange={(event)=>setNewLongDescription(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} style={{display: 'flex'}}>
                    <TextField
                        id="newDefaultPrice-input"
                        label="newDefaultPrice"
                        rows={10}
                        defaultValue=""
                        variant="outlined"
                        className={classes.text}
                        value={newDefaultPrice || ''}
                        onChange={(event)=>setNewDefaultPrice(event.target.value)}
                    />
                    <TextField
                        id="newSingleTicketPrice-input"
                        label="newSingleTicketPrice"
                        rows={10}
                        defaultValue=""
                        variant="outlined"
                        className={classes.text}
                        value={newSingleTicketPrice || ''}
                        onChange={(event)=>setNewSingleTicketPrice(event.target.value)}
                    />
                    <TextField
                        id="newFeaturedTicketPrice-input"
                        label="newFeaturedTicketPrice"
                        rows={10}
                        defaultValue=""
                        variant="outlined"
                        className={classes.text}
                        value={newFeaturedTicketPrice || ''}
                        onChange={(event)=>setNewFeaturedTicketPrice(event.target.value)}
                    />
                    <TextField
                        id="newPrivateShowBookingPrice-input"
                        label="newPrivateShowBookingPrice"
                        rows={10}
                        defaultValue=""
                        variant="outlined"
                        className={classes.text}
                        value={newPrivateShowBookingPrice || ''}
                        onChange={(event)=>setNewPrivateShowBookingPrice(event.target.value)}
                    />
                </Grid>
                <Grid item xs={6} style={{display: 'flex'}}>
                    <TextField
                            id="newMaxPlayers-input"
                            label="newMaxPlayers"
                            rows={10}
                            defaultValue=""
                            variant="outlined"
                            className={classes.text}
                            value={newMaxPlayers || ''}
                            onChange={(event)=>setNewMaxPlayers(event.target.value)}
                    />
                    <TextField
                            id="newMinPlayers-input"
                            label="newMinPlayers"
                            rows={10}
                            defaultValue=""
                            variant="outlined"
                            className={classes.text}
                            value={newMinPlayers || ''}
                            onChange={(event)=>setNewMinPlayers(event.target.value)}
                    />
                    <TextField
                        id="youttubeInput"
                        label="youTubeLink"
                        defaultValue="YouTube Link to your Experience"
                        variant="outlined"
                        value={newYouTubeLink}
                        onChange={(event)=>setNewYouTubeLink(event.target.value)}
                        className={classes.text}
                    />
                    <TextField
                            id="newMinInitialTicketPurchase-input"
                            label="newMinInitialTicketPurchase"
                            rows={10}
                            defaultValue=""
                            variant="outlined"
                            className={classes.text}
                            value={newMinInitialTicketPurchase || ''}
                            onChange={(event)=>setNewMinInitialTicketPurchase(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                {
                        platformsCheckBox &&
                        <Box pt={2} pb={2}>
                            <Typography variant="h5">
                                Platforms:
                            </Typography>
                            {Object.keys(platformsCheckBox).map(platform => (
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={platformsCheckBox[platform]} 
                                            onChange={
                                                () => checkboxOnChange(platform)
                                            }
                                            name={platform} 
                                        />
                                    }
                                    label={platform}
                                />
                            ))}
                        </Box>

                    }
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center">
                <Button color="primary" onClick={() => handleSaveExperience()}>
                    <Save /> Save
                </Button>
                <Button color="primary" onClick={handleCloseExperiences}>
                    <Cancel /> Cancel
                </Button>
                {!isCreateNew && <Button color="primary" onClick={() => setShowConfirmationDialog(true)}>
                    <Delete /> Delete
                </Button>}
            </Box>
            
            <Dialog open={showConfirmationDialog} onClose={() => setShowConfirmationDialog(false)}>
                <DialogTitle>
                    <Typography variant="body1">
                        Are you sure you want to delete {experience.shortName}?
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <>
                        <Button color="primary" onClick={handleDeleteExperience}>
                            <Typography variant="button">
                                Yes
                            </Typography>
                        </Button>
                        <Button color="primary" onClick={() => setShowConfirmationDialog(false)}>
                            <Typography variant="button">
                                No
                            </Typography>
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ExperienceEditor;