// MILAD TODO: Admin info and Host info needs to be DRY
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    Link,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useContext, useState, useEffect} from 'react';

import { Delete, Edit} from '@mui/icons-material'
import HostViewContext from '../../../Context/HostViewContext';
import { API, graphqlOperation } from 'aws-amplify';
import { deleteShow } from '../customQueries/customQueries';
import { getRecurringShow } from '../../../graphql/queries';
import moment from 'moment';

const privateShowBaseURL = `${window.location.origin}/guest/private`;

const useStyles = makeStyles(theme => ({
    showInfoContainer: {
    },
    title: {
        display: "flex",
        justifyContent: 'center'
    },
    gridItem: {
        marginBottom: '3%'
    },
    bold: {
        fontWeight: 600
    },
    arrayGrid: {
        display: "flex",
        flexDirection: "column"
    }
}))

function DialogShowHostInfo({ getShows }){
    const classes = useStyles();
    const hostViewState = useContext(HostViewContext);
    const show = hostViewState.shows.find(show => show.id === hostViewState.selectedRow.id);
    const privateShow = show.privateShowID ? hostViewState.privateShows.find(privateShow => privateShow.id === show.privateShowID): null;


    let tzValue = hostViewState.timeZoneValue?.value || "America/Los_Angeles";
    let momentDate = moment.utc(show.showtime);
    let date = momentDate.tz(tzValue).format("ddd MM-DD");
    let time = momentDate.tz(tzValue).format("h:mmA z");

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showOrders, setShowOrders] = useState(false);
    const [showShow, setShowShow] = useState(true);
    const [showTickets, setShowTickets] = useState(false);
    const [recurringShows, setRecurringShows] = useState([]);

    useEffect(() => {
        if (show.recurringShowID) {
            ( async () => {
                try {
                    const recurringShowsResponse = await API.graphql(graphqlOperation(getRecurringShow, {id: show.recurringShowID}))
                    const recurringShows = recurringShowsResponse.data.getRecurringShow.shows.items
                    setRecurringShows(recurringShows.map(show => ({show: show, checked: false,
                    displayTime: (() => {
                            let tzValue = hostViewState.timeZoneValue?.value || "America/Los_Angeles";
                            let momentDate = moment.utc(show.showtime);
                            let displayTime = momentDate.tz(tzValue).format("MMM ddd Do YYYY hh:mm a");
                            return displayTime;
                        })()
                    })));
                } catch(e) {
                    console.log("error getting recurring shows:", e)
                }
            })()
        }
    }, [])
    
    function handleOnClose(){
        hostViewState.dispatch({type: 'shouldShowMoreShowInfo', shouldShowMoreShowInfo: false})
    }
    
    async function handleDelete() {
        setShowConfirmationDialog(false);
        const input = { id: show.id };
        const deletedShow = await API.graphql(graphqlOperation(deleteShow, { input }));

        handleOnClose();
    }

    async function handleDeleteRecurring() {
        handleOnClose();
        setShowConfirmationDialog(false);
        for (const show of recurringShows) {
            if (show.checked && show.show.status === "OPEN") {
                const input = { id: show.show.id };
                const deletedShow = await API.graphql(graphqlOperation(deleteShow, { input }));
            }
        }

        getShows();
    }

    function handleRecurringChange(index, event) {
        const recurringShowsCopy = [...recurringShows];
        recurringShowsCopy[index].checked = !recurringShowsCopy[index].checked;
        setRecurringShows(recurringShowsCopy);
    }
    
    function handleNoneClicked(){
        const recurringShowsCopy = recurringShows.map(show => ({...show, ...{checked: false}}));
        setRecurringShows(recurringShowsCopy);
    }
    
    function handleAllClicked(){
        const recurringShowsCopy = recurringShows.map(show => ({...show, ...{checked: true}}));
        setRecurringShows(recurringShowsCopy);
    }

    async function handleEdit() {
        hostViewState.dispatch({type: 'isShowEditMode', isShowEditMode: true})
        hostViewState.dispatch({type: 'shouldShowShowsCreateEdit', shouldShowShowsCreateEdit: true})
    }
    
    return <>
        {show && <>
            <Dialog open={hostViewState.shouldShowMoreShowInfo && !!show} onClose={handleOnClose} fullWidth={true}>
                <DialogTitle>
                    <Typography variant="h5" className={classes.title}>
                        Show Info
                    </Typography>
                </DialogTitle>
                <Divider light/>
                <DialogContent >
                <Accordion expanded={showShow} onClick={() => setShowShow(!showShow)}>
                    <AccordionSummary>
                        <Typography variant="h4">
                            General Show Info
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography variant="body1" className={classes.bold}>
                                    Experience
                                </Typography>
                                <Typography variant="body2">
                                    {show.experience.shortName}
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={4}>
                                <Typography variant="body1" className={classes.bold}>
                                    Host
                                </Typography>
                                <Typography variant="body2">
                                    {show.host.hostName}
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="body1" className={classes.bold}>
                                    Status
                                </Typography>
                                <Typography variant="body2">
                                    {show.status}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" className={classes.bold}>
                                    Room Code
                                </Typography>
                                <Typography variant="body2">
                                    {show.roomCode}
                                </Typography>
                            </Grid>

                            {show.highlightsLink && 
                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        highlightsLink
                                    </Typography>
                                    <Typography variant="body2">
                                        {show.highlightsLink}
                                    </Typography>
                                </Grid>
                            }

                            {show.status === "CURRENT" &&
                            <Button color="primary"
                                variant="contained"
                                color="primary"
                                target="_blank" 
                                href={`https://prod.d14i5qd6xkbaej.amplifyapp.com/?isAdmin=true&roomId=${show.roomCode}`}
                            >
                                Show Link
                            </Button>
                            }
                            <Grid item xs={4}>
                                <Typography variant="body1" className={classes.bold}>
                                    Show Time
                                </Typography>
                                <Typography variant="body2">
                                    {date} @ {time}
                                </Typography>
                            </Grid>
                            {show.recurringShowID && 
                                <Grid item xs={6}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Recurring Show
                                    </Typography>
                                </Grid>
                            }
                            {show.privateShowID && 
                                <Grid item xs={6}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Private Show Link
                                    </Typography>
                                    <Typography variant="body2">
                                        <Link href={`${privateShowBaseURL}/${privateShow.id}`}> 
                                            {privateShow.name}
                                        </Link>
                                    </Typography>
                                    <Typography variant="body1" className={classes.bold}>
                                        Private Show description
                                    </Typography>
                                    <Typography variant="body2">
                                        {privateShow.description}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={showOrders} onClick={() => setShowOrders(!showOrders)}>
                    <AccordionSummary>
                        <Typography variant="h4">
                            Orders Info
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.arrayGrid}>
                        {show.orders.items.map((order, index) => (
                            <Grid key={order.id} container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Order {index + 1}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        When Purchased
                                    </Typography>
                                    <Typography variant="body2">
                                        {order.createdAt}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Team Name
                                    </Typography>
                                    <Typography variant="body2">
                                        {order.teamName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Order Name
                                    </Typography>
                                    <Typography variant="body2">
                                        {order.guest.firstName} {order.guest.lastName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Order Email
                                    </Typography>
                                    <Typography variant="body2">
                                        {order.guest.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    }
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={showTickets} onClick={() => setShowTickets(!showTickets)}>
                    <AccordionSummary>
                        <Typography variant="h4">
                            Tickets Info
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.arrayGrid}>
                        {show.tickets.items.map((ticket, index) => (
                            <Grid key={ticket.id} container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Ticket {index + 1}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Guest Name
                                    </Typography>
                                    <Typography variant="body2">
                                        {ticket.guest.firstName} {ticket.guest.lastName}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Guest Email
                                    </Typography>
                                    <Typography variant="body2">
                                        {ticket.guest.email}
                                    </Typography>
                                </Grid>
                                
                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Client Time Zone
                                    </Typography>
                                    <Typography variant="body2">
                                        {ticket.clientTimezone}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        How much VR Experience
                                    </Typography>
                                    <Typography variant="body2">
                                        {ticket.howMuchVRExperience}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Platform
                                    </Typography>
                                    <Typography variant="body2">
                                        {ticket.platform}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.bold}>
                                        Additional Note
                                    </Typography>
                                    <Typography variant="body2">
                                        {ticket.additionalNote}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    }
                    </AccordionDetails>
                </Accordion>
                </DialogContent>
                <DialogActions>
                    {show.recurringShowID && show.status === "OPEN" 
                        ?
                            <>
                                <Button color="primary" onClick={()=>setShowConfirmationDialog(true)}>
                                    <Delete /> Delete
                                </Button>
                            </>
                        :
                            <>
                                {
                                    show.status === "OPEN" &&
                                        <Button color="primary" onClick={()=>setShowConfirmationDialog(true)}>
                                            <Delete /> Delete
                                        </Button>
                                }
                                {/* <Button color="primary" onClick={() => setShowConfirmationDialog(false)}>
                                    <Typography variant="button">
                                        No
                                    </Typography>
                                </Button> */}
                            </>
                    }
                        <Button color="primary" onClick={handleEdit}>
                            <Edit /> Edit
                        </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showConfirmationDialog} onClose={() => setShowConfirmationDialog(false)}>
                <DialogTitle>
                    <Typography variant="h5" className={classes.title}>
                        Are you sure you want to delete this show?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {
                        show.recurringShowID && show.status === "OPEN" &&
                            <Box display="flex" flexDirection="column">
                                <Typography variant="body1">
                                    This is part of a recurring show.  Please choose which shows you want to delete:
                                </Typography>
                                <Box display="flex" justifyContent="center" m={1}>
                                    <Button color="primary" style={{margin: "0 1em 0 1em"}} variant="contained" onClick={handleAllClicked}>All</Button>
                                    <Button color="primary" style={{margin: "0 1em 0 1em"}} variant="contained" onClick={handleNoneClicked}>None</Button>
                                </Box>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start" xs={6}>
                                        {
                                            recurringShows
                                                .slice(0, recurringShows.length === 1 
                                                        ? 1 
                                                        : Math.ceil(recurringShows.length / 2))
                                                .map((show, index) => (
                                                        <Grid key={show.displayTime} item xs={12}>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={show.checked} onChange={(event) => handleRecurringChange(index, event)} name={show.show.showtime} />}
                                                                label={show.displayTime}
                                                            />
                                                        </Grid>
                                                    )
                                                )
                                        }
                                    </Grid>
                                    <Grid container item direction="column" justifyContent="center" alignItems="flex-start" xs={6}>
                                        {
                                            recurringShows.length !== 1 && 
                                            recurringShows
                                                .slice(Math.ceil(recurringShows.length / 2))
                                                .map((show, index) => (
                                                    <Grid key={show.displayTime} item xs={12}>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={show.checked} onChange={(event) => handleRecurringChange(index + Math.ceil(recurringShows.length / 2), event)} name={show.show.showtime} />}
                                                            label={show.displayTime}
                                                        />
                                                    </Grid>
                                                ))}
                                    </Grid>
                                </Grid>
                            </Box>
                    }
                </DialogContent>
                <DialogActions>
                    {show.recurringShowID && show.status === "OPEN" 
                            ?
                                <>
                                    <Button color="primary" onClick={handleDeleteRecurring}>
                                        <Typography variant="button">
                                            Delete Shows
                                        </Typography>
                                    </Button>
                                    <Button color="primary" onClick={() => setShowConfirmationDialog(false)}>
                                        <Typography variant="button">
                                            Cancel
                                        </Typography>
                                    </Button>
                                </>
                            :
                                <>
                                    <Button color="primary" onClick={handleDelete}>
                                        <Typography variant="button">
                                            Yes
                                        </Typography>
                                    </Button>
                                    <Button color="primary" onClick={() => setShowConfirmationDialog(false)}>
                                        <Typography variant="button">
                                            No
                                        </Typography>
                                    </Button>
                                </>
                        }
                </DialogActions>
            </Dialog>
        </>
        }
    </>;
}
export default DialogShowHostInfo;