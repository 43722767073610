import { Button, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { API } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import './square-app.css';
import awsconfig from '../../../aws-exports';

let currentEnv = 
    awsconfig.aws_cloud_logic_custom
        .find(option => option.name === "alplatformapi")
        .endpoint.split("/");

currentEnv = currentEnv[currentEnv.length - 1];

const SHOULD_USE_SANDBOX = false;
const SQUARE_APPLICATION_ID = 
    currentEnv === 'prod' && SHOULD_USE_SANDBOX === false ? 
    "sq0idp-XGmink_zBFISOLgL_nMT3w" : "sandbox-sq0idb-FeLsfXIRZpFxTTO553bXew";
const SQUARE_LOCATION_ID = 
    currentEnv === 'prod' && SHOULD_USE_SANDBOX === false ? 
    "7BGF9ZWBKNJZY" : "LVFN05FEXDTYT";

let payments = null;
try {
    payments = window.Square.payments(SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID);
} catch(e) {
    console.log("e : ", e)
}
const useStyles = makeStyles(theme => {
    return ({
        payInput: {
            marginBottom: "3%"
        },
        field: {
            width: '100%',
            margin: '0% 5%',
            "& .MuiInputLabel-formControl": {
                color: "red"
            },
            marginBottom: '2%'
        },
        flexAlign: {
            display: "flex",
            alignContent: "center",
            justifyContent: "center"
        }

})})


function Payment ({form, formErrors, onChange, formHasBeenSubmitted, setFormHasBeenSubmitted, validate, show, isProcessing, setIsProcessing, windowRef, setValidPayment, validSubmit, isReview, note, amount, handlePostData, isFeaturedShow=false}) {
    const [card, setCard] = useState();

    const classes = useStyles();

    async function initializeCard() {
        if (!payments) throw new Error("no payments api setup");

        const card = await payments.card();
        await card.attach('#card-container');

        return card;
    }

    async function destroyCard(){
        if (!card) return; 

        await card.destroy();
    }

    async function setupCard(){
        try {
            setCard(await initializeCard());
        } catch (e) {
            console.error("Initializing card failed: ", e);
            return;
        }
    }

    async function createPayment(nonce, buyerVerificationToken) {
        const postData = {
            body: {
                show: show,
                form,
                location_id: SQUARE_LOCATION_ID,
                nonce,
                buyerVerificationToken,
                amount: form.amount === '' ? 0 : form.amount,
                note: note
            }
        };

        handlePostData(postData);
    }

    async function tokenize() {
        const tokenResult = await card.tokenize();

        if (tokenResult.status === "OK") {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
            if (tokenResult.errors) {
    
                errorMessage += ` and errors: ${JSON.stringify(
                  tokenResult.errors
                )}`;
              }
              throw new Error(errorMessage);
        }
    }

    async function handlePaymentMethodSubmission(event) {
        event.preventDefault();

        try {
            const token = await tokenize();
            const buyerVerificationBody = {
                amount: (form.amount / 100).toFixed(2),
                currencyCode: "USD",
                intent: "CHARGE",
                billingContact: {
                    familyName: form.lastName,
                    givenName: form.firstName,
                    email: form.email
                }
            }
            const details = await payments.verifyBuyer(token, buyerVerificationBody)
            const paymentResults = await createPayment(token, details);
        } catch (e) {
            console.error(e);
        }
    }

    function scrollToBottom(){
        windowRef.current.scrollTop = windowRef.current.scrollHeight
    }

    useEffect(() => 
    {
        setupCard();

        // Just in case the payment doesn't scroll down
        setTimeout(scrollToBottom, 250)

        return () => {
            destroyCard();
        }

    }, [form.leaveTip])

    return (
        < >
            {isReview &&
                <>
                    <div className={classes.flexAlign}>
                        <TextField
                            className={classes.field}
                            required
                            error={form.leaveTip && !!formErrors.email}
                            helperText={formErrors.email ? 
                                formErrors.email : 
                                "Please enter an email for a payment receipt"
                            }
                            label="Email" 
                            type="email" 
                            value={form.email} 
                            onChange={(e) => onChange('email', e.target.value)}
                            onBlur={() => formHasBeenSubmitted && validate()}
                            InputLabelProps={{
                                style: { color: '#000' },
                            }}
                        />
                    </div>
                    <div className={classes.flexAlign}>

                        <TextField
                            required
                            className={classes.field}
                            error={!!formErrors.amount}
                            helperText={formErrors.amount}
                            label="Tip Amount" 
                            type="number"
                            value={Number(form.amount) <= 0 ? "" : (form.amount / 100).toString()}
                            onChange={(e) => onChange('amount', Number(e.target.value) * 100)}
                            onBlur={() => formHasBeenSubmitted && validate()}
                            InputProps={{
                                startAdornment: form.amount && <InputAdornment position="start">$</InputAdornment>
                            }}
                            InputLabelProps={{
                                style: { color: '#000' },
                            }}
                        />
                    </div>
                </>
            }
            <form id="payment-form">
                <div id="card-container"></div>

                <div style={{marginBottom: "1%"}} className={classes.flexAlign}>
                    <Button
                        style={{width: '50%'}}
                        variant="contained"
                        disabled={!validSubmit || (isReview && Number(form.amount) <= 0)}
                        color="primary" 
                        onClick={handlePaymentMethodSubmission}
                    >
                        Pay ${isReview ? (form.amount / 100).toFixed(2) : (amount / 100).toFixed(2) }
                    </Button>
                </div>

            </form>
        </>
    )
}

export default Payment;