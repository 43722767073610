import React, {useEffect, useContext, useRef, useState, useCallback} from 'react';
import { listExperiences, showsByHost, listPrivateShows, privateShowsByUserId, listHosts } from '../../../graphql/queries';
import { onCreateShow, onUpdateShow } from '../../../graphql/subscriptions';
import { onDeleteShow, listShows } from '../customQueries/customQueries';
import { API, graphqlOperation } from 'aws-amplify';

import moment from 'moment';
import UserContext from '../../../Context/UserContext';
import HostViewContext from '../../../Context/HostViewContext';

import { Card, Checkbox, Container, FormControlLabel, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Edit, Person, Repeat, Email, CalendarToday, Work} from '@mui/icons-material';

import { DataGridPro, LicenseInfo, GridToolbar, GridLinkOperator } from '@mui/x-data-grid-pro';

import DialogCreateEdit from '../Dialog/DialogCreateEdit';
import { checkMatch, serializeShow } from '../../../Common/searchUtils';
import DialogShowAdminInfo from '../Dialog/DialogShowAdminInfo';
import DialogShowHostInfo from '../Dialog/DialogShowHostInfo';

import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Calendar from '../../../lib/React-Calendar/index.js';
import '../../Guest/EventBoard/Calendar.css';

LicenseInfo.setLicenseKey(
    '470b58d6e0ef3f63b4ee8f640792fad7T1JERVI6MjUzNTksRVhQSVJZPTE2NTM3NjAxNDEwMDAsS0VZVkVSU0lPTj0x',
  );


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: '100%',
    },
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '95vw',
        height: '100%',
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid black`,
          },
    },
    calendar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: '1%',
        marginBottom: '1%'
    },
    showsTable: {
        width: '100%',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(0),
        left: 0,
        padding: "1%",
        zLevel: '999'
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "center"
    },
    buttonClearSelection: {
        marginBottom: theme.spacing(2),
    },
    staticTooltipLabel: {
        backgroundColor: theme.palette.secondary.main
    },
    speedDial: {
        // width: '100%'
    }
}))

const serializeCache = {};

function Shows ( ) {
    const userState = useContext(UserContext);
    const hostViewState = useContext(HostViewContext);
    const user = userState.dbUser;

    const classes = useStyles();
    const calRef = useRef(null);
    
    let tzValue = hostViewState.timeZoneValue?.value || "America/Los_Angeles";
    let currentRecurringGroupNumber = 1;
    let recurringGroupNumbers = {};
    
    // #region state
    const [selectedDays, setSelectedDays] = useState({});

    const [showPast, setShowPast] = useState(false);
    const [speedDialOpen, setSpeedDialOpen] = useState(false);

    const [currentLabel, setCurrentLabel] = useState('');


    const [additionalQueryOptions, setAdditionalQueryOptions] = useState({ 
    });

    const [newShowsRows, setNewShowsRows] = useState([]);

    const [queryTypeString, setQueryTypeString] = useState("");
    const [queryTypeQuery, setQueryTypeQuery] = useState("");

    const [transformedShows, setTransformedShows] = useState({});

    const [pageSize, setPageSize] = useState(50);
    // #endregion state

    // #region SPEED DIAL
    const handleSpeedDialOpen = () => {
        hostViewState.dispatch({type: 'shouldShowSpeedDialOpen', shouldShowSpeedDialOpen: true})

        setSpeedDialOpen(true);
    };

    const handleSpeedDialClose = (event, reason) => {
        hostViewState.dispatch({type: 'shouldShowSpeedDialOpen', shouldShowSpeedDialOpen: false});
        setCurrentLabel('');
    };

    const handleShowCalendarClick = (event) => {
        event.stopPropagation();
        hostViewState.dispatch({ type: "showShowsCalendar", showShowsCalendar: !hostViewState.showShowsCalendar})
    };

    const handleShowListClick = (event) => {
        hostViewState.dispatch({ type: "showShowsList", showShowsList: !hostViewState.showShowsList});
    };

    const handleExperienceEditorClicked = (event) => {
        event.stopPropagation();
        hostViewState.dispatch({ type: "showExperienceEditor", showExperienceEditor: true})
        handleSpeedDialClose();
    };

    const handleHostEditorClicked = (event) => {
        event.stopPropagation();
        hostViewState.dispatch({ type: "showProfileEditor", showProfileEditor: true});
        handleSpeedDialClose();
    };
    
    const handleEmailEditorClicked = (event) => {
        event.stopPropagation();
        hostViewState.dispatch({ type: "showEmailEditor", showEmailEditor: true});
        handleSpeedDialClose();
    }; 

    const handleCalendarEditorClicked = (event) => {
        event.stopPropagation();
        hostViewState.dispatch({ type: "showCalendarEditor", showCalendarEditor: true});
        handleSpeedDialClose();
    };

    const handleAdministrationClicked = (event) => {
        event.stopPropagation();
        hostViewState.dispatch({ type: "showAdministration", showAdministration: true});
        handleSpeedDialClose();
    };

    function onToolTipMouse(event){
        const text = event.target.innerText;
        setCurrentLabel(text);
    }

    function handleAddNewShow(){
        hostViewState.dispatch({type: 'isShowEditMode', isShowEditMode: false})
        hostViewState.dispatch({type: 'shouldShowShowsCreateEdit', shouldShowShowsCreateEdit: true})
    }

    // #endregion SPEED DIAL

    // #region Get shows
    async function* buildShows(combinedQueryOptions){
        try {
            if (!queryTypeQuery) return;
            let showList = [];
            let shows = await API.graphql(graphqlOperation(queryTypeQuery, combinedQueryOptions));
            showList = shows.data[queryTypeString].items;
            yield showList;
            let nextToken = shows.data[queryTypeString].nextToken;
            showList = [];
            while (nextToken) {
                const nextTokenShows = await API.graphql(
                    graphqlOperation(
                        queryTypeQuery, 
                        {...combinedQueryOptions, ...{ nextToken: nextToken }}
                    ));
                const tempNextTokenShows = nextTokenShows.data[queryTypeString].items;
                nextToken = nextTokenShows.data[queryTypeString].nextToken;

                if (tempNextTokenShows) {
                    showList = [...showList, ...tempNextTokenShows];
                }

                yield showList;
            }
            
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    async function getShows(shouldMaybeGetOldShows=false){
        if (shouldMaybeGetOldShows) {
            if (hostViewState.pastShows.length) return;
        }
        
        let momentDate = moment.utc(new Date());
        let formatDate = momentDate.tz(tzValue).format("YYYY-MM-DD");
        try {
            let combinedQueryOptions;
            if (user.isAdmin) {
                combinedQueryOptions = {
                    ...additionalQueryOptions,
                    ...{
                        filter: {
                            showtime: {
                                [shouldMaybeGetOldShows ? "lt" : "ge"]: formatDate
                            }
                        },
                        limit: 1000
                    }
                }
            } else {
                if (!additionalQueryOptions.hostID) return;
                combinedQueryOptions = { ...additionalQueryOptions }
            }
            
            const buildShowGenerator = buildShows(combinedQueryOptions);
            let nextObject = await buildShowGenerator.next();
            let showList = [];
            while (!nextObject.done) {
                showList = [...showList, ...nextObject.value];
                if (!user.isAdmin) {
                    showList = showList.filter(show => {
                        if (shouldMaybeGetOldShows) {
                            return show.showtime < formatDate;
                        } else {
                            return show.showtime >= formatDate;
                        }
                    })
                }
                
                nextObject = await buildShowGenerator.next();
                if (shouldMaybeGetOldShows) {
                    hostViewState.dispatch({type: 'pastShows', pastShows: showList});
                } else {
                    hostViewState.dispatch({type: 'shows', shows: showList});
                }
            }

        } catch (e) {
            console.error("Error getting shows: ", e);
        }
    }

    async function getExperiences(){
        try {
            let experiences = await API.graphql(graphqlOperation(listExperiences));
            experiences = experiences.data.listExperiences.items;
    
            hostViewState.dispatch({ type: 'experiences', experiences });

        } catch (e) {
            console.log("Error getting experiences: ", e)
        }
    }

    async function getPrivateShows(){
        try {
            let privateShows;

            if (user.isAdmin) {
                privateShows = await API.graphql(graphqlOperation(listPrivateShows));
                privateShows = privateShows.data.listPrivateShows.items;
            } else {
                privateShows = await API.graphql(graphqlOperation(privateShowsByUserId, { createdByUserID: user.id }));
                privateShows = privateShows.data.privateShowsByUserID.items;
            }

            hostViewState.dispatch({ type: 'privateShows', privateShows });
        } catch (e) {
            console.log("Error getting privateShows: ", e)
        }
    }

    async function getHosts(){
        try {
            let hosts = await API.graphql(graphqlOperation(listHosts));
            hosts = hosts.data.listHosts.items;
    
            hostViewState.dispatch({type: 'hosts', hosts: hosts});
        } catch (e) {
            console.log("Error getting hosts: ", e)
        }
    }

    // #endregion Get Shows

    // #region Calendar
    function handleOnActiveStartDateChange({activeStartDate, value, view}){
        let momentDate = moment.utc(activeStartDate);
        let tzValue = hostViewState.timeZoneValue?.value || "America/Los_Angeles";
        let formatDate = momentDate.tz(tzValue).format("ddd MM-DD");
        let calendarValue = activeStartDate;
        hostViewState.dispatch({type: 'calendarValue', calendarValue: calendarValue, formatDate: formatDate});
    }

    function getAdjustedCalendarValueAndFormatDate(date, tzValue){
        let momentDate = moment.utc(date);
        let adjustedTZDate = momentDate.tz(tzValue).toDate();
        let formatDate = momentDate.tz(tzValue).format("ddd MM-DD");
        return { adjustedTZDate, formatDate }
    }

    function onChange(value){
        const {adjustedTZDate, formatDate} = getAdjustedCalendarValueAndFormatDate(value, tzValue);
        const newSelectedDays = {...selectedDays};
        if (newSelectedDays[formatDate]) {
            delete newSelectedDays[formatDate];
            setSelectedDays(newSelectedDays)
        } else {
            setSelectedDays(
                {
                    ...newSelectedDays, 
                    ...{[formatDate]: true}
                }
            )
        }

    }
    
    function tileDisabled({ date, view}){
        const {formatDate} = getAdjustedCalendarValueAndFormatDate(date, tzValue);
        if (typeof transformedShows[formatDate]?.disabled === Boolean || !transformedShows[formatDate]) {
            return true
        } else {
            return false;
        }
    }

    const tileContent = useCallback( ({activeStartDate, date, view}) => {
        const {formatDate} = getAdjustedCalendarValueAndFormatDate(date, tzValue);

        let showsOpen = transformedShows[formatDate]?.showsOpen || 0;
        let showsBooked = transformedShows[formatDate]?.showsBooked || 0;
        return (
            <>
                <Typography className={classes.showsOpen} variant="caption" align="center" component="p">
                    B: {showsBooked}
                </Typography>
                <Typography className={classes.showsOpen} variant="caption" align="center" component="p">
                    S: {showsOpen + showsBooked}
                </Typography>
            </>
        )
    }, [showPast, transformedShows])

    const {formatDate} = getAdjustedCalendarValueAndFormatDate(hostViewState.calendarValue, tzValue);

    let transformedShowsDates = Object.keys(transformedShows);

    const tileClassName = useCallback( ({activeStartDate, date, view}) => {
        let className = '';
        const {adjustedTZDate, formatDate} = getAdjustedCalendarValueAndFormatDate(date, tzValue);
        const {adjustedTZDate: selectedDateAdjustedTZDate, formatDate: selectedDateFormatDate} = getAdjustedCalendarValueAndFormatDate(hostViewState.calendarValue, tzValue);


        if (transformedShows[formatDate]?.selected) {
            className += 'selected ';
        } else {
            className = className.replace('selected', '');
        }
        let showsOpen = transformedShows[formatDate]?.showsOpen;
        if (showsOpen === undefined) {  
        } else if (showsOpen === 0) {
            // className += 'noAvailableShows ';
        } else if (showsOpen > 0) {
        }

        return className;
    }, [showPast, transformedShows, selectedDays])
    
    const selectedDaysKeys = Object.keys(selectedDays);
    // #endregion Calendar

    // #region Table
    function makeColumns(){

        const columns = [
            { width: 120, field: 'status', headerName: 'status', description: 'status' },
            { width: 150, field: 'date', headerName: 'date', description: 'date', type: 'string' },
            { width: 120, field: 'time', headerName: 'time', description: 'time' },
            { width: 160, field: 'roomCode', headerName: 'room code', description: 'roomCode'},
            { width: 160, field: 'experience', headerName: 'experience', description: 'experience' },
            { width: 120, field: 'tickets', headerName: 'tickets', description: 'tickets', type: 'number', },
            { width: 120, field: 'showtime', headerName: 'showtime', description: 'showtime', type: 'date', hide: true},
            { width: 180, field: 'recurringGroup', headerName: 'recurring group', description: 'recurringGroup', renderCell: (GridCellParams) => {
                return (<>{GridCellParams.value ? <><Repeat fontSize="small"></Repeat>{GridCellParams.value} </> : null}</>)
            } },
            { width: 160, field: 'privateShow', headerName: 'private show', description: 'private show name' },
            { width: 100, field: 'featuredShow', headerName: 'featured', description: 'featured show' },
            // TODO: Commenting this out for now.  I don't think the current status is being used
            // { width: 150, field: 'showLink', headerName: 'show link', description: 'showLink', renderCell: (GridCellParams) => {
            //     return (
            //         <>
            //             {
            //                 GridCellParams.row.status === "CURRENT"
            //                 ?
            //                     <Button color="primary"
            //                         variant="contained"
            //                         color="primary"
            //                         target="_blank" 
            //                         href={`https://prod.d14i5qd6xkbaej.amplifyapp.com/?isAdmin=true&roomId=${GridCellParams.row.roomCode}&userName=${user.host.hostName}`}
            //                     >
            //                         Show Link
            //                     </Button>
            //                 :
            //                     null
            //             }
            //         </>
            //     )
            // }},
            { width: 120, field: 'reviews', headerName: 'reviews', description: 'reviews', type: 'number', },
        ];
        
        const adminColumns = [
            { width: 160, field: 'host', headerName: 'host', description: 'host' },
        ]
            
        return [...columns, ...(user.isAdmin ? adminColumns: []), { width: 150, field: 'showtime', headerName: 'showtime', description: 'showtime', hide: false }]
    }

    const renderShowsRows = useCallback(() => {
        const transformedShowsTemp = {};
        let todayDate = moment.utc(Date.now());
        const allShows = [...hostViewState.pastShows, ...hostViewState.shows];
        const transformedData = allShows.map((newEvent, index) => {
            let tzValue = hostViewState.timeZoneValue?.value || "America/Los_Angeles";
            let momentDate = moment.utc(newEvent.showtime);

            let date = momentDate.tz(tzValue).format("ddd MM-DD");
            let time = momentDate.tz(tzValue).format("h:mmA z");
            // Transformed shows are what the calendar uses to render it's tiles
            if (!transformedShowsTemp[date]) {
                transformedShowsTemp[date] = {
                    shows: [],
                    disabled: true,
                    showsOpen: 0,
                    selected: false,
                    showsBooked: 0,
                    formatDate: date
                };
            }

            if (newEvent.status === 'OPEN') {
                transformedShowsTemp[date].disabled = false;
                transformedShowsTemp[date].showsOpen++;
            }

            if (newEvent.status === 'BOOKED' || newEvent.status === 'SHOULD_START' || newEvent.status === 'SHOULD_END' || newEvent.status === 'COMPLETED') {
                transformedShowsTemp[date].disabled = false;
                transformedShowsTemp[date].showsBooked++;
            }

            if (selectedDays[date]) {
                transformedShowsTemp[date].selected = true;
            } else {
                transformedShowsTemp[date].selected = false;
            }

            transformedShowsTemp[date].shows.push({ ...newEvent, ...{displayTime: time} });
            setTransformedShows(transformedShowsTemp);
            
            if (!serializeCache[newEvent.id]) {
                serializeCache[newEvent.id] = serializeShow(newEvent, date, time, true, tzValue);
            }

            newEvent.serialize = serializeCache[newEvent.id];
            if (hostViewState.searchTerms && !checkMatch(hostViewState.searchTerms, newEvent.serialize)) return null;

            if (newEvent.recurringShowID && !recurringGroupNumbers[newEvent.recurringShowID]) {
                recurringGroupNumbers[newEvent.recurringShowID] = currentRecurringGroupNumber;
                currentRecurringGroupNumber++;
            }

            let privateShow = hostViewState.privateShows.find( (privateShow) => privateShow.id === newEvent.privateShowID);

            const hostFields = {
                id: newEvent.id,
                status: newEvent.status === "SHOULD_START" ? "BOOKED" : newEvent.status,
                date: moment.utc(newEvent.showtime).tz(tzValue).format("ddd MM-DD"),
                time: moment.utc(newEvent.showtime).tz(tzValue).format("hh:mmA"),
                price: newEvent.experience.defaultPrice / 100,
                host: newEvent.host.hostName,
                experience: newEvent.experience.shortName,
                tickets: newEvent.tickets?.items?.length || 0,
                reviews: newEvent.reviews?.items?.length || 0,
                roomCode: newEvent.roomCode,
                showtime: newEvent.showtime,
                recurringGroup: recurringGroupNumbers[newEvent.recurringShowID],
                privateShow: privateShow?.name,
                featuredShow: newEvent.showType === "FEATURED" ? "Y" : "N" 
            };
            return hostFields;
        })

        if (!transformedData) {
            transformedData = [];
        }
        return transformedData.filter(show => {
            if (show === null) {
                return false;
            }

            let momentDate = moment.utc(show.showtime);

            if (!showPast) {
                if (momentDate < todayDate) {
                    return false;
                }
            }

            return true;
        });
    }, [hostViewState.shows, hostViewState.pastShows]);

    function handleRowClicked(row){
        hostViewState.dispatch({type: 'newSelectedRow', selectedRow: row.row})
        hostViewState.dispatch({type: 'shouldShowMoreShowInfo', shouldShowMoreShowInfo: true})
    }
    // #endregion Table

    // #region useEffect
    useEffect( () => {

        getShows();
        getExperiences();
        getPrivateShows();

        if (!hostViewState.hosts.length && user.isAdmin) {
            getHosts();
        }

        // #region subscribe
        // TODO: LEAVING THIS IN FOR NOW BUT THIS IS A BIG TODO TO ADD SUBSCRIPTION
        // const unsubscribeToDeleteShows = API.graphql(graphqlOperation(onDeleteShow))
        // .subscribe({
        //     next: ({provider, value}) => {
        //         if (value?.errors?.length > 0) {
        //             console.log("there were subscribe errors deleting!", value.errors)
        //             return;
        //         }
        //         hostViewState.dispatch({type: 'showDeleted', showDeleted: value.data.onDeleteShow})
        //     },
        //     error: (error) => {
        //         console.error(error);
        //     }
        // })

        // const unsubscribeToUpdateShows = API.graphql(graphqlOperation(onUpdateShow))
        // .subscribe({
        //     next: ({provider, value}) => {
        //         if (value?.errors?.length > 0) {
        //             console.log("there were subscribe errors updating!", value.errors)
        //             return;
        //         }
        //         hostViewState.dispatch({type: 'showUpdated', showUpdated: value.data.onUpdateShow})
        //     },
        //     error: (error) => {
        //         console.error(error);
        //     }
        // })

        // const unsubscribeToCreateShows = API.graphql(graphqlOperation(onCreateShow))
        //     .subscribe({
        //         next: ({provider, value}) => {
        //             if (value?.errors?.length > 0) {
        //                 console.log("there were subscribe errors creating!", value.errors)
        //                 return;
        //             }
        //             const show = value.data.onCreateShow;
        //              if (user.isAdmin || show.createdByUserId === user.id) {
        //                 hostViewState.dispatch({type: 'newShowCreated', newShowCreated: show})
        //             }
        //         },
        //         error: (error) => {
        //             console.error(error);
        //         }
        //     })

        // return () => {
        //     unsubscribeToDeleteShows.unsubscribe();
        //     unsubscribeToUpdateShows.unsubscribe();
        //     unsubscribeToCreateShows.unsubscribe();
        // }
        // #endregion subscribe
    }, [user, additionalQueryOptions, queryTypeQuery])

    useEffect(()=> {
        if (!hostViewState.calendarValue) {
            let showTime = transformedShows[Object.keys(transformedShows)[0]].shows[0].showtime;
            const {adjustedTZDate, formatDate} = getAdjustedCalendarValueAndFormatDate(showTime, tzValue);
    
            hostViewState.dispatch({
                type: 'calendarValue', calendarValue: adjustedTZDate, formatDate: formatDate
            });
        }
    }, [])

    useEffect(() => {
        const shows = renderShowsRows();
        setNewShowsRows(shows);

    }, [hostViewState.shows, showPast, selectedDays, hostViewState.searchTerms])

    
    useEffect(() => {
        if (user.isAdmin) {
            setQueryTypeQuery(listShows);
            setQueryTypeString("listShows");
        } else {
            setQueryTypeQuery(showsByHost);
            setQueryTypeString("showsByHost");

            setAdditionalQueryOptions({ hostID: user.hostID});
        }
    }, [user])

    // #endregion useEffect

    useEffect(() => {
        if (!showPast) {
            getShows(true);
        }
    }, [showPast])

    return (
        <div className={classes.root}>
            <div className={classes.buttonGroup}>
                <FormControlLabel
                    control={
                        <Checkbox
                            // disabled={!hostViewState.shows.length}
                            checked={showPast}
                            onChange={() => setShowPast(!showPast)}
                            name="showPast"
                        />
                    }
                    label="Show Past Events"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={hostViewState.showShowsCalendar}
                            onClick={handleShowCalendarClick}
                        />
                    }
                    label="Show Calendar"
                />
            </div>
        <Paper className={classes.main}>
            { hostViewState.showShowsCalendar &&
                <Container className={classes.calendar} ref={calRef}>
                    <Card elevation={3}>
                        <Calendar
                            onActiveStartDateChange={handleOnActiveStartDateChange}
                            onChange={onChange}
                            tileDisabled={tileDisabled}
                            tileContent={tileContent}
                            tileClassName={tileClassName}
                            view='month'
                            minDate={showPast && newShowsRows[0] ? new Date(newShowsRows[0].showTime) : new Date()}
                            maxDate={ 
                                (() => {
                                    const result = new Date();
                                    result.setDate(result.getDate() + 90);
                                    return result;
                                })()
                            }
                        />
                    </Card>
                </Container>
            }
            {
                Object.keys(selectedDays).length > 0 &&
                <Button color="primary" 
                    className={classes.buttonClearSelection}
                    variant="contained" color="primary"
                    size="small"
                    onClick={() => setSelectedDays({})}
                >
                    Clear Selection
                </Button>

            }
            
            
            { hostViewState.showShowsList && 
                <DataGridPro
                    autoHeight
                    className={classes.showsTable}
                    rows={newShowsRows} 
                    columns={makeColumns()} 
                    components={{
                        Toolbar: GridToolbar
                    }}
                    disableSelectionOnClick
                    showCellRightBorder
                    loading={newShowsRows.length <= 0}
                    initialState={{
                        sorting: {
                          sortModel: [{ field: 'showtime', sort: 'asc' }],
                        }
                    }}
                    onRowClick={handleRowClicked}
                    filterModel={ selectedDaysKeys.length > 0 ?
                        {
                            items:
                                Object.keys(selectedDays).map(day => ({
                                    columnField: 'date', 
                                    operatorValue: 'contains', 
                                    value: day,
                                    id: day
                                })),
                            linkOperator: GridLinkOperator.Or
                        } :
                        { items: []}
                    }
                    rowsPerPageOptions={[5, 20, 50, 100, 250, 500]}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={pageSize => setPageSize(pageSize)}
                />
            }

            
            {hostViewState.shouldShowMoreShowInfo && 
                (user.isAdmin ? <DialogShowAdminInfo getShows={getShows}/> : <DialogShowHostInfo getShows={getShows}/>)
            }
            {hostViewState.shouldShowShowsCreateEdit && <DialogCreateEdit getShows={getShows} getPrivateShows={getPrivateShows}/>}
        </Paper>
        <div className={classes.fab}>
            <SpeedDial
                ariaLabel="SpeedDial Add Shows"
                className={classes.speedDial}
                icon={<Edit />}
                onClose={handleSpeedDialClose}
                onOpen={handleSpeedDialOpen}
                open={hostViewState.shouldShowSpeedDialOpen}
                onClick={handleAddNewShow}
            >
                <SpeedDialAction
                    icon={<Add />}
                    tooltipTitle="New Show"
                    tooltipOpen
                    onClick={handleAddNewShow}
                    tooltipPlacement="right"
                    onMouseEnter={onToolTipMouse}
                    onMouseLeave={onToolTipMouse}
                    classes={{staticTooltipLabel: currentLabel === "New Show" ? classes.staticTooltipLabel : ''}}

                />
                {user.isAdmin &&
                        <SpeedDialAction
                            icon={<WbSunnyIcon />}
                            tooltipTitle="Experience Editor"
                            tooltipOpen
                            onClick={handleExperienceEditorClicked}
                            tooltipPlacement="right"
                            onMouseEnter={onToolTipMouse}
                            onMouseLeave={onToolTipMouse}
                            classes={{staticTooltipLabel: currentLabel === "Experience Editor" ? classes.staticTooltipLabel : ''}}

                    />
                }
                {user.isAdmin &&
                        <SpeedDialAction
                            icon={<Person />}
                            tooltipTitle="Host Editor"
                            tooltipOpen
                            onClick={handleHostEditorClicked}
                            tooltipPlacement="right"
                            onMouseEnter={onToolTipMouse}
                            onMouseLeave={onToolTipMouse}
                            classes={{staticTooltipLabel: currentLabel === "Host Editor" ? classes.staticTooltipLabel : ''}}


                    />
                }
                {user.isAdmin &&
                        <SpeedDialAction
                            icon={<Email />}
                            tooltipTitle="Email Editor"
                            tooltipOpen
                            onClick={handleEmailEditorClicked}
                            tooltipPlacement="right"
                            classes={{staticTooltipLabel: currentLabel === "Email Editor" ? classes.staticTooltipLabel : ''}}
                            onMouseEnter={onToolTipMouse}
                            onMouseLeave={onToolTipMouse}
                        />
                }
                {user.isAdmin &&
                    <SpeedDialAction
                        icon={<CalendarToday />}
                        tooltipTitle="Calendar Editor"
                        tooltipOpen
                        onClick={handleCalendarEditorClicked}
                        tooltipPlacement="right"
                        classes={{staticTooltipLabel: currentLabel === "Calendar Editor" ? classes.staticTooltipLabel : ''}}
                        onMouseEnter={onToolTipMouse}
                        onMouseLeave={onToolTipMouse}
                    />
                }
                {user.isAdmin && 
                    <SpeedDialAction
                        icon={<Work />}
                        tooltipTitle="Administration View"
                        tooltipOpen
                        onClick={handleAdministrationClicked}
                        tooltipPlacement="right"
                        classes={{staticTooltipLabel: currentLabel === "Administration" ? classes.staticTooltipLabel : ''}}
                        onMouseEnter={onToolTipMouse}
                        onMouseLeave={onToolTipMouse}
                    />
                        
                }
            </SpeedDial>
        </div>
        </div>
    )
}

export default React.memo(Shows);
