import React from 'react';
import './App.css';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import { Redirect, Route, Switch } from 'react-router-dom';

import GuestView from './Components/Guest/GuestView.js';
import HostView from './Components/Host/HostView.js';

Amplify.configure(awsconfig);


function App() {

    return (
        <div id="App">
            <Switch>
                <Route path="/host">
                    <HostView />
                </Route>
                <Route path="/guest">
                    <GuestView />
                </Route>
                <Route path="/">
                    <Redirect to="/guest" />
                </Route>
            </Switch>
        </div>
    )
}

export default App;