import API, { graphqlOperation } from '@aws-amplify/api';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { listExperiences } from '../../../graphql/queries';
import HostContext from '../../../Context/HostViewContext';
import {
    Box,
    Chip,
    FormControl,
    Grid,
    Button,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Cancel, Save } from '@mui/icons-material';
import { updateCalendarTemplates } from '../../../graphql/mutations';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'draft-js/dist/Draft.css';


function CalendarEditor(){
    const hostViewState = useContext(HostContext);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorRawData, setEditorRawData] = useState(null);
    const [summary, setSummary] = useState("");
    const [location, setLocation] = useState("");
    const [currentStaticAttendee, setCurrentStaticAttendee] = useState("");
    const [staticAttendees, setStaticAttendees] = useState(["test@test.com"]);

    const [creatorDisplayName, setCreatorDisplayName] = useState("");
    const [creatorEmail, setCreatorEmail] = useState("");
    const [creatorID, setCreatorID] = useState("");

    const [organizerDisplayName, setOrganizerDisplayName] = useState("");
    const [organizerEmail, setOrganizerEmail] = useState("");
    const [organizerID, setOrganizerID] = useState("");

    const editor = React.useRef(null);
    
    const useStyles = makeStyles(theme => ({
        calendarEditorContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        },
        experienceSelect: {
            width: '25%'
        },
        formLabel: {
            width: '100%'
        },
        text: {
            width: '33%',
            margin: '0.5rem'
        },
        editButton: {
            position: 'absolute',
            bottom: '5%',
            right: '5%',
            background: 'white'
        },
        summary: {
            width: '50vw',
            margin: '1rem 0 1rem 0'
        },
        handleAddStaticAttendeeButton: {
            height: '1em',
            width: '1em'
        },
    }))

    function onEditorStateChange(editorState){
        setEditorState(editorState);
        setEditorRawData((convertToRaw(editorState.getCurrentContent())));
    }  

    useEffect(
        () => {
            if (!hostViewState.calendarState?.calendarTemplate) return;

            let contentBlock = hostViewState.calendarState.calendarTemplate.rawData;
            if (typeof contentBlock === 'string') {
                contentBlock = (() => { try { return JSON.parse(contentBlock) } catch(e) { console.log("error reading content block", e)} })();
            } 
            if (contentBlock && typeof contentBlock === 'object' && Object.keys(contentBlock).length > 0) {
                const contentState = convertFromRaw(contentBlock);
                let editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            } else {
                setEditorState(EditorState.createEmpty());
            }

            setSummary(hostViewState.calendarState.calendarTemplate?.summary || "")
            setLocation(hostViewState.calendarState.calendarTemplate?.location || "")
            setStaticAttendees(hostViewState.calendarState.calendarTemplate?.attendees || [])
            
            setCreatorDisplayName(hostViewState.calendarState.calendarTemplate?.creator?.displayName || "")
            setCreatorEmail(hostViewState.calendarState.calendarTemplate?.creator?.email || "")
            setCreatorID(hostViewState.calendarState.calendarTemplate?.creator?.id || "")

            setOrganizerDisplayName(hostViewState.calendarState.calendarTemplate?.organizer?.displayName || "")
            setOrganizerEmail(hostViewState.calendarState.calendarTemplate?.organizer?.email || "")
            setOrganizerID(hostViewState.calendarState.calendarTemplate?.organizer?.id || "")
    }, [hostViewState.calendarState.calendarTemplate])

    useEffect(()=>{
        if (!hostViewState.experiences.length) {
            getExperiences();
        }
    }, [])

    useEffect(() => {
        if (!hostViewState.experiences?.length) return;

        let foundIndex = hostViewState.experiences?.findIndex(
            experience => experience.id === hostViewState.calendarState.experience?.id);
        foundIndex = foundIndex > -1 
            ? foundIndex
            :  0;

        const experience = hostViewState.experiences[foundIndex];
        if (experience) {
            const transformedCalendars = returnTransformedCalendarList(experience.calendarTemplates.items);
            
            let foundCalendarTemplateIndex = transformedCalendars.findIndex(
                calendar => calendar.id === hostViewState.calendarState?.calendarTemplate?.id);

            foundCalendarTemplateIndex =  foundCalendarTemplateIndex > -1 
                ? foundCalendarTemplateIndex
                : 0;

            hostViewState.dispatch({
                type: "calendarState",
                calendarState: {
                    experience: experience,
                    calendarTemplates:transformedCalendars,
                    calendarTemplate: transformedCalendars[foundCalendarTemplateIndex],
                }
            })

            setSummary(transformedCalendars[foundCalendarTemplateIndex].summary  || "");
            setLocation(transformedCalendars[foundCalendarTemplateIndex]?.location || "")
            setStaticAttendees(transformedCalendars[foundCalendarTemplateIndex]?.attendees || [])
            
            setCreatorDisplayName(transformedCalendars[foundCalendarTemplateIndex]?.creator?.displayName || "")
            setCreatorEmail(transformedCalendars[foundCalendarTemplateIndex]?.creator?.email || "")
            setCreatorID(transformedCalendars[foundCalendarTemplateIndex]?.creator?.id || "")
    
            setOrganizerDisplayName(transformedCalendars[foundCalendarTemplateIndex]?.organizer?.displayName || "")
            setOrganizerEmail(transformedCalendars[foundCalendarTemplateIndex]?.organizer?.email || "")
            setOrganizerID(transformedCalendars[foundCalendarTemplateIndex]?.organizer?.id || "")
        }
    }, [hostViewState.experiences])

    const CALENDAR_TEMPLATES_ORDER = ["HOST", "GUEST", "ADMIN"]

    const classes = useStyles();
    async function getExperiences(){
        try {
            let experiences = await API.graphql(graphqlOperation(listExperiences));
            experiences = experiences.data.listExperiences.items;
    
            hostViewState.dispatch({type: 'experiences', experiences: 
            experiences});

        } catch (e) {
            console.log("Error getting experiences: ", e)
        }
    }

    function handleCloseCalendarEditor(){
        hostViewState.dispatch({ type: "showCalendarEditor", showCalendarEditor: false})
    }

    function handleExperienceChange(event){
        const foundIndex = hostViewState.experiences.findIndex(experience => event.target.value.id === experience.id) || 0;
        const experience = hostViewState.experiences[foundIndex];

        const templates = returnTransformedCalendarList(experience.calendarTemplates.items);
        hostViewState.dispatch({
            type: "calendarState",
            calendarState: {
                calendarTemplate: templates[0],
                calendarTemplates: templates,
                experience: experience,
            }
        })
        setSummary(templates[0].summary  || "");
        setLocation(templates[0]?.location || "")
        setStaticAttendees(templates[0]?.attendees || [])
        
        setCreatorDisplayName(templates[0]?.creator?.displayName || "")
        setCreatorEmail(templates[0]?.creator?.email || "")
        setCreatorID(templates[0]?.creator?.id || "")

        setOrganizerDisplayName(templates[0]?.organizer?.displayName || "")
        setOrganizerEmail(templates[0]?.organizer?.email || "")
        setOrganizerID(templates[0]?.organizer?.id || "")
    }

    function handleSummaryChange(event){
        
        setSummary(event.target.value);

    }

    function handleLocationChange(event){
        
        setLocation(event.target.value);

    }

    function handleAddStaticAttendee() {
        // Don't do anything if there is no input
        if (currentStaticAttendee === "") return;
        // Don't do anything if the email doesn't pass the normal
        if (!(/.+@.+\..+/).test(currentStaticAttendee.toLowerCase())) return;
        // Clear it out if they try to put their own email in there
        // Make sure we only get unique emails
        setStaticAttendees(Array.from(new Set([...staticAttendees, currentStaticAttendee.toLowerCase()])))

        setCurrentStaticAttendee('');
    }

    function removeStaticAttendee(index) {
        let newStaticAttendees = [...staticAttendees.slice(0, index), ...staticAttendees.slice(index + 1)];
        setStaticAttendees(newStaticAttendees);
    }

    function handleCalendarChange(event){
        const foundIndex = hostViewState.calendarState.calendarTemplates.findIndex(calendars => event.target.value.id === calendars.id) || 0;
        const calendarTemplate = hostViewState.calendarState.calendarTemplates[foundIndex];

        hostViewState.dispatch({
            type: "calendarState",
            calendarState: {
                calendarTemplate: calendarTemplate,
            }
        })
        setSummary(calendarTemplate.summary  || "");
        setLocation(calendarTemplate?.location || "")
        setStaticAttendees(calendarTemplate?.attendees || [])
        
        setCreatorDisplayName(calendarTemplate?.creator?.displayName || "")
        setCreatorEmail(calendarTemplate?.creator?.email || "")
        setCreatorID(calendarTemplate?.creator?.id || "")

        setOrganizerDisplayName(calendarTemplate?.organizer?.displayName || "")
        setOrganizerEmail(calendarTemplate?.organizer?.email || "")
        setOrganizerID(calendarTemplate?.organizer?.id || "")

    }

    function returnTransformedCalendarList(calendarTemplates){
        if (!calendarTemplates) {
            return [];
        }

        return CALENDAR_TEMPLATES_ORDER.map(calendarType => {
            return calendarTemplates.find(calendar => calendar.type === calendarType)
        })
    }

    async function handleSaveCalendarTemplate(){
        const input = {
            id: hostViewState.calendarState.calendarTemplate.id,
            rawData: JSON.stringify(editorRawData),
            html: draftToHtml(editorRawData),
            experienceID: hostViewState.calendarState.experience.id,
            summary,
            location,
            attendees: staticAttendees,
            creator: {
                displayName: creatorDisplayName,
                email: creatorEmail,
                id: creatorID
            },
            organizer: {
                displayName: creatorDisplayName,
                email: creatorEmail,
                id: creatorID
            }
        }
        try {
            const handleUpdateCalendarTemplate = await API.graphql(graphqlOperation(updateCalendarTemplates, {input}));
            await getExperiences();
        } catch (e) {
            console.log("error updating experience; ", e);
        }

    }

    return (
        <Box className={classes.calendarEditorContainer} >
            {hostViewState.calendarState.experience && 
                <> 
                    <Typography variant="h4" align="center">
                        Calendar Editor
                    </Typography>

                    <Grid container>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1em 0 1em 0'}}>
                            <FormControl className={classes.experienceSelect}>
                                <InputLabel id="demo-simple-select-label">Experiences</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={hostViewState.calendarState.experience}
                                    onChange={handleExperienceChange}
                                >
                                    {
                                        hostViewState.experiences.map(experience => (
                                            <MenuItem key={experience.shortName || experience.createNew} value={experience}>{experience.shortName || 'Create New'}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        { hostViewState.calendarState.calendarTemplates && 
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', margin: '1em 0 1em 0'}}>
                                <FormControl className={classes.calendarSelect}>
                                    <InputLabel id="demo-simple-select-label">Calendars</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={hostViewState.calendarState.calendarTemplate}
                                        onChange={handleCalendarChange}
                                    >
                                        {
                                            hostViewState.calendarState.calendarTemplates.map(calendar => {
                                                return <MenuItem key={calendar.type} value={calendar}>{calendar.type}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FormControl style={{width: '50vw', margin: '1rem 1rem 1rem 1rem'}}>
                                    <InputLabel htmlFor="my-input">Summary</InputLabel>
                                    <Input id="my-input" aria-describedby="my-helper-text" value={summary} onChange={handleSummaryChange}/>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FormControl style={{width: '50vw', margin: '1rem 1rem 1rem 1rem'}}>
                                    <InputLabel htmlFor="my-input">Location</InputLabel>
                                    <Input id="my-input" aria-describedby="my-helper-text" value={location} onChange={handleLocationChange}/>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
                                {/* TODO: Revisit this.  The length of this line is messed up */}
                                <TextField
                                    style={{width: '48vw', margin: '1rem 1rem 1rem 1rem'}}
                                    type="email"
                                    label="Static Attendees Emails"
                                    onChange={(e) => setCurrentStaticAttendee(e.target.value) }
                                    value={currentStaticAttendee}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Button color="primary" onClick={handleAddStaticAttendee} className={classes.handleAddStaticAttendeeButton}>
                                                <Add /> Add Email
                                            </Button>
                                        </InputAdornment>,
                                        }}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                        // Do code here
                                        ev.preventDefault();
                                        handleAddStaticAttendee();
                                        }
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <div className={classes.chips}>
                                    {staticAttendees.map((email, index) => (
                                        <Chip
                                            key={email}
                                            label={email}
                                            onDelete={() => removeStaticAttendee(index)}
                                            className={classes.chip}
                                            color="secondary"
                                            size="small"
                                        />
                                    ))}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
                                <Typography variant="h5" style={{width: '48vw', margin: '0 1rem 0 1rem'}}>
                                    Creator Info
                                </Typography>
                                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
                                    <TextField
                                        style={{width: '48vw', margin: '1rem 1rem 1rem 1rem'}}
                                        label="Creator display name"
                                        onChange={(e) => setCreatorDisplayName(e.target.value) }
                                        value={creatorDisplayName}
                                    />
                                    <TextField
                                        style={{width: '48vw', margin: '1rem 1rem 1rem 1rem'}}
                                        type="email"
                                        label="Creator email"
                                        onChange={(e) => setCreatorEmail(e.target.value) }
                                        value={creatorEmail}
                                    />
                                    <TextField
                                        style={{width: '48vw', margin: '1rem 1rem 1rem 1rem'}}
                                        label="Creator id"
                                        onChange={(e) => setCreatorID(e.target.value) }
                                        value={creatorID}
                                    />
                                </div>

                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
                                <Typography variant="h5" style={{width: '48vw', margin: '0 1rem 0 1rem'}}>
                                    Organizer Info
                                </Typography>
                                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
                                    <TextField
                                        style={{width: '48vw', margin: '1rem 1rem 1rem 1rem'}}
                                        label="Organizer display name"
                                        onChange={(e) => setOrganizerDisplayName(e.target.value) }
                                        value={organizerDisplayName}
                                    />
                                    <TextField
                                        style={{width: '48vw', margin: '1rem 1rem 1rem 1rem'}}
                                        type="email"
                                        label="Organizer email"
                                        onChange={(e) => setOrganizerEmail(e.target.value) }
                                        value={organizerEmail}
                                    />
                                    <TextField
                                        style={{width: '48vw', margin: '1rem 1rem 1rem 1rem'}}
                                        label="Organizer id"
                                        onChange={(e) => setOrganizerID(e.target.value) }
                                        value={organizerID}
                                    />
                                </div>

                            </div>
                        </Grid>
                        <Grid item xs={12}>
                        <div
                                style={{ 
                                    border: '1px solid black', 
                                    cursor: 'text',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '96vw',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                }} 
                            >
                                <Editor
                                    ref={editor}
                                    editorState={editorState}
                                    wrapperStyle={{
                                    }}
                                    editorStyle={{
                                        height: '35vh'
                                    }}
                                    onEditorStateChange={onEditorStateChange}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="center">
                        <Button color="primary" onClick={() => handleSaveCalendarTemplate()}>
                            <Save /> Save
                        </Button>
                        <Button color="primary" onClick={handleCloseCalendarEditor}>
                            <Cancel /> Cancel
                        </Button>
                    </Box>
                    
                </>
            }
        </Box>
    )
}

export default CalendarEditor;
