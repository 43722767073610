import React, {useEffect, useState, useContext} from 'react';
import './Notifications.css'
import Notification from './Notification.js';
import {API, graphqlOperation} from 'aws-amplify'

import UserContext from '../../../Context/UserContext';
import NotificationsContext from '../../../Context/NotificationsContext';
import { notificationsTo } from '../../../graphql/queries';
import { onCreateNotification, onDeleteNotification } from '../../../graphql/subscriptions';
import { Paper, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vh',
        height: '100%',
        width: '20vw',
        overflowY: 'auto'
    },
    showsTable: {
        width: '100%'
    },
    header: {
        height: '10%',
    },
    content: {
        height: '90%',
    }

}))

function Notifications( ){
    const user = useContext(UserContext)
    const notificationsData = useContext(NotificationsContext);
    const classes = useStyles();

    async function subscribeToCreateNotification(){
        const subscription = API.graphql(graphqlOperation(onCreateNotification))
            .subscribe({
                next: (data) => {
                    console.log("data: ", data)
                    if (data.value.data.onCreateNotification) {
                        notificationsData.dispatch({type: 'newNotificationCreated', newNotificationCreated: data.value.data.onCreateNotification})
                    }
                },
                error: (error) => {
                    console.warn(error);
                }
            })
    }

    async function subscribeToDeleteNotification(){
        const subscription = API.graphql(graphqlOperation(onDeleteNotification))
            .subscribe({
                next: (data) => {
                    notificationsData.dispatch({type: 'notificationDeleted', notificationDeleted: data.value.data.onDeleteNotification})
                },
                error: (error) => console.warn(error)
            })
    }
    

    useEffect( () => {
        subscribeToCreateNotification();
        subscribeToDeleteNotification();
    }, [])

    return (
        <Paper className={classes.main}>
            <div className={classes.header}>
                <Typography variant="h5">
                    Notifications
                </Typography>
            </div>
            <div className={classes.content}>
            {
            notificationsData.notifications.map((notification,i) => {
                return <Notification
                    key={i}
                    message={notification.message}
                    id={notification.id}
                />
            })
        }
            </div>
        </Paper>
    )
}

export default Notifications;