const guestViewReducer = (state, action) => {
    switch(action.type) {
        case 'shows':
            return {
                ...state,
                ...{
                    shows: action.shows
                }
            }
        case 'closeShowPickModal':
            return {
                ...state,
                ...{
                    showShowPickModal: false,
                    showClickedOn: null
                }
            }
        case 'showClicked':
            return {
                ...state,
                ...{
                    showShowPickModal: true,
                    showClickedOn: action.showClickedOn
                }
            }
        case 'buyShowClicked':
            return {
                ...state,
                ...{
                    showOrder: true,
                    showToOrder: action.showToOrder
                }
            }
        case 'closeOrder':
            return {
                ...state,
                ...{
                    showOrder: false,
                    showToBuy: null
                }
            }
        case 'showPayForm':
            return {
                ...state,
                ...{
                    showPayForm: action.showPayForm,
                }
            }
        case 'showGeneralInfo':
            return {
                ...state,
                ...{
                    showGeneralInfo: action.showGeneralInfo,
                }
            }
        case 'showGeneralTicketInfo':
            return {
                ...state,
                ...{
                    showGeneralTicketInfo: action.showGeneralTicketInfo,
                }
            }
        case 'resetForms':
            return {
                ...state,
                ...{
                    showGeneralInfo: true,
                    showPayForm: false
                }
            }
        case 'newShowCreated':
            return {
                ...state,
                ...{
                    shows: [action.newShowCreated, ...state.shows].sort((a, b) => {
                        if (a.date > b.date) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
            }
        case 'timeZoneValue':
            return {
                ...state,
                ...{
                    timeZoneValue: action.timeZoneValue
                }
            }
        case 'showCalendar':
            return {
                ...state,
                ...{
                    showCalendar: action.showCalendar
                }
            }
        case 'calendarValue':
            return {
                ...state,
                ...{
                    calendarValue: action.calendarValue,
                    formatDate: action.formatDate
                }
            }
        case 'searchTerms':
            return {
                ...state,
                ...{
                    searchTerms: action.searchTerms
                }
            }
        case 'updateHostImages':
            return {
                ...state,
                ...{
                    hostImages: action.hostImages
                }
            }
        case 'updateExperienceImages':
            return {
                ...state,
                ...{
                    experienceImages: action.experienceImages
                }
            }
        default:
            console.log("bad customer view reducer: ", action);
            return state
    }
}

export default guestViewReducer;