import React from 'react';
import './Notification.css'
import moment from 'moment';
import {deleteNotification} from '../../../graphql/mutations';
import {API, graphqlOperation} from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
import { Button, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Delete } from '@mui/icons-material';

const useStyles = makeStyles((theme) => {
    return ({
    root: {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary,
        textDecoration: 'none'
    //   display: 'flex',
    //   '& > *': {
    //     margin: theme.spacing(1),
    //   },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    media: {
        // height: 0,
        paddingTop: 56.25 / 4 + '%', // 16:9
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textTitle: {
        color: 'white',
        padding: '3%',
        fontWeight: 'bold',
        textDecoration: 'none'
    },
    link: {
        textDecoration: 'none'
    }
  })}
);


function Notification( {message, id} ){
    const classes = useStyles();

    const messageSplit = message.split("\n");
    const messageMap = {};
    messageSplit.forEach(item => {
        const splitItem = item.split(": ");
        messageMap[splitItem[0]] = splitItem[1].trim();
    })
    async function handleDeleteNotification(){
        const input = { id };
        try {
            const returnFromDelete = await API.graphql(graphqlOperation(deleteNotification, { input }))
        } catch(e) {
            console.error("error deleteing notification", e)
        }
    }

    return (
        <Card className={classes.root}>
        <CardHeader
            action={
                <Button color="primary" onClick={handleDeleteNotification}>
                    <Delete /> Delete
                </Button>
            }
            title={messageMap.subject}
            subheader={`Host: ${messageMap.host}`}
        >
        </CardHeader>
        <CardContent >
            <Typography >
                Experience: {messageMap.experience}
            </Typography>
            <Typography >
                ShowTime: {moment(messageMap.showtime).format("YYYY-MM-DD hh:mmA")}
            </Typography>
            <Typography >
                Additional Note: {messageMap.additionalNote}
            </Typography>
        </CardContent>

    </Card>
    )
}

export default Notification;