import React, {useEffect, useState} from 'react';

import {
    Button,
    AppBar,
    Toolbar,
    useMediaQuery,
    InputBase,
    alpha,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Avatar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AL_LOGO_IMAGE from '../../images/adventurelab-blacklogo-ontransparent.png';
import AL_LOGO_IMAGE_MOBILE from '../../images/adventurelab-blacklogo-ontransparent_mobile.png';

import TimeZonePicker from './TimeZonePicker.js';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import Storage from '@aws-amplify/storage';
import crumb_icon from '../../images/crumb_icon.png'

function Header({isHostHeader, signOut, timeZoneValue, onTimeZoneChange, searchTermValue, onSearchTermChange, onProfileClick, profileKey}) {
    const greaterThanSm = useMediaQuery(theme => theme.breakpoints.up("sm"), {noSsr: true});
    const greaterThanMd = useMediaQuery(theme => theme.breakpoints.up("md"), {noSsr: true});

    const [showMobileTZ, setShowMobileTZ] = useState(false);
    const [avatarImage, setAvatarImage] = useState(null);

    const useStyles = makeStyles(theme => ({
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            height: '10%',
            padding: '0.5% 0px',
            [theme.breakpoints.down('md')]: {
                padding: '2.5% 0px',
            },
            position: 'sticky',
            backgroundColor: 'white',
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '10%',
            padding: '0% 0.5%',
            width: '100%',
            flexDirection: 'column'
        },
        icon: {
            height: '100%',
            maxHeight: '3.5em',
            [theme.breakpoints.down('md')]: {
                maxHeight: '3em',
            },
            width: '100%',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            outline: '1px solid black',
            marginRight: theme.spacing(0.5),
            marginLeft: theme.spacing(0.5),
            width: '90%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(3),
                width: '90%',
            },
          },
        searchIcon: {
            padding: theme.spacing(0, 2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 0),
            },
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        inputRoot: {
            color: 'inherit',
            width: '100%'
        },
          inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1, 0, 1, 3),
                fontSize: '16px'
            },
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
            },
          },
          mobileTimeZone: {
              width: '100%'
          },
          closeIcon: {
            position: 'absolute',
            top: '0.5%',
            right: '0.5%'
        },
        timezoneButton: {
            height: '2em',
            width: '1em',
            padding: '0'
        },
        signOutButton: {
            height: '3em',
        },
        avatar: {
            marginRight: theme.spacing(1),
            cursor: 'pointer',
            '&:hover': {
                filter: `drop-shadow(0px 0px 10px ${theme.palette.secondary.main})`
            }
        },
        links: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        },
        linkLabel: {
            fontSize: '3rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '0.6rem',
            }
        },
        linkButtons: {
            fontSize: '1.5rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
            },
            marginRight: '4%',
            padding: '0% 0%',
            whiteSpace: 'nowrap'
        },
        link: {
            marginLeft: theme.spacing(2),
        },
        toolbarIconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            [theme.breakpoints.down('md')]: {
                flex: 1.75
            },
            width: '100%',
            height: '5.5rem',
            [theme.breakpoints.down('md')]: {
                height: '100%'
            }
        },
        toolbarActionsContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            flex: 2,
            [theme.breakpoints.down('md')]: {
                flex: 2
            },
            width: '100%',
            height: '5.5rem',
            [theme.breakpoints.down('md')]: {
                height: '100%'
            }
        },
        toolbarTimezoneContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1.5,
            width: '100%',
            height: '5.5rem',
            [theme.breakpoints.down('md')]: {
                height: '100%'
            },
            marginRight: '4%',
        },
        menuContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            width: '100%',
        },
        searchContainer: {
            marginBottom: '1%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            margin: '0 1%',
        },
        signOutContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }))

    const classes = useStyles();

    useEffect(() => {
        (async () => {
            if(isHostHeader && profileKey) {
                const url = await Storage.get(profileKey);
                setAvatarImage(url);
                
            } else {
                setAvatarImage(crumb_icon);
            }
        

        })();
    }, [profileKey, isHostHeader])

    useEffect(() => {

    }, [])
    return (
        <AppBar className={classes.root} position="sticky">
            <Toolbar className={classes.toolbar} disableGutters={true}>
                <div className={classes.menuContainer}>
                    <div className={classes.toolbarIconContainer}>
                        <Button href="https://www.adventurelab.fun/">
                            <img src={ greaterThanSm ? AL_LOGO_IMAGE : AL_LOGO_IMAGE_MOBILE} className={classes.icon}/>
                        </Button>
                    </div>    
                    <div className={classes.toolbarActionsContainer}>
                        { !isHostHeader &&
                            <div className={classes.links}>
                                <Button className={classes.linkButtons} href="https://www.adventurelab.fun/faq"  classes={{label: classes.linkLabel}} variant="text">
                                    FAQ
                                </Button>
                                <Button className={classes.linkButtons} href="https://www.adventurelab.fun/tech-support"  classes={{label: classes.linkLabel}} variant="text">
                                    Mission setup
                                </Button>
                            </div>
                        }
                    </div>
                    <div className={classes.toolbarTimezoneContainer}>
                        {greaterThanMd
                            ? 
                                <>
                                    <TimeZonePicker timeZoneValue={timeZoneValue} onTimeZoneChange={onTimeZoneChange}/>
                                </>
                            :
                                <>
                                    <Button className={classes.linkButtons} classes={{label: classes.linkLabel}}  onClick={() =>setShowMobileTZ(true)} variant="text">
                                        Timezone
                                    </Button>
                                    <Dialog fullWidth={true} open={showMobileTZ} onClose={() => setShowMobileTZ(false)} >
                                        <DialogTitle>
                                            <Typography>
                                                Please choose your timezone
                                            </Typography>
                                            <IconButton className={classes.closeIcon} onClick={() => setShowMobileTZ(false)} size="small">
                                                <CancelIcon /> 
                                            </IconButton>
                                        </DialogTitle>
                                        <DialogContent>
                                            <div className={classes.mobileTimeZone}>
                                                <Typography variant="h5" gutterBottom>
                                                    Current Timezone: <br /> {timeZoneValue.label}
                                                </Typography>
                                                <TimeZonePicker timeZoneValue={timeZoneValue} onTimeZoneChange={onTimeZoneChange}/>
 
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                </>
                        }
                    </div>
                    {
                            isHostHeader &&
                            <>
                                <div className={classes.signOutContainer}>
                                    <Avatar className={classes.avatar} alt="Remy Sharp" src={avatarImage} onClick={onProfileClick}/>
                                    <Button 
                                        className={classes.signOutButton} 
                                        variant="contained" 
                                        size={'small'} 
                                        color="secondary" 
                                        onClick={signOut}
                                    >
                                        Sign Out
                                    </Button>
                                </div>
                            </>
                   
                    }
                        {

                        }
                </div>
                <div className={classes.searchContainer}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Try typing: Ari or Sept or Fri or 3pm"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={onSearchTermChange}
                            value={searchTermValue}
                        />
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
    
}

export default Header;
