/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createShow = /* GraphQL */ `
  mutation CreateShow(
    $input: CreateShowInput!
    $condition: ModelShowConditionInput
  ) {
    createShow(input: $input, condition: $condition) {
      id
      showtime
      hostID
      experienceID
      status
      price
      createdByUserId
      roomCode
      guestCalendarEventId
      hostCalendarEventId
      instanceId
      onePlayerWarningSent
      preshowSent
      highlightsLink
      type
      duration
      recurringShowID
      privateShowID
      showType
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          showID
          hostID
          guestID
          experienceID
          status
          tipAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          commentForHost
          commentForPublic
          commentForPrivate
          rating
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateShow = /* GraphQL */ `
  mutation UpdateShow(
    $input: UpdateShowInput!
    $condition: ModelShowConditionInput
  ) {
    updateShow(input: $input, condition: $condition) {
      id
      showtime
      hostID
      experienceID
      status
      price
      createdByUserId
      roomCode
      guestCalendarEventId
      hostCalendarEventId
      instanceId
      onePlayerWarningSent
      preshowSent
      highlightsLink
      type
      duration
      recurringShowID
      privateShowID
      showType
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          showID
          hostID
          guestID
          experienceID
          status
          tipAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          commentForHost
          commentForPublic
          commentForPrivate
          rating
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteShow = /* GraphQL */ `
  mutation DeleteShow(
    $input: DeleteShowInput!
    $condition: ModelShowConditionInput
  ) {
    deleteShow(input: $input, condition: $condition) {
      id
      showtime
      hostID
      experienceID
      status
      price
      createdByUserId
      roomCode
      guestCalendarEventId
      hostCalendarEventId
      instanceId
      onePlayerWarningSent
      preshowSent
      highlightsLink
      type
      duration
      recurringShowID
      privateShowID
      showType
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          showID
          hostID
          guestID
          experienceID
          status
          tipAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          commentForHost
          commentForPublic
          commentForPrivate
          rating
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      guestID
      showID
      orderID
      clientTimezone
      additionalNote
      platform
      howDidYouHearAboutAL
      howMuchVRExperience
      status
      playerName
      ticketCode
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      order {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      guestID
      showID
      orderID
      clientTimezone
      additionalNote
      platform
      howDidYouHearAboutAL
      howMuchVRExperience
      status
      playerName
      ticketCode
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      order {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      guestID
      showID
      orderID
      clientTimezone
      additionalNote
      platform
      howDidYouHearAboutAL
      howMuchVRExperience
      status
      playerName
      ticketCode
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      order {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      showID
      totalTicketsPurchased
      guestID
      totalAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      status
      clientTimezone
      coupon
      teamName
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      showID
      totalTicketsPurchased
      guestID
      totalAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      status
      clientTimezone
      coupon
      teamName
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      showID
      totalTicketsPurchased
      guestID
      totalAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      status
      clientTimezone
      coupon
      teamName
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      showID
      hostID
      guestID
      experienceID
      status
      tipAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      commentForHost
      commentForPublic
      commentForPrivate
      rating
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      showID
      hostID
      guestID
      experienceID
      status
      tipAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      commentForHost
      commentForPublic
      commentForPrivate
      rating
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      showID
      hostID
      guestID
      experienceID
      status
      tipAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      commentForHost
      commentForPublic
      commentForPrivate
      rating
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      isAdmin
      hostID
      profilePicture {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      notifications {
        items {
          id
          date
          fromID
          toID
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      isAdmin
      hostID
      profilePicture {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      notifications {
        items {
          id
          date
          fromID
          toID
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      isAdmin
      hostID
      profilePicture {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      notifications {
        items {
          id
          date
          fromID
          toID
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      date
      fromID
      toID
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      date
      fromID
      toID
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      date
      fromID
      toID
      message
      createdAt
      updatedAt
    }
  }
`;
export const createExperience = /* GraphQL */ `
  mutation CreateExperience(
    $input: CreateExperienceInput!
    $condition: ModelExperienceConditionInput
  ) {
    createExperience(input: $input, condition: $condition) {
      id
      longName
      shortName
      idName
      coverImage {
        bucket
        region
        key
      }
      defaultPrice
      singleTicketPrice
      featuredTicketPrice
      privateShowBookingPrice
      longDescription
      shortDescription
      maxPlayers
      minPlayers
      minInitialTicketPurchase
      platforms
      youTubeLink
      createdAt
      updatedAt
      emailTemplates {
        items {
          id
          type
          html
          rawData
          subjectLine
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
      calendarTemplates {
        items {
          id
          type
          html
          rawData
          summary
          location
          attendees
          creator {
            displayName
            email
            id
          }
          organizer {
            displayName
            email
            id
          }
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateExperience = /* GraphQL */ `
  mutation UpdateExperience(
    $input: UpdateExperienceInput!
    $condition: ModelExperienceConditionInput
  ) {
    updateExperience(input: $input, condition: $condition) {
      id
      longName
      shortName
      idName
      coverImage {
        bucket
        region
        key
      }
      defaultPrice
      singleTicketPrice
      featuredTicketPrice
      privateShowBookingPrice
      longDescription
      shortDescription
      maxPlayers
      minPlayers
      minInitialTicketPurchase
      platforms
      youTubeLink
      createdAt
      updatedAt
      emailTemplates {
        items {
          id
          type
          html
          rawData
          subjectLine
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
      calendarTemplates {
        items {
          id
          type
          html
          rawData
          summary
          location
          attendees
          creator {
            displayName
            email
            id
          }
          organizer {
            displayName
            email
            id
          }
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteExperience = /* GraphQL */ `
  mutation DeleteExperience(
    $input: DeleteExperienceInput!
    $condition: ModelExperienceConditionInput
  ) {
    deleteExperience(input: $input, condition: $condition) {
      id
      longName
      shortName
      idName
      coverImage {
        bucket
        region
        key
      }
      defaultPrice
      singleTicketPrice
      featuredTicketPrice
      privateShowBookingPrice
      longDescription
      shortDescription
      maxPlayers
      minPlayers
      minInitialTicketPurchase
      platforms
      youTubeLink
      createdAt
      updatedAt
      emailTemplates {
        items {
          id
          type
          html
          rawData
          subjectLine
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
      calendarTemplates {
        items {
          id
          type
          html
          rawData
          summary
          location
          attendees
          creator {
            displayName
            email
            id
          }
          organizer {
            displayName
            email
            id
          }
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createGuest = /* GraphQL */ `
  mutation CreateGuest(
    $input: CreateGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    createGuest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateGuest = /* GraphQL */ `
  mutation UpdateGuest(
    $input: UpdateGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    updateGuest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteGuest = /* GraphQL */ `
  mutation DeleteGuest(
    $input: DeleteGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    deleteGuest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createHost = /* GraphQL */ `
  mutation CreateHost(
    $input: CreateHostInput!
    $condition: ModelHostConditionInput
  ) {
    createHost(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      hostName
      slackName
      bio
      profilePicture {
        bucket
        region
        key
      }
      youTubeLink
      status
      rate
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateHost = /* GraphQL */ `
  mutation UpdateHost(
    $input: UpdateHostInput!
    $condition: ModelHostConditionInput
  ) {
    updateHost(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      hostName
      slackName
      bio
      profilePicture {
        bucket
        region
        key
      }
      youTubeLink
      status
      rate
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteHost = /* GraphQL */ `
  mutation DeleteHost(
    $input: DeleteHostInput!
    $condition: ModelHostConditionInput
  ) {
    deleteHost(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      hostName
      slackName
      bio
      profilePicture {
        bucket
        region
        key
      }
      youTubeLink
      status
      rate
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      couponCode
      discount
      totalTimesCanBeUsed
      totalTimesUsed
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      couponCode
      discount
      totalTimesCanBeUsed
      totalTimesUsed
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      couponCode
      discount
      totalTimesCanBeUsed
      totalTimesUsed
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const createPrivateShow = /* GraphQL */ `
  mutation CreatePrivateShow(
    $input: CreatePrivateShowInput!
    $condition: ModelPrivateShowConditionInput
  ) {
    createPrivateShow(input: $input, condition: $condition) {
      id
      description
      name
      type
      createdByUserID
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const updatePrivateShow = /* GraphQL */ `
  mutation UpdatePrivateShow(
    $input: UpdatePrivateShowInput!
    $condition: ModelPrivateShowConditionInput
  ) {
    updatePrivateShow(input: $input, condition: $condition) {
      id
      description
      name
      type
      createdByUserID
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const deletePrivateShow = /* GraphQL */ `
  mutation DeletePrivateShow(
    $input: DeletePrivateShowInput!
    $condition: ModelPrivateShowConditionInput
  ) {
    deletePrivateShow(input: $input, condition: $condition) {
      id
      description
      name
      type
      createdByUserID
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createRecurringShow = /* GraphQL */ `
  mutation CreateRecurringShow(
    $input: CreateRecurringShowInput!
    $condition: ModelRecurringShowConditionInput
  ) {
    createRecurringShow(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateRecurringShow = /* GraphQL */ `
  mutation UpdateRecurringShow(
    $input: UpdateRecurringShowInput!
    $condition: ModelRecurringShowConditionInput
  ) {
    updateRecurringShow(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteRecurringShow = /* GraphQL */ `
  mutation DeleteRecurringShow(
    $input: DeleteRecurringShowInput!
    $condition: ModelRecurringShowConditionInput
  ) {
    deleteRecurringShow(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createEmailTemplates = /* GraphQL */ `
  mutation CreateEmailTemplates(
    $input: CreateEmailTemplatesInput!
    $condition: ModelEmailTemplatesConditionInput
  ) {
    createEmailTemplates(input: $input, condition: $condition) {
      id
      type
      html
      rawData
      subjectLine
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateEmailTemplates = /* GraphQL */ `
  mutation UpdateEmailTemplates(
    $input: UpdateEmailTemplatesInput!
    $condition: ModelEmailTemplatesConditionInput
  ) {
    updateEmailTemplates(input: $input, condition: $condition) {
      id
      type
      html
      rawData
      subjectLine
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteEmailTemplates = /* GraphQL */ `
  mutation DeleteEmailTemplates(
    $input: DeleteEmailTemplatesInput!
    $condition: ModelEmailTemplatesConditionInput
  ) {
    deleteEmailTemplates(input: $input, condition: $condition) {
      id
      type
      html
      rawData
      subjectLine
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createCalendarTemplates = /* GraphQL */ `
  mutation CreateCalendarTemplates(
    $input: CreateCalendarTemplatesInput!
    $condition: ModelCalendarTemplatesConditionInput
  ) {
    createCalendarTemplates(input: $input, condition: $condition) {
      id
      type
      html
      rawData
      summary
      location
      attendees
      creator {
        displayName
        email
        id
      }
      organizer {
        displayName
        email
        id
      }
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCalendarTemplates = /* GraphQL */ `
  mutation UpdateCalendarTemplates(
    $input: UpdateCalendarTemplatesInput!
    $condition: ModelCalendarTemplatesConditionInput
  ) {
    updateCalendarTemplates(input: $input, condition: $condition) {
      id
      type
      html
      rawData
      summary
      location
      attendees
      creator {
        displayName
        email
        id
      }
      organizer {
        displayName
        email
        id
      }
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCalendarTemplates = /* GraphQL */ `
  mutation DeleteCalendarTemplates(
    $input: DeleteCalendarTemplatesInput!
    $condition: ModelCalendarTemplatesConditionInput
  ) {
    deleteCalendarTemplates(input: $input, condition: $condition) {
      id
      type
      html
      rawData
      summary
      location
      attendees
      creator {
        displayName
        email
        id
      }
      organizer {
        displayName
        email
        id
      }
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
