/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getShow = /* GraphQL */ `
  query GetShow($id: ID!) {
    getShow(id: $id) {
      id
      showtime
      hostID
      experienceID
      status
      price
      createdByUserId
      roomCode
      guestCalendarEventId
      hostCalendarEventId
      instanceId
      onePlayerWarningSent
      preshowSent
      highlightsLink
      type
      duration
      recurringShowID
      privateShowID
      showType
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          showID
          hostID
          guestID
          experienceID
          status
          tipAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          commentForHost
          commentForPublic
          commentForPrivate
          rating
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listShows = /* GraphQL */ `
  query ListShows(
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const byDate = /* GraphQL */ `
  query ByDate(
    $type: String
    $showtime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byDate(
      type: $type
      showtime: $showtime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const showsByHost = /* GraphQL */ `
  query ShowsByHost(
    $hostID: ID
    $showtime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsByHost(
      hostID: $hostID
      showtime: $showtime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const showsByStatus = /* GraphQL */ `
  query ShowsByStatus(
    $status: ShowStatus
    $showtime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsByStatus(
      status: $status
      showtime: $showtime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const showsByRoomCode = /* GraphQL */ `
  query ShowsByRoomCode(
    $roomCode: Int
    $showtime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsByRoomCode(
      roomCode: $roomCode
      showtime: $showtime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const showsByInstance = /* GraphQL */ `
  query ShowsByInstance(
    $instanceId: String
    $showtime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsByInstance(
      instanceId: $instanceId
      showtime: $showtime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const showsByExperience = /* GraphQL */ `
  query ShowsByExperience(
    $experienceID: ID
    $showtime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsByExperience(
      experienceID: $experienceID
      showtime: $showtime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      guestID
      showID
      orderID
      clientTimezone
      additionalNote
      platform
      howDidYouHearAboutAL
      howMuchVRExperience
      status
      playerName
      ticketCode
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      order {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        guestID
        showID
        orderID
        clientTimezone
        additionalNote
        platform
        howDidYouHearAboutAL
        howMuchVRExperience
        status
        playerName
        ticketCode
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        order {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const ticketsByShow = /* GraphQL */ `
  query TicketsByShow(
    $showID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByShow(
      showID: $showID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        guestID
        showID
        orderID
        clientTimezone
        additionalNote
        platform
        howDidYouHearAboutAL
        howMuchVRExperience
        status
        playerName
        ticketCode
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        order {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const ticketsByOrder = /* GraphQL */ `
  query TicketsByOrder(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByOrder(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        guestID
        showID
        orderID
        clientTimezone
        additionalNote
        platform
        howDidYouHearAboutAL
        howMuchVRExperience
        status
        playerName
        ticketCode
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        order {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const ticketsByGuest = /* GraphQL */ `
  query TicketsByGuest(
    $guestID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByGuest(
      guestID: $guestID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        guestID
        showID
        orderID
        clientTimezone
        additionalNote
        platform
        howDidYouHearAboutAL
        howMuchVRExperience
        status
        playerName
        ticketCode
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        order {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      showID
      totalTicketsPurchased
      guestID
      totalAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      status
      clientTimezone
      coupon
      teamName
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      guest {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const ordersByShow = /* GraphQL */ `
  query OrdersByShow(
    $showID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByShow(
      showID: $showID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const ordersByGuest = /* GraphQL */ `
  query OrdersByGuest(
    $guestID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByGuest(
      guestID: $guestID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showID
        totalTicketsPurchased
        guestID
        totalAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        status
        clientTimezone
        coupon
        teamName
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        guest {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      showID
      hostID
      guestID
      experienceID
      status
      tipAmount
      squareOrderID
      squarePaymentID
      squareRefundID
      receiptUrl
      commentForHost
      commentForPublic
      commentForPrivate
      rating
      createdAt
      updatedAt
      show {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        duration
        recurringShowID
        privateShowID
        showType
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            status
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showID
        hostID
        guestID
        experienceID
        status
        tipAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        commentForHost
        commentForPublic
        commentForPrivate
        rating
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const reviewsByShow = /* GraphQL */ `
  query ReviewsByShow(
    $showID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByShow(
      showID: $showID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showID
        hostID
        guestID
        experienceID
        status
        tipAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        commentForHost
        commentForPublic
        commentForPrivate
        rating
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const reviewsByHost = /* GraphQL */ `
  query ReviewsByHost(
    $hostID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByHost(
      hostID: $hostID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showID
        hostID
        guestID
        experienceID
        status
        tipAmount
        squareOrderID
        squarePaymentID
        squareRefundID
        receiptUrl
        commentForHost
        commentForPublic
        commentForPrivate
        rating
        createdAt
        updatedAt
        show {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      isAdmin
      hostID
      profilePicture {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      notifications {
        items {
          id
          date
          fromID
          toID
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      host {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const byUserEmail = /* GraphQL */ `
  query ByUserEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      date
      fromID
      toID
      message
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        fromID
        toID
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsTo = /* GraphQL */ `
  query NotificationsTo(
    $toID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsTo(
      toID: $toID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        fromID
        toID
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExperience = /* GraphQL */ `
  query GetExperience($id: ID!) {
    getExperience(id: $id) {
      id
      longName
      shortName
      idName
      coverImage {
        bucket
        region
        key
      }
      defaultPrice
      singleTicketPrice
      featuredTicketPrice
      privateShowBookingPrice
      longDescription
      shortDescription
      maxPlayers
      minPlayers
      minInitialTicketPurchase
      platforms
      youTubeLink
      createdAt
      updatedAt
      emailTemplates {
        items {
          id
          type
          html
          rawData
          subjectLine
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
      calendarTemplates {
        items {
          id
          type
          html
          rawData
          summary
          location
          attendees
          creator {
            displayName
            email
            id
          }
          organizer {
            displayName
            email
            id
          }
          experienceID
          createdAt
          updatedAt
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listExperiences = /* GraphQL */ `
  query ListExperiences(
    $filter: ModelExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getGuest = /* GraphQL */ `
  query GetGuest($id: ID!) {
    getGuest(id: $id) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      tickets {
        items {
          id
          guestID
          showID
          orderID
          clientTimezone
          additionalNote
          platform
          howDidYouHearAboutAL
          howMuchVRExperience
          status
          playerName
          ticketCode
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          order {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      orders {
        items {
          id
          showID
          totalTicketsPurchased
          guestID
          totalAmount
          squareOrderID
          squarePaymentID
          squareRefundID
          receiptUrl
          status
          clientTimezone
          coupon
          teamName
          createdAt
          updatedAt
          show {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          guest {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listGuests = /* GraphQL */ `
  query ListGuests(
    $filter: ModelGuestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const byGuestEmail = /* GraphQL */ `
  query ByGuestEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelGuestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGuestEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            playerName
            ticketCode
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getHost = /* GraphQL */ `
  query GetHost($id: ID!) {
    getHost(id: $id) {
      id
      firstName
      lastName
      email
      hostName
      slackName
      bio
      profilePicture {
        bucket
        region
        key
      }
      youTubeLink
      status
      rate
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listHosts = /* GraphQL */ `
  query ListHosts(
    $filter: ModelHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const byHostEmail = /* GraphQL */ `
  query ByHostEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byHostEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        hostName
        slackName
        bio
        profilePicture {
          bucket
          region
          key
        }
        youTubeLink
        status
        rate
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      couponCode
      discount
      totalTimesCanBeUsed
      totalTimesUsed
      expiration
      createdAt
      updatedAt
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        couponCode
        discount
        totalTimesCanBeUsed
        totalTimesUsed
        expiration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrivateShow = /* GraphQL */ `
  query GetPrivateShow($id: ID!) {
    getPrivateShow(id: $id) {
      id
      description
      name
      type
      createdByUserID
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
      createdByUser {
        id
        email
        isAdmin
        hostID
        profilePicture {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        notifications {
          items {
            id
            date
            fromID
            toID
            message
            createdAt
            updatedAt
          }
          nextToken
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          rate
          createdAt
          updatedAt
          shows {
            items {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              showType
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const listPrivateShows = /* GraphQL */ `
  query ListPrivateShows(
    $filter: ModelPrivateShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateShows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        name
        type
        createdByUserID
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const privateShowsByUserId = /* GraphQL */ `
  query PrivateShowsByUserId(
    $createdByUserID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPrivateShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    privateShowsByUserID(
      createdByUserID: $createdByUserID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        name
        type
        createdByUserID
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const getRecurringShow = /* GraphQL */ `
  query GetRecurringShow($id: ID!) {
    getRecurringShow(id: $id) {
      id
      type
      createdAt
      updatedAt
      shows {
        items {
          id
          showtime
          hostID
          experienceID
          status
          price
          createdByUserId
          roomCode
          guestCalendarEventId
          hostCalendarEventId
          instanceId
          onePlayerWarningSent
          preshowSent
          highlightsLink
          type
          duration
          recurringShowID
          privateShowID
          showType
          createdAt
          updatedAt
          tickets {
            items {
              id
              guestID
              showID
              orderID
              clientTimezone
              additionalNote
              platform
              howDidYouHearAboutAL
              howMuchVRExperience
              status
              playerName
              ticketCode
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            items {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              showID
              hostID
              guestID
              experienceID
              status
              tipAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              commentForHost
              commentForPublic
              commentForPrivate
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdByUser {
            id
            email
            isAdmin
            hostID
            profilePicture {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            notifications {
              nextToken
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          experience {
            id
            longName
            shortName
            idName
            coverImage {
              bucket
              region
              key
            }
            defaultPrice
            singleTicketPrice
            featuredTicketPrice
            privateShowBookingPrice
            longDescription
            shortDescription
            maxPlayers
            minPlayers
            minInitialTicketPurchase
            platforms
            youTubeLink
            createdAt
            updatedAt
            emailTemplates {
              nextToken
            }
            calendarTemplates {
              nextToken
            }
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            rate
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listRecurringShows = /* GraphQL */ `
  query ListRecurringShows(
    $filter: ModelRecurringShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurringShows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
        shows {
          items {
            id
            showtime
            hostID
            experienceID
            status
            price
            createdByUserId
            roomCode
            guestCalendarEventId
            hostCalendarEventId
            instanceId
            onePlayerWarningSent
            preshowSent
            highlightsLink
            type
            duration
            recurringShowID
            privateShowID
            showType
            createdAt
            updatedAt
            tickets {
              nextToken
            }
            orders {
              nextToken
            }
            reviews {
              nextToken
            }
            createdByUser {
              id
              email
              isAdmin
              hostID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              rate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEmailTemplates = /* GraphQL */ `
  query GetEmailTemplates($id: ID!) {
    getEmailTemplates(id: $id) {
      id
      type
      html
      rawData
      subjectLine
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listEmailTemplatess = /* GraphQL */ `
  query ListEmailTemplatess(
    $filter: ModelEmailTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailTemplatess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        html
        rawData
        subjectLine
        experienceID
        createdAt
        updatedAt
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const emailTemplatesByExperience = /* GraphQL */ `
  query EmailTemplatesByExperience(
    $experienceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEmailTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailTemplatesByExperience(
      experienceID: $experienceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        html
        rawData
        subjectLine
        experienceID
        createdAt
        updatedAt
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCalendarTemplates = /* GraphQL */ `
  query GetCalendarTemplates($id: ID!) {
    getCalendarTemplates(id: $id) {
      id
      type
      html
      rawData
      summary
      location
      attendees
      creator {
        displayName
        email
        id
      }
      organizer {
        displayName
        email
        id
      }
      experienceID
      createdAt
      updatedAt
      experience {
        id
        longName
        shortName
        idName
        coverImage {
          bucket
          region
          key
        }
        defaultPrice
        singleTicketPrice
        featuredTicketPrice
        privateShowBookingPrice
        longDescription
        shortDescription
        maxPlayers
        minPlayers
        minInitialTicketPurchase
        platforms
        youTubeLink
        createdAt
        updatedAt
        emailTemplates {
          items {
            id
            type
            html
            rawData
            subjectLine
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarTemplates {
          items {
            id
            type
            html
            rawData
            summary
            location
            attendees
            creator {
              displayName
              email
              id
            }
            organizer {
              displayName
              email
              id
            }
            experienceID
            createdAt
            updatedAt
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listCalendarTemplatess = /* GraphQL */ `
  query ListCalendarTemplatess(
    $filter: ModelCalendarTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarTemplatess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        html
        rawData
        summary
        location
        attendees
        creator {
          displayName
          email
          id
        }
        organizer {
          displayName
          email
          id
        }
        experienceID
        createdAt
        updatedAt
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const calendarTemplatesByExperience = /* GraphQL */ `
  query CalendarTemplatesByExperience(
    $experienceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarTemplatesByExperience(
      experienceID: $experienceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        html
        rawData
        summary
        location
        attendees
        creator {
          displayName
          email
          id
        }
        organizer {
          displayName
          email
          id
        }
        experienceID
        createdAt
        updatedAt
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
