import moment from "moment";

const dates = {mon: 'monday', tue: 'tuesday', wed: 'wednesday', thu: 'thursday', fri: 'friday', sat: 'saturday', sun: 'sunday'};
const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', ,'october', 'november', 'december']



export function serializeShow(show, date, time, isHostOrAdmin, tzValue){
    const todayDate = moment.utc(Date.now());
    const formatDate = todayDate.tz(tzValue).format("ddd MM-DD");
    const tomorrowDate = todayDate.add(1, 'days');
    const formatTomorrowDate = tomorrowDate.tz(tzValue).format("ddd MM-DD");

    const fullDay = dates[date.split(" ")[0].toLowerCase()];
    const month = months[Number(date.replace("-", " ").split(" ")[1]) - 1];

    let text = `
        ${date} 
        ${month} 
        ${fullDay} 
        ${time} 
        ${time.replace(":00", "")} 
        ${show.status} 
        ${show.host.hostName} 
        ${show.host.lastName} 
        ${show.experience.longName} 
        ${show.experience.price}
        ${formatDate === date ? "today" : ""} 
        ${formatTomorrowDate === date ? "tomorrow" : ""}
    `.toLowerCase();

    if (isHostOrAdmin) {
        text += `
            ${show.roomCode} 
            ${show.id} 
            ${show.orders?.items.map(order => order.id).join(" ")}
            ${show.orders?.items.map(order => order.guest.firstName + " " + order.guest.lastName + " " + order.guest.email).join(" ")}
            ${show.tickets?.items.map(ticket => ticket?.guest?.email).join(" ")}
            ${show.tickets?.items.map(ticket => ticket?.guest?.firstname + " " + ticket.guest.lastname).join(" ")}
            ${show.status !== "OPEN" ? "booked" : ""}
            ${show.recurringShowID}
            ${show.recurringShowID ? "recurring" : ""}
            ${show.privateShowID ? "private" : ""}
            ${show.privateShowID}
            ${show.orders?.items.map(order => order.coupon).join(" ")}
            ${show.reviews?.items.length ? "reviews" : ""}
            ${show.tickets?.items.length ? "tickets" : ""}
        `.toLowerCase().replace(/\n|\t/g, "");
    }
    return text;
}

export function checkMatch(terms, entry){
    terms = terms.split(" ");
    let found = false;
    for (let i = 0; i < terms.length; i++) {
        const term = terms[i];
        if (entry.includes(term.toLowerCase())) {
            found = true;
        } else {
            return false;
        }
    }
    return found;
}