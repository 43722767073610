import React, {useContext} from 'react';

import EventBoardShow from './EventBoardShow';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GuestViewContext from '../../../Context/GuestViewContext';
import moment from 'moment';
import 'moment-timezone';

const useStyles = makeStyles(theme => ({
    main: {
        position: 'relative',
        display: "flex",
        flexDirection: "column",
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: props => {
            if (props.soldout) return '#d0d0d0';
            // return props.formatDate === props.day ? theme.palette.primary.main : theme.palette.background.paper
        },
        width: 'calc(100%)'
    },
    title: {
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: 'black'
    },
    soldoutText: {
        position: 'absolute',
        width: '100%',
        margin: '0 auto',
        textAlign: 'left',
        marginBottom: '5%'

    }
}))

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function getMonth(date){
    let regexMatch = date.match(/(...) (\d\d)-(\d\d)/);
    let dayName = regexMatch[1];
    let month = months[parseInt(regexMatch[2]) - 1];
    let day = regexMatch[3];
    return `${dayName} ${month} ${day}`
}

function EventBoardDay(props){
    const guestViewState = useContext(GuestViewContext);
    const {day, shows=[], width, shouldHighlight, soldout} = props;
    const classes = useStyles({calendarValue: guestViewState.calendarValue, day: day, formatDate: guestViewState.formatDate, shouldHighlight, soldout});
    
    function handleDayClick(){
        if (!shouldHighlight) return;
        let momentDate = moment.utc(shows[0].showtime);
        let tzValue = guestViewState.timeZoneValue?.value || "America/Los_Angeles";
        let formatDate = momentDate.tz(tzValue).format("ddd MM-DD");
        guestViewState.dispatch({type: 'calendarValue', calendarValue: momentDate.toDate(), formatDate: formatDate});
    }


    return (
        <Card className={`${classes.main} style-4`} onClick={handleDayClick}>
            <CardHeader title={getMonth(day)} className={classes.title} >
            </CardHeader>
            <CardContent>
                {shows.map(show => <EventBoardShow key={show.id} show={show} day={day}/> )}
            </CardContent>
        </Card>
    )
}

export default EventBoardDay;