export const deleteShow = /* GraphQL */ `
  mutation DeleteShow(
    $input: DeleteShowInput!
    $condition: ModelShowConditionInput
  ) {
    deleteShow(input: $input, condition: $condition) {
      id
    }
  }
`;

export const onDeleteShow = /* GraphQL */ `
  subscription OnDeleteShow {
    onDeleteShow {
      id
    }
  }
`;

export const listShows = /* GraphQL */ `
  query ListShows(
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showtime
        hostID
        experienceID
        status
        price
        createdByUserId
        roomCode
        guestCalendarEventId
        hostCalendarEventId
        instanceId
        onePlayerWarningSent
        preshowSent
        highlightsLink
        type
        showType
        duration
        recurringShowID
        privateShowID
        createdAt
        updatedAt
        tickets {
          items {
            id
            guestID
            showID
            orderID
            clientTimezone
            additionalNote
            platform
            playerName
            howDidYouHearAboutAL
            howMuchVRExperience
            status
            createdAt
            updatedAt
            ticketCode
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              createdAt
              updatedAt
            }
            order {
              id
              showID
              totalTicketsPurchased
              guestID
              totalAmount
              squareOrderID
              squarePaymentID
              squareRefundID
              receiptUrl
              status
              clientTimezone
              coupon
              teamName
              createdAt
              updatedAt
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        orders {
          items {
            id
            showID
            totalTicketsPurchased
            guestID
            totalAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            status
            clientTimezone
            coupon
            teamName
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              createdAt
              updatedAt
            }
            tickets {
              nextToken
            }
            guest {
              id
              firstName
              lastName
              email
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdByUser {
          id
          email
          isAdmin
          hostID
          profilePicture {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          notifications {
            items {
              id
              date
              fromID
              toID
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          host {
            id
            firstName
            lastName
            email
            hostName
            slackName
            bio
            profilePicture {
              bucket
              region
              key
            }
            youTubeLink
            status
            createdAt
            updatedAt
            shows {
              nextToken
            }
          }
        }
        experience {
          id
          longName
          shortName
          idName
          coverImage {
            bucket
            region
            key
          }
          defaultPrice
          singleTicketPrice
          featuredTicketPrice
          privateShowBookingPrice
          longDescription
          shortDescription
          maxPlayers
          minPlayers
          minInitialTicketPurchase
          platforms
          youTubeLink
          createdAt
          updatedAt
          emailTemplates {
            items {
              id
              type
              html
              rawData
              subjectLine
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
          calendarTemplates {
            items {
              id
              type
              html
              rawData
              summary
              location
              attendees
              experienceID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        host {
          id
          firstName
          lastName
          email
          hostName
          slackName
          bio
          profilePicture {
            bucket
            region
            key
          }
          youTubeLink
          status
          createdAt
          updatedAt
        }
        reviews {
          items {
            id
            showID
            hostID
            guestID
            experienceID
            tipAmount
            squareOrderID
            squarePaymentID
            squareRefundID
            receiptUrl
            commentForHost
            commentForPublic
            commentForPrivate
            rating
            createdAt
            updatedAt
            show {
              id
              showtime
              hostID
              experienceID
              status
              price
              createdByUserId
              roomCode
              guestCalendarEventId
              hostCalendarEventId
              instanceId
              onePlayerWarningSent
              preshowSent
              highlightsLink
              type
              duration
              recurringShowID
              privateShowID
              createdAt
              updatedAt
            }
            experience {
              id
              longName
              shortName
              idName
              defaultPrice
              singleTicketPrice
              featuredTicketPrice
              privateShowBookingPrice
              longDescription
              shortDescription
              maxPlayers
              minPlayers
              minInitialTicketPurchase
              platforms
              youTubeLink
              createdAt
              updatedAt
            }
            host {
              id
              firstName
              lastName
              email
              hostName
              slackName
              bio
              youTubeLink
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;