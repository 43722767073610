import { Button, TextField } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import React, {useContext} from 'react';
import HostViewContext from '../../../Context/HostViewContext';

export default function PrivateShowAutoComplete({shouldCreateNewPrivateShow, onChange, form, onPrivateShowChange, privateShowInfo, setShouldCreateNewPrivateShow, isEdit}){
    const hostViewState = useContext(HostViewContext);

    function makeOptions(){
        return [{name: "Create New Private Show"}, ...hostViewState.privateShows]
    }

    const options = makeOptions();
    function getPrivateShowValue(){
        if (privateShowInfo) {
            let findShowIndex = options.findIndex((option) => option.id === privateShowInfo.id);
            return options[findShowIndex];
        }
    }
    
    const privateShowValue = getPrivateShowValue();

    return <>
    { shouldCreateNewPrivateShow
        ?
            <div>
                <div>
                    <TextField
                        label="private show name"
                        helperText="Enter the private show name that guests will see"
                        required
                        value={form.privateShowName}
                        onChange={(e) => onChange('privateShowName', e)}
                    />
                    <TextField
                        label="private show description"
                        helperText="Enter a description of the show for admin purposes. Guests won't see this."
                        value={form.privateShowDescription}
                        onChange={(e) => onChange('privateShowDescription', e)}
                    />
                </div>
                    <Button startIcon={<Cancel />}onClick={() => setShouldCreateNewPrivateShow(false)}>
                        Cancel New Private Show
                    </Button>
            </div>
        :
            <Autocomplete
                id="private-shows"
                size={"small"} 
                autoHighlight
                options={options}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                style={{ width: '100%', marginRight: '1em' }}
                onChange={onPrivateShowChange}
                value={privateShowValue}
                renderInput={(params) => <TextField {...params} size='medium' label="Choose or create a private show" variant="standard" helperText="Type to filter private shows"/>}
            />
    }
</>;
}