const notificationsReducer = (state, action) => {
    switch(action.type){
        case 'receivedNotificationsList':
            return {
                ...state,
                ...{
                    notifications: action.notifications
                }
            }
        case 'newNotificationCreated':
                return {
                    ...state,
                    ...{
                        notifications: [action.newNotificationCreated, ...state.notifications]
                    }
                }
        case 'notificationDeleted':
                return {
                    ...state,
                    ...{
                        notifications: state.notifications.filter(notification => notification.id !== action.notificationDeleted.id)
                    }
                }
        case 'isNotificationsOpen':
            return {
                ...state,
                ...{
                    isNotificationsOpen: action.isNotificationsOpen
                }
            }
        default:
            console.log("Bad Log in notifications reducer: " + action);
            return state;
    }
}

export default notificationsReducer;