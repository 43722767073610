import React, { useContext, useState, useRef, useEffect } from 'react';
import GuestViewContext from '../../../Context/GuestViewContext';

import { API } from 'aws-amplify';

import moment from 'moment';
import 'moment-timezone';

import { useParams, Link } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';

import { Link as MuiLink, Button, Accordion, AccordionSummary , AccordionDetails, Fab, Paper, Typography, TextField, Divider, FormControlLabel, Checkbox, MenuItem, Container, CircularProgress, Card, CardContent } from '@mui/material';

import { NavigateNext} from '@mui/icons-material';
import ShowInfoDialog from '../Dialog/ShowInfoDialog';
import HostInfoDialog from '../Dialog/HostInfoDialog';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        marginTop: '1%',
        padding: '2%',
        // height: '80vh',
        overflowY: 'auto'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        padding: "2%"
    },
    contactForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1) / 2,
            width: 'calc(100%)',
        }
    },
    divider: {
        margin: "1% 0"
    },
    tos: {
        display: "flex",
        flexDirection: 'column'
    },
    paymentInfo: {
        display: "flex",
        flexDirection: 'column'
    },
    confirmation: {
        display: "flex",
        flexDirection: 'column'
    },
    descriptionCard: {
        marginBottom: '1%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary[50]
    },
    title: {
    },
    description: {
        whiteSpace: 'pre-wrap'
    },
    error: {
        color: theme.palette.error.main
    },
    linkButtons: {
        marginRight: '1%'
    },
    links: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
        margin: '1% 0'
    },
    linkLabel: {
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.6rem',
        }
    },
}))


function Ticket() {
    const guestViewState = useContext(GuestViewContext);

    const classes = useStyles();

    const { orderID } = useParams();

    const [order, setOrder] = useState(null)
    const [day, setDay] = useState(null)
    const [displayTime, setDisplayTime] = useState(null)
    const [showAlreadySignedUpError, setShowAlreadySignedUpError] = useState(null);
    const [timeLoaded, setTimeLoaded] = useState(Date.now());
    const [showDescription, setShowDescription] = useState(false);
    const [showHostInfo, setShowHostInfo] = useState(false);
    const formRef = useRef();


    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        additionalNote: '',
        readTOS: false,
        okToUseForPromo: false,
        platform: '',
        playerName: '',
        howDidYouHearAboutAL: '',
        howMuchVRExperience: '',
    })

    const [formErrors, setFormErrors] = useState({});

    const [orderLoaded, setOrderLoaded] = useState(false)

    const [validSubmit, setValidSubmit] = useState(null);
    const [validTicket, setValidTicket] = useState(false);
    const [noTicketsAvailable, setNoTicketsAvailable] = useState(null);
    const [isProcessing, setIsProcessing] = useState(null);
    const [errorLoadingTicketContactAL, setErrorLoadingTicketContactAL] = useState(null);
    const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);

    const [currentShowTime, setCurrentShowTime] = useState(null);
    const SANDBOX_LOCATION_ID = "LVFN05FEXDTYT";
    const SANDBOX_APPLICATION_ID = "sandbox-sq0idb-FeLsfXIRZpFxTTO553bXew";

    function onCloseDialog(setCloseFunction){
        setCloseFunction(false);
        clearAllBodyScrollLocks();
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setFormHasBeenSubmitted(true);
        if (validate()) {
            try {
                const postData = {
                    body: {
                        clientTimezone: guestViewState.timeZoneValue.value,
                        order,
                        form,
                    }
                };
                setIsProcessing(true);
                const response = await API.post('alplatformapi', '/tickets', postData);
                setIsProcessing(false);

                if (response.success){
                    setValidTicket(true);
                    guestViewState.dispatch({type: 'showGeneralTicketInfo', showGeneralTicketInfo: false })
                }
            } catch (e) {
                // TODO: UI FOR ERROR
                setIsProcessing(false);
                if(e.response.data.error === 'already signed up') {
                    setShowAlreadySignedUpError(true)
                }
                console.log("error claiming ticket", e.response);
            }
        }
    }

    function validate(){
        let temp = {};
        temp.firstName = form.firstName ? "" : "This field is required";
        temp.lastName = form.lastName ? "" : "This field is required";
        temp.email = (/.+@.+\..+/).test(form.email) ? "" : "This email is not valid";
        temp.readTOS = form.readTOS ? "" : "This field is required";
        temp.howMuchVRExperience = form.howMuchVRExperience ? "" : "This field is required";
        // temp.okToUseForPromo = form.okToUseForPromo ? "" : "This field is required";
        temp.platform = form.platform ? "" : "This field is required";

        setFormErrors(temp);
        if (Object.values(temp).every(x => x === "")) {
            setValidSubmit(true);
            return true;
        } else {
            setValidSubmit(false);
            return false;
        }
    }

    function handleReset(e) {
        e.preventDefault();

        formRef.current.resetFields();
    }

    function onChange(key, value) {
        setForm(Object.assign({}, form, { [key]: value}))
    }

    function adjustTime(time){
        let momentDate = moment.utc(time);
        let tzValue = guestViewState.timeZoneValue?.value || moment.tz.guess();
        let momentDay = momentDate.tz(tzValue).format("ddd MM-DD");
        setDay(momentDay);
        let momentDisplayTime = momentDate.tz(tzValue).format("h:mmA z");
        setDisplayTime(momentDisplayTime);
    }

    async function getOrder(){
        try {
            let startDate = Date.now();
            const returnedOrder = await API.get('alplatformapi', '/orders/' + orderID);

            if (!returnedOrder.success) {
                setNoTicketsAvailable(true)
            } else {
                let endDate = Date.now();
                setCurrentShowTime(returnedOrder.data.show.showtime)
    
                adjustTime(returnedOrder.data.show.showtime);
                setOrder(returnedOrder.data);
                setOrderLoaded(true);

                const totalTicketsPurchased = returnedOrder.data.totalTicketsPurchased;
                const currentNumberOfTickets = returnedOrder.data.tickets;
                if (totalTicketsPurchased === currentNumberOfTickets) {
                    setNoTicketsAvailable(true)
                }
            }

        } catch (e) {
            console.log("e: ", e.response)
            console.error("Error getting order: ", e);
        }
    }
    
    function resetForms(){
        guestViewState.dispatch({type: 'resetForms' })
        
    }

    useEffect( () => {
        if (currentShowTime) {
            adjustTime(currentShowTime);
        }
    }, [guestViewState.timeZoneValue])
    
    useEffect( ()=> {
        getOrder();
        resetForms();
    }, [])



    return (
            <Paper className={`${classes.root} style-4`}>
                {(orderLoaded && order) 
                    ?
                        noTicketsAvailable 
                            ?
                                <Typography variant="h3" align='left'>
                                    No tickets are available for this order.  <br />
                                    Please contact chief@adventurelab.fun for support.
                                </Typography>                   
                            :
                                <div>
                                    <div className={classes.title}>
                                        <Typography variant="h4" align='center'>
                                            {order.show.experience.longName}
                                        </Typography> 
                                        <Typography variant="h5" align='center'>
                                            w/ {order.show.host.hostName} @ {displayTime} on {day}
                                        </Typography>
                                    </div>
                                    <div className={classes.links}>
                                            <Button
                                                onClick={() => setShowHostInfo(true)}
                                                className={classes.linkButtons} 
                                                classes={{label: classes.linkLabel}} 
                                                variant="outlined"
                                            >
                                                About your host
                                                
                                            </Button>
                                            <Button 
                                                onClick={() => setShowDescription(true)}
                                                className={classes.linkButtons}  
                                                classes={{label: classes.linkLabel}} 
                                                variant="outlined"
                                            >
                                                About your show
                                            </Button>

                                        <ShowInfoDialog showDescription={showDescription} onClose={() => onCloseDialog(setShowDescription)} experience={order.show.experience} show={order.show}/>

                                        <HostInfoDialog showHostInfo={showHostInfo} onClose={() => onCloseDialog(setShowHostInfo)} show={order.show}/>
                                    </div>

                                    <Card variant="outlined" className={classes.descriptionCard}>
                                        {/* <CardHeader subheader="Show Details" /> */}
                                        <CardContent>
                                            <Typography variant="h6" component="p" className={classes.description}>
                                                {order.show.experience.longDescription}
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Typography variant="h6" align='left'>
                                        Please fill out this form to register for your show
                                    </Typography>

                                    <Accordion expanded={guestViewState.showGeneralTicketInfo}>

                                        <AccordionSummary >
                                            <Typography variant="h6" align='left'>
                                                1. General
                                            </Typography>
                                        </AccordionSummary >

                                        <AccordionDetails>
                                            <form className={classes.contactForm} ref={formRef}>
                                                <div>
                                                <TextField 
                                                        required 
                                                        error={!!formErrors.firstName}
                                                        helperText={formErrors.firstName}
                                                        label="First Name" 
                                                        value={form.firstName} 
                                                        onChange={(e) => onChange('firstName', e.target.value)}
                                                        onBlur={() => formHasBeenSubmitted && validate()}
                                                    />
                                                    <TextField 
                                                        required 
                                                        error={!!formErrors.lastName}
                                                        helperText={formErrors.lastName}
                                                        label="Last Name" 
                                                        value={form.lastName} 
                                                        onChange={(e) => onChange('lastName', e.target.value)}
                                                        onBlur={() => formHasBeenSubmitted && validate()}
                                                    />
                                                    <TextField 
                                                        required
                                                        error={!!formErrors.email}
                                                        helperText={formErrors.email}
                                                        label="Email" 
                                                        type="email" 
                                                        value={form.email} 
                                                        onChange={(e) => onChange('email', e.target.value)}
                                                        onBlur={() => formHasBeenSubmitted && validate()}
                                                    />
                                                </div>

                                                <Divider className={classes.divider}/>

                                                <div className={classes.tos}>
                                                    <Typography variant="h6" align='left'>
                                                        Terms of Service
                                                    </Typography>
                                                    <MuiLink target="_blank" href="https://www.adventurelab.fun/tos" component="div">
                                                        https://www.adventurelab.fun/tos
                                                    </MuiLink>
                                                    <FormControlLabel
                                                        required
                                                        control={<Checkbox required checked={form.readTOS} onChange={(e) => onChange('readTOS', e.target.checked)} name="readTOS"/>}
                                                        label="I have read and agree to the terms above*"
                                                        classes={{
                                                            label: formErrors.readTOS ? classes.error: ''
                                                        }}
                                                        onBlur={() => formHasBeenSubmitted && validate()}
                                                    />
                                                    {/* commenting this out for now */}
                                                    {/* <FormControlLabel
                                                        required
                                                        control={
                                                            <Checkbox required checked={form.okToUseForPromo} 
                                                                onChange={(e) => onChange('okToUseForPromo', e.target.checked)} 
                                                                name="okToUseForPromo"
                                                            />}
                                                        label="It's okay if Adventure Lab uses gameplay footage of my session, including my voice and virtual likeness, in any of its promotional materials!*"
                                                        classes={{
                                                            label: formErrors.okToUseForPromo ? classes.error: ''
                                                        }}
                                                        onBlur={() => formHasBeenSubmitted && validate()}
                                                    /> */}
                                                </div>

                                                <Divider className={classes.divider}/>

                                                <div>
                                                    <Typography variant="h6" align='left'>
                                                        Experience Related
                                                    </Typography>

                                                    <TextField 
                                                            helperText="Birthday party? Language barrier? Age specific?..." 
                                                            label="Additional Info for your host" 
                                                            multiline 
                                                            rows={1} 
                                                            type="textarea" 
                                                            value={form.additionalNote} 
                                                            onChange={(e) => onChange('additionalNote', e.target.value)}
                                                        />

                                                    <TextField
                                                        required 
                                                        helperText={"Tip: include your first name if you want people to know who you are - ex Agent Fluffy (Mike)"}
                                                        label="What would you like your secret agent name to be?" 
                                                        value={form.playerName} 
                                                        onChange={(e) => onChange('playerName', e.target.value)}
                                                        onBlur={() => formHasBeenSubmitted && validate()}
                                                        InputLabelProps={{
                                                            style: { color: '#aaa' },
                                                        }}
                                                    />
                                                    
                                                    <TextField
                                                        select
                                                        label="What VR device do you use?"
                                                        helperText={"The Valve Index is supported, but not recommended. Please use a Quest, Rift, or Vive if possible. " + (formErrors.platform ? formErrors.platform : "")}
                                                        required
                                                        value={form.platform}
                                                        error={!!formErrors.platform}
                                                        onChange={(e) => onChange('platform', e.target.value)}
                                                        onBlur={() => formHasBeenSubmitted && validate()}
                                                    >
                                                        {["Oculus Quest", "Oculus Rift/Rift S", "HTC Vive", "Valve Index"].map(option => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>

                                                    <TextField
                                                            select
                                                            label="How did you hear about us?"
                                                            value={form.howDidYouHearAboutAL}
                                                            onChange={(e) => onChange('howDidYouHearAboutAL', e.target.value)}
                                                        >
                                                            {["Facebook", "Instagram", "Twitter", "Oculus", "News Article", "Family or Friend", "Google Search", "Other"].map(option => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>

                                                    <TextField
                                                            select
                                                            error={!!formErrors.howMuchVRExperience}
                                                            helperText={formErrors.howMuchVRExperience}
                                                            label="VR experience level?"
                                                            required
                                                            value={form.howMuchVRExperience}
                                                            onChange={(e) => onChange('howMuchVRExperience', e.target.value)}
                                                            onBlur={() => formHasBeenSubmitted && validate()}
                                                        >
                                                            {["None", "Just got a headset", "I know my virtual way around", "God Mode"].map(option => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                </div>

                                                <Button 
                                                    variant="contained" 
                                                    // disabled={validSubmit} 
                                                    color="primary" 
                                                    onClick={handleSubmit}
                                                    endIcon={<NavigateNext />}
                                                >
                                                    Register for Show
                                                </Button>
                                                {isProcessing && <CircularProgress />}
                                                {showAlreadySignedUpError && <>
                                                    <Typography variant="h6" align='left'>
                                                        This email has already been used to sign up for the show
                                                    </Typography>
                                                </>}
                                            </form>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion expanded={validTicket}>
                                        <Typography variant="h6" align='left'>
                                            2. Confirmation
                                        </Typography>
                                        <AccordionDetails className={classes.confirmation}>
                                            <Typography variant="h4" align='left'>
                                                THANK YOU {form.firstName}!
                                            </Typography>
                                            <Typography variant="h5" align='left'>
                                                Please check your email for further information on your show. 
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                    :
                    noTicketsAvailable 
                        ?
                            <Typography variant="h3" align='left'>
                                No tickets are available for this order.  <br />
                                Please contact chief@adventurelab.fun for support.
                            </Typography>                   
                        :
                            <CircularProgress />
                }
            </Paper>
    )
}

export default Ticket;