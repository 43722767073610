import { Drawer, Grid, IconButton, Button, Badge } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useContext, useEffect} from 'react';
import NotificationsContext from '../../Context/NotificationsContext';
import UserContext from '../../Context/UserContext';
import Header from '../Common/Header';
import HostAuth from './HostAuth/HostAuth';
import Notifications from './Notifications/Notifications';
import Shows from './Shows/Shows';
import {Notifications as NotificationIcon} from '@mui/icons-material';
import HostViewContext from '../../Context/HostViewContext';
import {Auth} from 'aws-amplify';
import HostProfileEditor from './HostProfileEditor/HostProfileEditor';
import ExperienceEditor from './ExperienceEditor/ExperienceEditor';
import CalendarEditor from './CalendarEditor/CalendarEditor';
import EmailEditor from './EmailEditor/EmailEditor';
import Administration from './Administration/Administration';

const useStyles = makeStyles(theme => ({
    showsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    notificationsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hostProfileEditorContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    notificationIcon: {
        position: 'fixed',
        bottom: 0,
        right: 0
    }
}))

function HostViewOrAuth(){
    const userState = useContext(UserContext);
    const hostViewState = useContext(HostViewContext);
    const notificationsState = useContext(NotificationsContext);
    const classes = useStyles();

    function handleNotificationsClosed(){
        notificationsState.dispatch({type: 'isNotificationsOpen', isNotificationsOpen: false});
    }

    function handleProfileEditorClosed(){
        hostViewState.dispatch({type: 'showProfileEditor', showProfileEditor: false});
    }

    function handleNotificationsOpen(){
        notificationsState.dispatch({type: 'isNotificationsOpen', isNotificationsOpen: true});
    }

    function handleExperienceEditorClosed(){
        hostViewState.dispatch({type: 'showExperienceEditor', showExperienceEditor: false});
    }

    function handleEmailEditorClosed(){
        hostViewState.dispatch({type: 'showEmailEditor', showEmailEditor: false});
    }

    function handleCalendarEditorClosed(){
        hostViewState.dispatch({type: 'showCalendarEditor', showCalendarEditor: false});
    }
    
    function handleAdministrationClosed(){
        hostViewState.dispatch({type: 'showAdministration', showAdministration: false});
    }

    function onSearchTermChange(event, value){
        hostViewState.dispatch({type: "searchTerms", searchTerms: event.target.value});
    }

    function onTimeZoneChange(event, value){
        hostViewState.dispatch({type: "timeZoneValue", timeZoneValue: value});
    }

    function onProfileClick(){
        hostViewState.dispatch({type: "showProfileEditor", showProfileEditor: true});
    }

    async function signOut(){
        await Auth.signOut({global: true});
        userState.dispatch({type: 'signOut'});
    }

    useEffect( () => {
        hostViewState.dispatch({type: "profileKey", profileKey: userState.dbUser?.host?.profilePicture?.key})
    }, [userState.dbUser])
    
    return (
        <div>
            {!userState.awsUser && !userState.dbUser
                ? 
                    <HostAuth />
                : (
                    <>
                        <Header 
                            isHostHeader={true} 
                            signOut={signOut}
                            searchTermValue={hostViewState.searchTermValue}
                            onSearchTermChange={onSearchTermChange}
                            timeZoneValue={hostViewState.timeZoneValue} 
                            onTimeZoneChange={onTimeZoneChange}
                            onProfileClick={onProfileClick}
                            profileKey={hostViewState.profileKey}
                        />

                        <Grid container>
                            <Grid item xs={12} className={classes.showsContainer}>
                                <Button color="primary" onClick={handleNotificationsOpen} className={classes.notificationIcon}>
                                    <Badge badgeContent={notificationsState.notifications.length} color="secondary">
                                        <NotificationIcon />
                                    </Badge>
                                </Button>
                                {userState.awsUser && userState.dbUser && <Shows />}
                            </Grid>
                        </Grid>
                        <Drawer 
                            anchor="left" 
                            open={hostViewState.showProfileEditor} 
                            onClose={handleProfileEditorClosed} 
                            className={classes.hostProfileEditorContainer}>
                                <HostProfileEditor />
                        </Drawer>
                        <Drawer anchor="right" open={notificationsState.isNotificationsOpen} onClose={handleNotificationsClosed} className={classes.notificationsContainer}>
                            <Notifications />
                        </Drawer>
                        <Drawer anchor="top" open={hostViewState.showExperienceEditor} onClose={handleExperienceEditorClosed} className={classes.experienceEditor}>
                            <ExperienceEditor />
                        </Drawer>
                        <Drawer anchor="bottom" open={hostViewState.showEmailEditor} onClose={handleEmailEditorClosed} className={classes.emailEditor}>
                            <EmailEditor />
                        </Drawer>
                        <Drawer anchor="bottom" open={hostViewState.showCalendarEditor} onClose={handleCalendarEditorClosed} className={classes.calendarEditor}>
                            <CalendarEditor />
                        </Drawer>
                        <Drawer anchor="top" open={hostViewState.showAdministration} onClose={handleAdministrationClosed} className={classes.administration}>
                            <Administration />
                        </Drawer>
                    </>
                )
            }
        </div>
    )
}

export default HostViewOrAuth;