import React, {useContext, useEffect} from 'react';
import UserContext from '../../../Context/UserContext';
import { Authenticator, AmplifyTheme } from 'aws-amplify-react';
import { API, Auth, graphqlOperation, Hub } from 'aws-amplify';
import { byHostEmail, listUsers, byUserEmail } from '../../../graphql/queries';
import { createUser } from '../../../graphql/mutations';
import NotificationsContext from '../../../Context/NotificationsContext';

const theme = {
    ...AmplifyTheme,
    button: {
        ...AmplifyTheme.button,
        backgroundColor: "var(--amazonOrange"
    },
    sectionBody: {
        ...AmplifyTheme.sectionBody,
        padding: "10px"
    },
    sectionHeader: {
        ...AmplifyTheme.sectionHeader,
        backgroundColor: "var(--info)",
        color: "black"
    },
    navBar: {
        ...AmplifyTheme.navBar,
        backgroundColor: "var(--red)",
        color: "white"
    },
    sectionFooterSecondaryContent: {
        display: 'none' 
    },
    container: {
        backgroundColor: "white",
        height: "25%",
        display: "inline-block"
    }
};

function HostAuth(){
    const user = useContext(UserContext);
    const notificationsState = useContext(NotificationsContext);

    async function getUserData(){
        // Get the currently authenticated user
        const awsUser = await Auth.currentAuthenticatedUser();
        // If there is an aws user, then lets update the user state to let the auth pass through
        if (user?.dispatch && awsUser) {
            user.dispatch({type: 'awsUser', awsUser: awsUser});
        };

        // Now if there is an aws user, let's check to see which group they are in
        if (awsUser) {
            // Groups are either host or admin
            let userGroup = awsUser.signInUserSession.accessToken.payload["cognito:groups"];
            if (!userGroup) {
                userGroup = null;
                console.error("please add user to a group");
                user.dispatch({type: 'awsUser', awsUser: null});
                return;
            }

            try {
                const getUserResponse = await API.graphql(graphqlOperation(byUserEmail, {email: awsUser.attributes.email}));
                const foundUser = getUserResponse?.data?.byUserEmail?.items[0];

                if (!foundUser) {
                    // Is this an admin user from awsUser?
                    const isAdmin = userGroup.includes("admin");
                    const isHost = userGroup.includes("hosts");
                    const getHostResponse = await API.graphql(graphqlOperation(byHostEmail, {email: awsUser.attributes.email}));
                    const foundHost = getHostResponse?.data?.byHostEmail?.items[0];

                    const input = {
                        email: awsUser.attributes.email,
                        isAdmin: isAdmin,
                    }
                    console.log("input: ", input);
                    // attach the host to this user if there is one
                    // NOTE: Hosts must be created before user Logs in first time!
                    if (foundHost) {
                        input.hostID = foundHost.id;
                    } else if (!foundHost && isHost) {
                        // Case where host is in their aws group, but a host isn't found
                        userGroup = null;
                        console.error("please add user to a group");
                        user.dispatch({type: 'awsUser', awsUser: null});
                        return;
                    } else {
                        // @connections must not be nullable, I believe this is being worked on, but this is the work around in the meantime
                        // This will be applied for admins that aren't hosts
                        input.hostID = "host_not_found";
                    }

                    // Add that new user to the DB and then put them on the state
                    let newUser = await API.graphql(graphqlOperation(createUser, { input }))
                    newUser = newUser.data.createUser;
                    user.dispatch({type: 'dbUser', dbUser: newUser})
                } else {
                    // user exists, no need to add, but let's add them to our state
                    if (user?.dispatch) {
                        user.dispatch({type: 'dbUser', dbUser: foundUser})

                        notificationsState.dispatch({type: 'receivedNotificationsList', notifications: foundUser.notifications.items })
                    }
                }
            } catch (e) {
                console.log("e", e)
            }
        } else {
            console.log("No aws user found");
        }
    }

    useEffect(() => {
        // First check to see if there is a user already signed in
        const awsUser = (async () => await Auth.currentAuthenticatedUser())();
        if (awsUser) {
            // If there is then run the auth init sequence above
            getUserData();
        }
        // If the user does need to go through sign in, this is where we react to the sign in event which will call the auth sequence
        Hub.listen('auth', data => {
            const { payload } = data;
            switch (payload.event) {
                case 'signIn':
                    getUserData();
                    break;
                // Not implemented
                case 'signUp':
                    break;
                // Not implemented
                case 'signOut':
                    break;
                default:
                    break;
            }
        })
    }, [])

    return (
        <div id="Auth-Container">
            <Authenticator theme={theme} />
        </div> 
    )
}

export default HostAuth;