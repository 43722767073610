import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import {orange, amber} from '@mui/material/colors';
// import {blueGrey, lightBlue} from '@mui/material/colors';

let theme = createTheme(adaptV4Theme({
    palette: {
        primary: orange,
        secondary: amber
    }
}))

theme = responsiveFontSizes(theme);

export default theme;