import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';


import theme from './theme';
import { BrowserRouter as Router } from 'react-router-dom';
import 'fontsource-roboto';
import './fonts/LuckiestGuy-Regular.ttf';

ReactDOM.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <App />
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root')
);