export function combineDateAndTime(date, time) {
    const timeString = time.getHours() + ':' + time.getMinutes() + ':00';
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Jan is 0, dec is 11
    const day = date.getDate();
    const dateString = '' + year + '-' + month + '-' + day;
    const combined = new Date(dateString + ' ' + timeString);

    return combined;
};

// TODO: is room code an int or string??  Let's pick a lane! I can't remember why I did it like this.  Will come back to this for v2.  In the meantime, I'll just make the first digit 1 in case it's 0.
function randomDigit(){
    let digit = Math.floor(Math.random() * 9);
    if (digit === 0) digit = 1;
    
    return String(digit);
}

export function createRoomCode(){
    const roomCode = `${randomDigit() || 1}${randomDigit()}${randomDigit()}`;
    return parseInt(roomCode);
}