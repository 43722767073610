import React, { useContext, useState, useRef, useEffect } from 'react';

import HostViewContext from '../../../Context/HostViewContext';

import { API } from 'aws-amplify';

import { useParams } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';

import { Button, Typography, TextField, FormControlLabel, Checkbox, MenuItem, Dialog, DialogTitle, DialogContent, Card, CardContent, CircularProgress, useMediaQuery, Container, IconButton } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';

import { disableBodyScroll } from 'body-scroll-lock';
import Payment from '../Payment/Payment';


const useStyles = makeStyles(theme => {
    return ({
        root: {
            display: "flex",
            flexDirection: "column",
            height: 'calc(100vh - calc(100vh - 100%))',
            width: '100vw',
            overflowY: 'auto'
        },
        contactForm: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1) / 2,
                width: 'calc(100%)',
            }
        },
        descriptionCard: {
            marginBottom: '1%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary[0],
        },
        closeIcon: {
            position: 'absolute',
            top: '0.5%',
            right: '0.5%'
        },
        description: {
            whiteSpace: 'pre-wrap'
        },
        error: {
            color: theme.palette.error.main
        },
        leaveTip: {
            fontSize: '100px'
        },
        controlLabel: {
            fontSize: '1.65em',
            fontWeight: '500'
        },
        flexAlign: {
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "space-around"
        }
    })
})

function Review({ onClose }) {
    const greaterThanXs = useMediaQuery(theme => theme.breakpoints.up("xs"));
    const greaterThanSm = useMediaQuery(theme => theme.breakpoints.up("sm"));
    const greaterThanMd = useMediaQuery(theme => theme.breakpoints.up("md"));
    const greaterThanLg = useMediaQuery(theme => theme.breakpoints.up("lg"));
    const greaterThanXl = useMediaQuery(theme => theme.breakpoints.up("xl"));

    const hostViewState = useContext(HostViewContext);
    const classes = useStyles();

    const { showID } = useParams();
    const formRef = useRef();
    const windowRef = useRef();

    const [show, setShow] = useState()

    const reviewOptions = [
        { v: 1, t: "1: I HATED IT!" },
        { v: 2, t: "2: Meh, I didn't like it." },
        { v: 3, t: "3: It was ok." },
        { v: 4, t: "4: Yeah, it was great!" },
        { v: 5, t: "5: WOW! I CAN'T WAIT TO TIP MY HOST!" }
    ]

    const [form, setForm] = useState({
        amount: '',
        rating: 0,
        leaveTip: false
    })

    const [formErrors, setFormErrors] = useState({});

    const [showLoaded, setshowLoaded] = useState(false);

    const [validSubmit, setValidSubmit] = useState(true);

    const [validPayment, setValidPayment] = useState(false);

    const [errorMessages, setErrorMessages] = useState([]);

    const [isProcessing, setIsProcessing] = useState(null);

    const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);

    function onDialogEntered() {
        if (greaterThanSm) {
            disableBodyScroll(windowRef.current);
        }
    }

    function onChange(key, value) {
        setForm(Object.assign({}, form, { [key]: value }))
    }

    function scrollToBottom() {
        windowRef.current.scrollTop = windowRef.current.scrollHeight
    }

    async function handleSubmitWithNoTip() {
        if (validate()) {
            try {
                const postData = {
                    body: {
                        show: show,
                        form,
                    }
                };
                setIsProcessing(true);
                scrollToBottom();
                const response = await API.post('alplatformapi', '/reviews', postData);

                setFormHasBeenSubmitted(true);
                setIsProcessing(false);

            } catch (e) {
                console.log("e: ", e);
                console.log("error saving show review", e.error);
                console.log("error saving show review", e.response);
            }
        }
    }

    function validate() {
        let temp = {};
        if (form.leaveTip) {
            temp.email = (/.+@.+\..+/).test(form.email) ? "" : "This email is not valid";
            temp.amount = typeof (form.amount) === "number" ? "" : "Please enter a tip amount";
        }

        temp.rating = typeof (form.rating) === "number" ? "" : "Please select a rating";

        setFormErrors(temp);

        if (Object.values(temp).every(x => x === "")) {
            setValidSubmit(true);
            return true;
        } else {
            setValidSubmit(false);
            return false;
        }
    }

    async function getShow() {
        try {
            const returnedShow = await API.get('alplatformapi', '/shows/' + showID);
            setShow(returnedShow.data);
            setshowLoaded(true);
        } catch (e) {
            console.error("Error getting shows: ", e);
        }
    }

    useEffect(() => {
        getShow();
    }, [])

    useEffect(() => {
        if (!formHasBeenSubmitted && !validPayment) return;

        setTimeout(() => {
            window.location = "https://book.adventurelab.net";
        }, 3500);

    }, formHasBeenSubmitted, validPayment)

    async function handlePostData(postData) {
        if (validate()) {
            try {
                setIsProcessing(true);
                scrollToBottom();
                const response = await API.post('alplatformapi', '/reviews', postData);
                setValidPayment(true);
                setIsProcessing(false);
                setFormHasBeenSubmitted(true);
            } catch (e) {
                // TODO: Show some kind of error here
                setIsProcessing(false);
                console.log("Error making order: ", e.response);
            }
        }
    }

    return (
        <Dialog
            fullWidth={true}
            fullScreen={greaterThanSm ? false : true}
            open={true}
            onClose={onClose}
            className={`${classes.root}`}
            maxWidth={greaterThanMd ? 'xl' : false}
            onEntered={() => onDialogEntered()}
        >
            <DialogTitle>
            </DialogTitle>
            <DialogContent
                ref={windowRef}

                className={'style-4'}>
                {(showLoaded && show)
                    ?
                    <>
                        {!formHasBeenSubmitted &&
                            <div>
                                <Card variant="outlined" className={classes.descriptionCard}>
                                    <CardContent>
                                        <Typography variant="body1" component="p" className={classes.description}>
                                            Thank you for trying out {show.experience.longName}!  Please use this form to leave a small comment for Adventure Lab and your host {show.host.hostName}.  If you like, you can also show your appreciation to your host with a tip!
                                        </Typography>
                                    </CardContent>
                                </Card>

                                <div className={classes.contactForm} ref={formRef}>
                                    <div className={classes.flexAlign}>
                                        <TextField
                                            select
                                            label="Rating"
                                            helperText={"What score would you give this experience"}
                                            required
                                            value={form.rating}
                                            error={!!formErrors.rating}
                                            onChange={(e) => onChange('rating', e.target.value)}
                                            onBlur={() => formHasBeenSubmitted && validate()}
                                            style={{ marginTop: "1rem", marginBottom: "2%", width: "100%" }}
                                            InputLabelProps={{
                                                style: { color: '#000' },
                                            }}

                                        >
                                            {reviewOptions.map(option => (
                                                <MenuItem key={option.t} value={option.v}>
                                                    {option.t}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className={classes.flexAlign}>
                                        <TextField
                                                helperText="Leave any comments you don't mind showing on our public website"
                                                label="Public Comment/Review"
                                                multiline
                                                rows={3}
                                                type="textarea"
                                                value={form.commentForPublic}
                                                onChange={(e) => onChange('commentForPublic', e.target.value)}
                                                style={{ marginBottom: "2%", width: "100%" }}
                                                InputLabelProps={{
                                                    style: { color: '#000' },
                                                }}
                                        />
                                    </div>
                                    <div className={classes.flexAlign}>
                                        <TextField
                                            helperText="Leave any comments that you would like to share only with Adventure Lab"
                                            label="Private Message to Adventure Lab"
                                            multiline
                                            rows={3}
                                            type="textarea"
                                            value={form.commentForPrivate}
                                            onChange={(e) => onChange('commentForPrivate', e.target.value)}
                                            style={{ marginBottom: "2%", width: "100%" }}
                                            InputLabelProps={{
                                                style: { color: '#000' },
                                            }}
                                        />
                                    </div>
                                    <div className={classes.flexAlign}>
                                        <TextField
                                            helperText="Leave any comments that you would like to share directly with your host"
                                            label="Comment for your host"
                                            multiline
                                            rows={3}
                                            type="textarea"
                                            value={form.commentForHost}
                                            onChange={(e) => onChange('commentForHost', e.target.value)}
                                            style={{ marginBottom: "2%", width: "100%" }}
                                            InputLabelProps={{
                                                style: { color: '#000' },
                                            }}
                                        />
                                    </div>
                                    <div className={classes.flexAlign}>
                                        <FormControlLabel

                                            control={<Checkbox checked={form.leaveTip} onChange={(e) => onChange('leaveTip', e.target.checked)} name="leaveTip" />}
                                            label="I would like to leave a tip for my host"
                                            classes={{
                                                label: formErrors.readTOS ? classes.error : classes.controlLabel
                                            }}
                                            onBlur={() => formHasBeenSubmitted && validate()}
                                            style={{ marginBottom: "2%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <div>
                            {form.leaveTip && !validPayment
                                ?
                                <>
                                    <Payment {...{
                                        form, formErrors, onChange, formHasBeenSubmitted,
                                        validate, show, setIsProcessing, windowRef,
                                        setValidPayment, validSubmit, isReview: true, handlePostData, setFormHasBeenSubmitted,
                                        note: `Tip for ${show.experience.shortName} w/ ${show.host.hostName} @ ${show.showtime} for $${(form.amount / 100).toFixed(2)}`,
                                    }}
                                    />

                                    {isProcessing && <CircularProgress />}
                                </>
                                :
                                <>
                                
                                {validPayment && formHasBeenSubmitted &&
                                        <Typography variant="h4" align='center'>
                                            Thank you! Please check your email for your tip receipt.
                                        </Typography>
                                    }
                                {!formHasBeenSubmitted &&
                                <div style={{ marginBottom: "1%" }} className={classes.flexAlign}>
                                    <Button
                                        style={{ width: '50%' }}
                                        variant="contained"
                                        disabled={formHasBeenSubmitted}
                                        color="primary"
                                        onClick={handleSubmitWithNoTip}
                                    >
                                        Submit Review
                                    </Button>
                                </div>
                                }
                                </>
                            }

                            {formHasBeenSubmitted && !validPayment && 
                                <Typography variant="h4" align='center'>
                                    Thank you for submitting your comments!
                                </Typography>
                            }

                        </div>

                    </>
                    :
                    <Container>
                        <CircularProgress />
                    </Container>
                }
            </DialogContent>
        </Dialog>
    )
}

export default Review;