import React, { useReducer, useEffect } from 'react';

import UserContext from '../../Context/UserContext';
import HostViewContext from '../../Context/HostViewContext';
import NotificationsContext from '../../Context/NotificationsContext';

import hostViewInitialState from '../../Context/hostViewInitialState';
import hostViewReducer from '../../Context/hostViewReducer';

import notificationsInitialState from '../../Context/notificationsInitialState';
import notificationsReducer from '../../Context/notificationsReducer';

import userInitialState from '../../Context/userInitialState';
import userReducer from '../../Context/userReducer';

import HostViewOrAuth from './HostViewOrAuth.js';

import makeStyles from '@mui/styles/makeStyles';

import {Auth} from 'aws-amplify';

const useStyles = makeStyles(theme => ({
    hostViewContainer: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - calc(100vh - 100%))',
        width: '100vw',
        overflow: 'hidden'
    }
}))

function HostView() {
    const [hostViewState, hostViewDispatch] = useReducer(hostViewReducer, hostViewInitialState)
    const [notificationsState, notificationsDispatch] = useReducer(notificationsReducer, notificationsInitialState);
    const [userState, userDispatch] = useReducer(userReducer, userInitialState);
    const classes = useStyles();

    return (
        <div className={classes.hostViewContainer}>
            <UserContext.Provider  value={{ ...userState, ...{ dispatch: userDispatch }}}>
            <HostViewContext.Provider value={{ ...hostViewState, ...{ dispatch: hostViewDispatch }}}>
            <NotificationsContext.Provider 
                value={{ ...notificationsState, ...{dispatch: notificationsDispatch }}}
            >
                <HostViewOrAuth />
            </NotificationsContext.Provider>
            </HostViewContext.Provider>
            </UserContext.Provider>
        </div>
    );
}

export default HostView;
