import React, {useContext, useState, useRef, useEffect} from 'react';

import GuestViewContext from '../../../Context/GuestViewContext';
import crumb_image from '../../../images/crumbFeaturedShow.png';

import {
    Avatar,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AirplaneTicket, AttachMoney, Info, LocalActivity, People } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import Order from '../Order/Order';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const TIME_TILL_RELOAD_MS = 60000;

const useStyles = makeStyles((theme) => {
    return {
    root: {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary,
        textDecoration: 'none',
        filter: props => {
            if (props.getNumberOfTicketsLeft() === 0) {
                return 'grayscale(100%)'
            }
        },
        // minHeight: '250px',
        width: '90%',
        margin: '1% 0 0 0'
    },
    avatar: {
        outline: "10px solid " + theme.palette.primary 
    },
    avatarLabel: {
        
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    media: {
        // minHeight: '150px',
        paddingTop: 56.25 / 32 + '%', // 16:9
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    textTitle: {
        color: 'white',
        padding: '2%',
        fontWeight: 'normal',
        textDecoration: 'none',
        textShadow: '1px 1px 2px black',
        fontFamily: 'LuckiestGuy'
    },
    link: {
        textDecoration: 'none'
    },
    booked: {
        position: 'absolute',
        zIndex: 100,
        bottom: '0',
        left: '10%'
    },
    description: {
        whiteSpace: 'pre-wrap'
    },
    cardAction: {
        margin: '1em 0 0 0 !important',
        padding: '0 0 1em 0 !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    closeIcon: {
        position: 'absolute',
        top: '0.5%',
        right: '0.5%'
    },
    buyButton: {
        width: '50%',
    },
    cardContent: {
        padding: '0 !important',
        margin: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    cardContentInner: {
        padding: '1% 1%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    cardHeaderOveride: {
        [theme.breakpoints.between('md', 1000)]: {
            padding: 0
        },
    },
    cardActionInfo: {
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'relative',
        top: '1.0em',
    },
    cardAvatar: {
        [theme.breakpoints.between('md', 1000)]: {
            marginRight: 0
        },
    },
    container: {
        minWidth: '50vw',
    },
    iconBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    chip: {
        margin: '0 1% 0 1%',
    },
    title: {
        textAlign: 'center',
        marginRight: '25%',
        whiteSpace: 'nowrap'
    },
    subheader: {
        textAlign: 'center',
        marginRight: '25%'
    }
  };}
);


function EventBoardFeaturedShow({show}){
    const classes = useStyles({show, getNumberOfTicketsLeft});
    const guestViewState = useContext(GuestViewContext);
    const experience = show.experience;

    const [showOrder, setShowOrder] = useState(false);
    const greaterThanSm = useMediaQuery(theme => theme.breakpoints.up("sm"));

    function getNumberOfTicketsLeft(){
        const validOrders = show.orders?.items?.filter(order => order.status === "PAID");
        
        let totalTicketsPurchased = 0;
        validOrders.forEach(order => {
            totalTicketsPurchased += order.totalTicketsPurchased;
        })
        return show.experience.maxPlayers - totalTicketsPurchased;
    }

    function handleBuyShow(){
        guestViewState.dispatch({
            type: 'buyShowClicked',
            showToOrder: show
        })
        setShowOrder(true);
    }

    const [timeLoaded, setTimeLoaded] = useState(Date.now());

    return (<>
        <Card className={classes.root} raised={true}>
            {/* <CardHeader
                classes={{
                    root: classes.cardHeaderOveride,
                    avatar: classes.cardAvatar,
                    action: classes.cardActionInfo,
                    title: classes.title,
                    subheader: classes.subheader
                }}
                className={classes.cardHeader}
                title={`${show.displayTime.replace(":00", "")}`}
                subheader={show.host.hostName}
            >
            </CardHeader> */}

            <CardActionArea onClick={handleBuyShow} disabled={show.status !== 'OPEN'}>
                <CardMedia
                    image={crumb_image}
                    title="test"
                    className={classes.media}
                >
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: "center", fontFamily: 'LuckiestGuy'}}>
                        <Typography  variant={greaterThanSm ? "h3" : "subtitle2"} className={classes.textTitle}>
                            {experience.longName}
                        </Typography>
                        <Typography  variant={greaterThanSm ? "h3" : "subtitle2"} className={classes.textTitle}>
                            Special Featured Show for ${experience.featuredTicketPrice / 100}/ticket
                        </Typography>
                        <Typography  variant={greaterThanSm ? "h3" : "subtitle2"} className={classes.textTitle}>
                        {show.dayDescription} @ {show.displayTime}
                        </Typography>
                        <Typography  variant={greaterThanSm ? "h3" : "subtitle2"} className={classes.textTitle}>
                        Only {getNumberOfTicketsLeft()} Tickets Left!
                        </Typography>
                    </div>

                </CardMedia>
            </CardActionArea>
            
            <CardActions className={classes.cardAction}>
                {getNumberOfTicketsLeft() > 0 ?

                    <Button onClick={handleBuyShow} variant="contained" color="primary" className={classes.buyButton}>
                    <Typography align="center" variant="h6" style={{color: 'white'}}>
                        Buy Ticket
                    </Typography>
                    </Button>
                    :
                    <Typography align="center" variant="h6" color="primary" style={{width: '100%'}}>
                        SOLD OUT
                    </Typography>
                }
            </CardActions>
        </Card>
        
        {showOrder && <Order show={Date.now() - timeLoaded < TIME_TILL_RELOAD_MS ? guestViewState.showToOrder : null} onClose={()=>{setShowOrder(false)}} showIDForCache={show.id} isFeaturedShow={true}/>}
    </>)
}

export default EventBoardFeaturedShow;