import {
    Box,
    IconButton,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    InputLabel,
    FormControlLabel,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel, Delete, Edit, Save } from '@mui/icons-material';
import React, {useContext, useState, useEffect} from 'react';
import HostViewContext from '../../../Context/HostViewContext';
import DisplayPicture from '../DisplayPicture/DisplayPicture';
import UserContext from '../../../Context/UserContext';
import Auth from '@aws-amplify/auth';
import aws_exports from '../../../aws-exports';
import API, { graphqlOperation } from '@aws-amplify/api';
import { createHost, deleteHost, updateHost, updateUser } from '../../../graphql/mutations';
import { PhotoPicker } from 'aws-amplify-react';
import { Storage } from 'aws-amplify';
import crumb_icon from '../../../images/crumb_icon.png'
import { listHosts } from '../../../graphql/queries';


function HostProfileEditor(){
    const hostViewState = useContext(HostViewContext);
    const userState = useContext(UserContext);
    const [host, setHost] = useState(hostViewState.hosts[0]);

    const [image, setImage] = useState(false);
    const [showPhotoPicker, setShowPhotoPicker] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [profilePicture, setProfilePicture] = useState(userState ? userState.dbUser?.host?.profilePicture : null);
    const [isUploading, setIsUploading] = useState(false);

    let slackName = userState.dbUser?.host?.slackName;
    let bio = userState.dbUser?.host?.bio;
    let youTubeLink = userState.dbUser?.host?.youTubeLink;
    let hostName = userState.dbUser?.host?.hostName;
    let firstName = userState.dbUser?.host?.firstName;
    let lastName = userState.dbUser?.host?.lastName;
    let email = userState.dbUser?.host?.email;

    const [newSlackName, setNewSlackName] = useState(slackName);
    const [newBio, setNewBio] = useState(bio);
    const [newHostName, setNewHostName] = useState(hostName);
    const [newFirstName, setNewFirstName] = useState(firstName);
    const [newLastName, setNewLastName] = useState(lastName);
    const [newEmail, setNewEmail] = useState(email);
    const [newYouTubeLink, setNewYouTubeLink] = useState(youTubeLink);
    
    const [isCreateNewHost, setIsCreateNewHost] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

    async function handleHostChange(event) {
        const foundIndex = hostViewState.hosts.findIndex(host => event.target.value.id === host.id) || 0;
        const host = hostViewState.hosts[foundIndex];
        setHost(host);
        setNewBio(host.bio);
        setNewEmail(host.email);
        setNewHostName(host.hostName);
        setNewFirstName(host.firstName);
        setNewLastName(host.lastName);
        setNewYouTubeLink(host.youTubeLink);
        setProfilePicture(host.profilePicture);
        let url = null;
        if (host?.profilePicture?.key) {
            url = await Storage.get(host.profilePicture.key);
        }
        setPreviewImage(url);
        setIsCreateNewHost(false);
    }

    async function saveProfile(){
        const input = {
            bio: newBio,
            firstName: newFirstName,
            lastName: newLastName,
            slackName: newSlackName,
            hostName: newHostName,
            email: newEmail,
            youTubeLink: newYouTubeLink
        }

        if (profilePicture && Object.keys(profilePicture).length){
            input.profilePicture = profilePicture;
        }

        try {
            if (isCreateNewHost) {
                const handleCreateHost = await API.graphql(graphqlOperation(createHost, {input}));
                setIsCreateNewHost(false);
                // setExperience(experiences[experiences.length])
            } else {
                input.id = userState.dbUser.isAdmin ? host.id : userState.dbUser.host.id
                const handleUpdateHost = await API.graphql(graphqlOperation(updateHost, {input}));

                userState.dispatch({type: "host", host: handleUpdateHost.data.updateHost})
            }
            
            if (!userState.dbUser.isAdmin) {
                hostViewState.dispatch({type: "profileKey", profileKey: profilePicture.key})
            }

            hostViewState.dispatch({type: "showProfileEditor", showProfileEditor: false});
            await getHosts();
        } catch (e) {
            console.log("error updating host; ", e);
        }


    }

    function handleCloseProfileEditor(){
        hostViewState.dispatch({type: "showProfileEditor", showProfileEditor: false});
    }

    const useStyles = makeStyles((theme) => ({
        text: {
            width: '100%',
            margin: '1% 0 1% 0'
        },
        editButton: {
            position: 'absolute',
            bottom: '5%',
            right: '5%',
            background: 'white'
        },
        hostProfileContainer: {
            minWidth: '25vw'
        }
    }))

    function handleCreateNewHost(){
        setIsCreateNewHost(true);
        // setExperience(experience);

        setNewSlackName(null);
        setNewFirstName(null);
        setNewLastName(null);
        setNewBio(null);
        setNewHostName(null);
        setNewYouTubeLink(null);
        setNewEmail(null);
        setPreviewImage(null);
    }

    useEffect( () => {
        userState && setProfilePicture(userState?.dbUser?.host?.profilePicture);
        hostViewState.dispatch({type: "profileKey", profileKey: userState?.dbUser?.host?.profilePicture?.key})
    }, [userState.dbUser.host])

    async function getHosts(){
        try {
            let hosts = await API.graphql(graphqlOperation(listHosts));
            hosts = hosts.data.listHosts.items;
    
            hostViewState.dispatch({type: 'hosts', hosts: 
            hosts});

        } catch (e) {
            console.log("Error getting hosts: ", e)
        }
    }

    async function handleDeleteHost(){
        const input = {
            id: host.id
        };
        try {
            const handleDeleteHost = API.graphql(graphqlOperation(deleteHost, { input }))
            setShowConfirmationDialog(false);

            getHosts();
        } catch (e) {
            console.log('problem handling experience');
        }

    }

    useEffect(()=>{
        if (!hostViewState.hosts.length) {
            getHosts();
        }
    }, [])

    const classes = useStyles();

    async function onPickFile(file){
        setImage(file);
        setIsUploading(false);
        setShowPhotoPicker(false);
        const {identityId} = await Auth.currentCredentials();
        const filename = `${identityId}/${Date.now()}-${file.name}`;

        try {
            const uploadedFile = await Storage.put(filename, file.file, {contentType: file.type});

            const s3File = {
                key: uploadedFile.key,
                bucket: aws_exports.aws_user_files_s3_bucket,
                region: aws_exports.aws_project_region
            }
            setProfilePicture(s3File);
        } catch (e) {
            console.log("e uploading pictures: ", e)
        }

    }
    return (
        <>
            <Box 
                display="flex" 
                flexDirection="column" 
                justifyContent="space-around" 
                alignItems="space-between" 
                p={2}
                className={classes.hostProfileContainer}
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h4">
                        Profile Editor
                    </Typography>
                    <Box position="relative">
                        <DisplayPicture width="250px" height="250px" previewImage={previewImage}/>
                        <Button color="primary" className={classes.editButton} onClick={()=>setShowPhotoPicker(true)}>
                            <Edit fontSize="small"/> Edit
                        </Button>
                    </Box>
                    {userState.dbUser.isAdmin &&
                        <Box style={{display: 'flex', justifyContent: 'center'}}>
                            <Button color="primary" variant="contained" onClick={handleCreateNewHost}>Create new host</Button>
                        </Box>
                    }
                    <Dialog open={showPhotoPicker}>
                            <DialogTitle>
                                <Typography variant="h4">
                                    Profile Editor
                                </Typography>
                            </DialogTitle>
                            <PhotoPicker 
                                title="User Image"
                                preview="hidden"
                                onLoad={url => setPreviewImage(url)}
                                onPick={file => onPickFile(file)}
                                theme={{
                                    formContainer: {
                                        margin: 0,
                                        padding: '0.8em'
                                    },
                                    formSection: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    },
                                    sectionBody: {
                                        margin: 0,
                                        width: "250px"
                                    },
                                    sectionHeader: {
                                        padding: "0.2em"
                                    },
                                    photoPickerButton: {}
                                }}
                            />
                        <DialogActions>
                            <Button color="primary" onClick={()=>setShowPhotoPicker(false)}>
                                <Cancel /> Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {userState?.dbUser?.isAdmin &&
                        <div style={{width: '100%'}}>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <FormControl className={classes.experienceSelect}>
                            <InputLabel id="demo-simple-select-label">Hosts</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={host}
                                onChange={handleHostChange}
                            >
                                {
                                    hostViewState.hosts.map(host => (
                                        <MenuItem key={host.hostName} value={host}>{host.hostName}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                            </div>

                            <TextField
                                id="firstName-input"
                                label="firstName"
                                rows={10}
                                defaultValue=""
                                variant="outlined"
                                className={classes.text}
                                value={newFirstName || ''}
                                onChange={(event)=>setNewFirstName(event.target.value)}
                                style={{width: '50%'}}
                            />
                            <TextField
                                id="lastName-input"
                                label="lastName"
                                rows={10}
                                defaultValue=""
                                variant="outlined"
                                className={classes.text}
                                value={newLastName || ''}
                                onChange={(event)=>setNewLastName(event.target.value)}
                                style={{width: '50%'}}
                            />
                            </div>
                    }
                    <TextField
                        id="hostName-input"
                        label="hostName"
                        rows={10}
                        defaultValue=""
                        variant="outlined"
                        className={classes.text}
                        value={newHostName || ''}
                        onChange={(event)=>setNewHostName(event.target.value)}
                    />
                    {userState.dbUser.isAdmin &&
                        <TextField
                            id="email-input"
                            label="email"
                            rows={10}
                            defaultValue=""
                            variant="outlined"
                            className={classes.text}
                            value={newEmail || ''}
                            onChange={(event)=>setNewEmail(event.target.value)}
                        />
                    }
                    <TextField
                        id="bio-input"
                        label="bio"
                        multiline
                        rows={userState.dbUser.host ? 18 : 7}
                        defaultValue="Default Value"
                        variant="outlined"
                        className={classes.text}
                        value={newBio || ''}
                        onChange={(event)=>setNewBio(event.target.value)}
                    />
                    <TextField
                        id="youttubeInput"
                        label="youTubeLink"
                        defaultValue="YouTube Link to your bio"
                        variant="outlined"
                        value={newYouTubeLink || ''}
                        onChange={(event)=>setNewYouTubeLink(event.target.value)}
                        className={classes.text}
                    />
                    {userState.dbUser.isAdmin &&
                        <TextField
                            id="slackname input"
                            label="slackname"
                            defaultValue=""
                            variant="outlined"
                            value={newSlackName || ''}
                            onChange={(event)=>setNewSlackName(event.target.value)}
                            className={classes.text}
                        />
                    }
                </Box>
                        
                <Box display="flex" justifyContent="space-around">
                    <Button color="primary" onClick={() => saveProfile()}>
                        <Save /> Save
                    </Button>
                    <Button color="primary" onClick={handleCloseProfileEditor}>
                        <Cancel /> Cancel
                    </Button>
                    {!isCreateNewHost && userState.dbUser.isAdmin && 
                        <Button color="primary" onClick={() => {
                            setShowConfirmationDialog(true)}
                        }>
                            <Delete /> Delete
                        </Button>
                    }
                </Box>
                <Dialog open={showConfirmationDialog} onClose={() => setShowConfirmationDialog(false)}>
                <DialogTitle>
                    <Typography variant="body1">
                        Are you sure you want to delete {host?.hostName}?
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <>
                        <Button color="primary" onClick={handleDeleteHost}>
                            <Typography variant="button">
                                Yes
                            </Typography>
                        </Button>
                        <Button color="primary" onClick={() => setShowConfirmationDialog(false)}>
                            <Typography variant="button">
                                No
                            </Typography>
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
            </Box>
        </>
    )
}

export default HostProfileEditor