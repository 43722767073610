import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useContext, useState, useRef, useEffect} from 'react';

import { Save, Cancel, Repeat } from '@mui/icons-material'
import HostViewContext from '../../../Context/HostViewContext';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';

import moment from 'moment';

import { API, graphqlOperation } from 'aws-amplify';
import { getRecurringShow, getPrivateShow, getOrder } from '../../../graphql/queries';
import UserContext from '../../../Context/UserContext';
import { createRoomCode } from '../../../Common/Utility';
import { createShow, updateShow, createRecurringShow, createPrivateShow } from '../../../graphql/mutations';

import PrivateShowAutoComplete from '../PrivateShow/PrivateShowAutoComplete';

const useStyles = makeStyles(theme => ({
    title: {
        display: "flex",
        justifyContent: 'center'
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        }
    },
    progress: {
        position: 'absolute',
        top: '5%',
        right: '5%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    buttonRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: '1em',
        marginRight: '1em' 
    },
    chip: {
        margin: theme.spacing(1),
        width: '100%'
    },
    divs: {
        width: '100%',
        height: '100%',
        marginLeft: theme.spacing(1),
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    pickers: {
        width: 100,
    },
    rootTimePicker: {
        fontSize: '1em',
        display: 'none'
    },
    keyboardIcon: {
        fontSize: '1em'
    }
}))


function DialogCreateEdit({getShows, getPrivateShows}){
    const classes = useStyles();
    const hostViewState = useContext(HostViewContext);
    const userState = useContext(UserContext);
    const user = userState.dbUser;

    const selectedRow = hostViewState.selectedRow;
    let tzValue = hostViewState.timeZoneValue?.value || "America/Los_Angeles";
    const formRef = useRef();

    const isEdit = hostViewState.isShowEditMode;
    let showToEdit = null;
    let experience = null;
    let host = null;
    let previousShowDate = null;
    let order = null;
    let shouldBeFeaturedShow = null;
    if (isEdit) {
        showToEdit = hostViewState.shows[hostViewState.shows.findIndex(show => show.id === selectedRow.id)];
        experience = hostViewState.experiences[hostViewState.experiences.findIndex(experience => showToEdit.experience.id === experience.id)];
        host = hostViewState.hosts[hostViewState.hosts.findIndex(host => showToEdit.host.id === host.id)];
        previousShowDate = showToEdit.showtime;

        if (showToEdit.status === "BOOKED") {
            order = showToEdit.orders.items.find(order => order.status === "PAID");
        }
        shouldBeFeaturedShow = showToEdit.showType === "FEATURED" ? true : false;
    } else {
        experience = hostViewState.experiences[hostViewState.experiences.findIndex(experience => experience.shortName === 'Dr. Crumb')];
    }
    let momentDate = moment.utc(isEdit ? selectedRow.showtime : new Date()).tz(tzValue);

    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState(null);
    const [recurring, setRecurring] = useState(false);
    const [isPrivateShow, setIsPrivateShow] = useState(null);
    const [isFeaturedShow, setIsFeaturedShow] = useState(shouldBeFeaturedShow);
    const [privateShowInfo, setPrivateShowInfo] = useState(null);
    const [shouldCreateNewPrivateShow, setShouldCreateNewPrivateShow] = useState(false);
    const [onlyEditThisShow, setOnlyEditThisShow] = useState(false);
    const [everyOtherWeek, setEveryOtherWeek] = useState(null);
    const [recurringShows, setRecurringShows] = useState([]);
    const [showRecurringConfirmation, setShowRecurringConfirmation] = useState(false);
    const [showSingleShowConfirmation, setShowSingleShowConfirmation] = useState(false);
    const [howManyTimes, setHowManyTimes] = useState(1);
    const [compiledShowTime, setCompiledShowTime] = useState(momentDate);
    
    const [recurringDays, setRecurringDays] = useState(
        {"Mon": {active: false, day: 1}, "Tue": {active: false, day: 2}, "Wed": {active: false, day: 3}, "Thu": {active: false, day: 4}, "Fri": {active: false, day: 5}, "Sat": {active: false, day: 6}, "Sun": {active: false, day: 0}}
    );

    const MAX_REPEATS = 8;
    
    useEffect(() => {
        if (showToEdit && showToEdit.privateShowID) {
            async function fetchPrivateShow() {
                const privateShowInfo = await getPrivateShowInfo(showToEdit.privateShowID);
                setPrivateShowInfo(privateShowInfo);
                setIsPrivateShow(!!privateShowInfo);
            }

            fetchPrivateShow();

        }
        
    }, [showToEdit])

    function handleOnClose(){
        hostViewState.dispatch({type: 'shouldShowShowsCreateEdit', shouldShowShowsCreateEdit: false})
    }

    
    const [form, setForm] = useState({
        showtime: momentDate,
        time: momentDate,
        date: momentDate,
        price: isEdit ? selectedRow.price : 0,
        experience: experience,
        host: isEdit ? hostViewState.hosts[hostViewState.hosts.findIndex(host => showToEdit.host.id === host.id)] : '',
        privateShowName: '',
        privateShowDescription: ''
    })

    function onChange(key, event, isCheckbox=false) {
        if (!event) {
            console.error("event is invalid");
            return;
        }
        if (key === 'time' || key === 'date') {
            const showtime = moment.utc(new Date()).tz(tzValue);
            event = moment.utc(event).tz(tzValue);

            if (key === 'time') {
                showtime.set({
                    year: form.date.get('year'),
                    month: form.date.get('month'),
                    date: form.date.get('date'),
                    hour: event.get('hour'),
                    minute: event.get('minute'),
                    second: event.get('second'),
                    millisecond: event.get('millisecond'),
                });
            } else if (key === 'date') {
                showtime.set({
                    year: event.get('year'),
                    month: event.get('month'),
                    date: event.get('date'),
                    hour: form.time.get('hour'),
                    minute: form.time.get('minute'),
                    second: form.time.get('second'),
                    millisecond: form.time.get('millisecond'),
                });
            }
            setCompiledShowTime(showtime);
            setForm(Object.assign({}, form, { [key]: event }))
        } else {
            setForm(Object.assign({}, form, { [key]: isCheckbox ? event.target.checked : event.target.value }))
        }
    }

    function handleRecurringChange(index, event) {
        const recurringShowsCopy = [...recurringShows];
        recurringShowsCopy[index].checked = !recurringShowsCopy[index].checked;
        setRecurringShows(recurringShowsCopy);
    }
    
    function handleNoneClicked(){
        const recurringShowsCopy = recurringShows.map(show => ({...show, ...{checked: false}}));
        setRecurringShows(recurringShowsCopy);
    }
    
    function handleAllClicked(){
        const recurringShowsCopy = recurringShows.map(show => ({...show, ...{checked: true}}));
        setRecurringShows(recurringShowsCopy);
    }

    async function setupRecurringShows(){
        // Build our date array for easy checking
        if (!isEdit) {

            const activeDaysArray = new Array(7);
            for (let key in recurringDays) {
                activeDaysArray[recurringDays[key].day] = recurringDays[key].active;
            }
    
            const showDates = [];
            let currentWeekIteration = 0;
            let currentDay = compiledShowTime.day();
            let currentDate = compiledShowTime.clone();

            showDates.push(currentDate.clone())
            while (currentWeekIteration < howManyTimes) {
                while (currentDay < 7) {
                    currentDate = currentDate.add(1, "day");
                    let dayToCheck = currentDate.day();
                    if (activeDaysArray[dayToCheck]) {
                        showDates.push(currentDate.clone());
                    }
                    currentDay++;
                }
                if (everyOtherWeek) {
                    currentDate = currentDate.add(1, "week");
                }
                currentDay = 0;
                currentWeekIteration++;
    
            }
            setRecurringShows(showDates.map(date => {
                return ({
                    checked: true,
                    displayTime: date.format("MMM ddd Do YYYY hh:mm a"),
                    date
                })
            }
            ));
        } else {
            const nowDate = new moment(new Date());
            const tempRecurringShows = [];
            recurringShows.forEach(date => {
                let newDate = moment(date.date); 
                const diffNowDate = newDate.diff(nowDate, 'minutes');
                if (diffNowDate < 0) return;

                newDate.set({
                    hour: compiledShowTime.get('hour'),
                    minute: compiledShowTime.get('minute'),
                    second: compiledShowTime.get('second'),
                    millisecond: compiledShowTime.get('millisecond'),
                });

                tempRecurringShows.push({
                    checked: true,
                    displayTime: newDate.format("MMM ddd Do YYYY hh:mm a"),
                    date: newDate,
                    show: date.show
                })
            })

            setRecurringShows(tempRecurringShows)
        }
    
        setShowRecurringConfirmation(true);
    }

    async function saveRecurringShow(){
        setShowLoader(true);

        let recurringShowCreateResult;
        let privateShowResult;
        if (!hostViewState.isShowEditMode) {
            recurringShowCreateResult = await API.graphql(graphqlOperation(createRecurringShow, { input: { type: "RecurringShow" }}));
        }
        if (isPrivateShow && shouldCreateNewPrivateShow) {
            privateShowResult = await API.graphql(graphqlOperation(createPrivateShow, { input: 
                {
                    id: getPrivateShowID(),
                    type: "PrivateShow", 
                    name: form.privateShowName, 
                    description: form.privateShowDescription,
                    createdByUserID: user.id
                }
            }))
        }
    
        for (const recurringShow of recurringShows){
            if (!recurringShow.checked) continue;

            try {
                const input = {
                    showtime: recurringShow.date.toDate(),
                    hostID: user.isAdmin ? form.host.id : user.hostID,
                    status: "OPEN",
                    price: form.price,
                    createdByUserId: user.id,
                    experienceID: form.experience.id,
                    type: 'show'
                }

                
                if (isPrivateShow) {
                    input.showType = "PRIVATE";
                    if (shouldCreateNewPrivateShow) {
                        input.privateShowID = privateShowResult.data.createPrivateShow.id;
                    } else {
                        input.privateShowID = privateShowInfo.id;
                    }
                }

                if (hostViewState.isShowEditMode && recurringShow.show.status === "OPEN") {
                    let tzValue = hostViewState.timeZoneValue?.value || "America/Los_Angeles";
                    let momentDate = moment.utc(compiledShowTime);
                    const hours = momentDate.hours();
                    const minutes = momentDate.minutes();
                    recurringShow.date = moment.utc(recurringShow.date);
                    recurringShow.date.hours(hours);
                    recurringShow.date.minutes(minutes);

                    input.showtime = recurringShow.date.toDate();
                    input.id = recurringShow.show.id;

                    const updateShowResponse = await API.graphql(graphqlOperation(updateShow, { input }))
                } else if (!hostViewState.isShowEditMode){

                    input.roomCode = createRoomCode();
                    input.recurringShowID = recurringShowCreateResult.data.createRecurringShow.id;
                    input.showType = isFeaturedShow ? "FEATURED" : (isPrivateShow ? "PRIVATE" : "PUBLIC");
                    const result = await API.graphql(graphqlOperation(createShow, { input }))
                }
    
                handleClose();
                getShows();
                if (shouldCreateNewPrivateShow) {
                    getPrivateShows();
                }
                setShowLoader(false);
            } catch (e) {
                console.error("error saving booked show", e);
                setShowLoader(false);
                setErrors("Error Booking Show: " + JSON.stringify(e))
            }
        }
    }

    function getPrivateShowID(){
        const date = new Date();
        const isoDateString = date.toISOString().split("T")[0];
        // Using room code because the function returns a random 3 digit code already
        return `${isoDateString}-${createRoomCode()}`;
    }

    async function saveShow(){
        setShowLoader(true);
        try {
            const input = {
                showtime: moment.utc(compiledShowTime).toDate(),
                hostID: user.isAdmin ? form.host.id : user.hostID,
                status: "OPEN",
                price: form.price,
                createdByUserId: user.id,
                experienceID: form.experience.id,
                type: 'show'
            }
            if (isPrivateShow) {
                if (shouldCreateNewPrivateShow) {
                    let privateShowResult = await API.graphql(graphqlOperation(createPrivateShow, { input: 
                        {
                            id: getPrivateShowID(),
                            type: "PrivateShow", 
                            name: form.privateShowName, 
                            description: form.privateShowDescription,
                            createdByUserID: user.id
                        }
                    }))
                    input.privateShowID = privateShowResult.data.createPrivateShow.id;
                } else {
                    input.privateShowID = privateShowInfo.id;
                }
            }

            if (hostViewState.isShowEditMode) {
                input.id = selectedRow.id;
                input.status = showToEdit.status;
                
                if (showToEdit.status === "BOOKED") {
                    try {
                        await API.graphql(graphqlOperation(updateShow, { input }))

                        if (previousShowDate !== input.showtime) {
                            const postData = {
                                body: {
                                    show_id: input.id,
                                    duration: 60,
                                    datetime: input.showtime,
                                    time_zone: order.clientTimezone,
                                    host_name: host.hostName,
                                    host_slack_name: host.slackName,
                                    experience: input.experienceID,
                                    team_name: order.teamName,
                                    guest_calendar_event_id: showToEdit.guestCalendarEventId,
                                    host_calendar_event_id: showToEdit.hostCalendarEventId,
                                }
                            };

                            const showResponse = await API.post('alplatformapi', '/reschedule', postData);
                        }
                    } catch (error) {
                        console.log("error: ", error.response)
                    }
                } else {
                    await API.graphql(graphqlOperation(updateShow, { input }))
                }
                
            } else {
                input.roomCode = createRoomCode();
                input.showType = isFeaturedShow ? "FEATURED" : (isPrivateShow ? "PRIVATE" : "PUBLIC");
                const result = await API.graphql(graphqlOperation(createShow, { input }))
            }
            getShows();
            if (shouldCreateNewPrivateShow) {
                getPrivateShows();
            }
            handleClose()
            setShowLoader(false);
        } catch (e) {
            console.log("error saving booked show", e);
            setShowLoader(false);
            setErrors("Error Booking Show: " + JSON.stringify(e))
        }
    }
    
    function handleRecurringShowConfirmationCancel(){
        setShowLoader(false);
        setShowRecurringConfirmation(false);
    }

    function handleSingleShowConfirmationCancel(){
        setShowLoader(false);
        setShowSingleShowConfirmation(false);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if ((recurring || recurringShows.length) && !onlyEditThisShow) {
            setupRecurringShows();
        } else {
            setShowSingleShowConfirmation(true);
        }
    }

    async function getPrivateShowInfo(id){
        try {
            const privateShowInfo = await API.graphql(graphqlOperation(getPrivateShow, {id}));
            return privateShowInfo.data.getPrivateShow;

        } catch (e) {
            console.log("error getting private show", e);
        }

    }

    function handleClose(){
        hostViewState.dispatch({type: 'shouldShowMoreShowInfo', shouldShowMoreShowInfo: false})
        hostViewState.dispatch({type: 'shouldShowShowsCreateEdit', shouldShowShowsCreateEdit: false})
        hostViewState.dispatch({type: 'isShowEditMode', isShowEditMode: false})
    }

    function onPrivateShowChange(event, value){
        if (value.name === "Create New Private Show") {
            setShouldCreateNewPrivateShow(true);
        } else {
            setPrivateShowInfo(value);
        }
    }

    useEffect(() => {
        if (isEdit && showToEdit.recurringShowID) {
            ( async () => {
                try {
                    const recurringShowsResponse = await API.graphql(graphqlOperation(getRecurringShow, {id: showToEdit.recurringShowID}))
                    let recurringShows = recurringShowsResponse.data.getRecurringShow.shows.items
                    recurringShows = recurringShows.map(show => ({
                        show: show, 
                        checked: true,
                        displayTime: (() => {
                                let tzValue = hostViewState.timeZoneValue?.value || "America/Los_Angeles";
                                let momentDate = moment.utc(show.showtime);
                                let displayTime = momentDate.tz(tzValue).format("MMM ddd Do YYYY hh:mm a");
                                return displayTime;
                            })(),
                        date: moment.utc(show.showtime)
                        }))
                    setRecurringShows(recurringShows);
                } catch(e) {
                    console.log("error getting recurring shows:", e)
                }
            })()
        }
    }, [showToEdit])

    return <>
    <Dialog open={hostViewState.shouldShowShowsCreateEdit} onClose={handleOnClose}>
        <DialogTitle>
            <Typography variant="h5" component="p" className={classes.title}>
                Show {hostViewState.isShowEditMode ? "Edit" : "Create"}
            </Typography>
        </DialogTitle>
        <Divider light/>
        <DialogContent >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <>
                    <form className={classes.form} ref={formRef}>
                        <div>
                            <div>
                                <Grid alignContent="center">
                                    <Grid item xs={12} >
                                        <DatePicker
                                            required
                                            label={recurring || recurringShows.length > 0 ? "Recurring Shows Date" : "Show Date"}
                                            value={form.date}
                                            onChange={ (e) => onChange('date', e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TimePicker
                                            required
                                            label={recurring || recurringShows.length ? "Recurring Shows Time" : "Show Time ( XX:XX[PM|AM] )"}
                                            value={form.time}
                                            onChange={ (e) => onChange('time', e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                </Grid>
                                <Box className={classes.divs}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={isPrivateShow} 
                                                onChange={() => setIsPrivateShow(!isPrivateShow)}
                                            />
                                        }
                                        label="Private Show"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={isFeaturedShow} 
                                                onChange={() => setIsFeaturedShow(!isFeaturedShow)}
                                            />
                                        }
                                        label="Featured Show"
                                    />

                                    {isPrivateShow && 
                                        <PrivateShowAutoComplete 
                                            shouldCreateNewPrivateShow={shouldCreateNewPrivateShow}
                                            onChange={onChange}
                                            form={form}
                                            onPrivateShowChange={onPrivateShowChange}
                                            privateShowInfo={privateShowInfo}
                                            setShouldCreateNewPrivateShow={setShouldCreateNewPrivateShow}
                                            isEdit={isEdit}
                                        />
                                    }

                                    {!isEdit &&
                                        <>

                                            <FormControlLabel
                                                control={<Checkbox checked={recurring} onChange={() => setRecurring(!recurring)} name="checkedA" />}
                                                label="Recurring"
                                            />
                                            {recurring && 
                                                <FormControlLabel
                                                    control={<Checkbox checked={everyOtherWeek} onChange={() => setEveryOtherWeek(!everyOtherWeek)} name="checkedA" />}
                                                    label="Every other week"
                                                />
                                            }

                                            
                                        </>
                                    }
                                        {showToEdit?.recurringShowID && <>
                                            <Typography variant="h5">
                                                <Repeat fontSize='small'></Repeat> Recurring Show 
                                            </Typography>
                                        </>}
                                    {isEdit && showToEdit.recurringShowID &&
                                        <FormControlLabel
                                            control={<Checkbox checked={onlyEditThisShow} onChange={() => setOnlyEditThisShow(!onlyEditThisShow)} name="checkedA" />}
                                            label="Only edit this show"
                                        />}
                                </Box>

                            </div>
                            {recurring && 
                                <div>
                                    <div className={classes.buttonRow}>
                                        {
                                            ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(day => {
                                                return (
                                                    <Button
                                                        variant="contained"
                                                        color={recurringDays[day].active ? "primary" : "inherit"} 
                                                        onClick={
                                                            () => setRecurringDays({
                                                                ...recurringDays, 
                                                                ...{[day]: {...recurringDays[day], ...{active: !recurringDays[day].active}}}
                                                            })
                                                        }>
                                                        {day[0]}
                                                    </Button>
                                                )   
                                            })
                                        }
                                    </div>
                                    <FormControl className={classes.formControl}>
                                    <TextField
                                        id="standard-number"
                                        label="How many times to repeat this weekly pattern?"
                                        helperText="The pattern will start the next available day after the start date"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ min: 1, max: MAX_REPEATS }}
                                        value={howManyTimes}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            setHowManyTimes(value)
                                        }}
                                        onBlur={() => {
                                            let value = howManyTimes;
                                            value = Math.min(MAX_REPEATS, value);
                                            value = Math.max(1, value);
                                            setHowManyTimes(value)
                                        }}
                                    />
                                    </FormControl>
                                </div>
                            }
                        </div>
                        <TextField
                            select
                            label="Experience"
                            helperText=""
                            required
                            value={form.experience}
                            onChange={(e) => onChange('experience', e)}
                        >
                            {hostViewState.experiences.map(experience => (
                                <MenuItem key={experience.shortName} value={experience}>
                                    {experience.shortName}
                                </MenuItem>
                            ))}
                        </TextField>

                        {user.isAdmin && <TextField
                            select
                            label="Host"
                            helperText=""
                            required
                            value={form.host}
                            onChange={(e) => onChange('host', e)}
                        >
                            {hostViewState.hosts.map(host => (
                                <MenuItem key={host.hostName} value={host}>
                                    {host.hostName}
                                </MenuItem>
                            ))}
                        </TextField>}

                    {/* TODO: Add back when we want to play with price again
                        <TextField
                            disabled
                            required
                            helperText="" 
                            label="Show Price" 
                            min="0.00" 
                            step="any"
                            type="number" 
                            value={form.price} 
                            onChange={(e) => onChange('price', e)} 
                        /> */}
                    </form>
                    {showLoader && <div className={classes.progress}>
                        <CircularProgress />
                    </div>}
                    <div>
                        {errors}
                    </div>
                </>
            </LocalizationProvider>
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={handleSubmit}>
                <Save /> Save
            </Button>
            <Button color="primary" onClick={handleClose}>
                <Cancel /> Cancel
            </Button>
        </DialogActions>
    </Dialog>

    <Dialog open={showSingleShowConfirmation} fullWidth={true}>
        <DialogTitle>
            <Typography variant="h5">
                Save Show {hostViewState.isShowEditMode ? "Edit" : "Create"} Confirmation
            </Typography>
        </DialogTitle>
        <DialogContent>
            The following show will be {hostViewState.isShowEditMode ? "edited" : "created"}:
            <Typography variant="h5">
                {moment.utc(compiledShowTime).tz(tzValue).format("MMM ddd Do YYYY hh:mm a")}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={() => saveShow()}>
                <Save /> Save
            </Button>
            <Button color="primary" onClick={handleSingleShowConfirmationCancel}>
                <Cancel /> Cancel
            </Button>
        </DialogActions>
    </Dialog>

    <Dialog open={showRecurringConfirmation} fullWidth={true}>
        <DialogTitle>
            <Typography variant="h5" component="p" className={classes.title}>
                Recurring Show {hostViewState.isShowEditMode ? "Edit" : "Create"} Confirmation
            </Typography>
        </DialogTitle>
        <Divider light/>
        <DialogContent >
            <Typography variant="h4" component="p" className={classes.title}>
                The following shows will be {hostViewState.isShowEditMode ? "edited" : "created"}
            </Typography>
            <Typography variant="h6" component="p" className={classes.title}>
                Please uncheck any you do not want {hostViewState.isShowEditMode ? "edited" : "created"}
            </Typography>
            <Box display="flex" justifyContent="center" m={1}>
                    <Button color="primary" style={{margin: "0 1em 0 1em"}} variant="contained" onClick={handleAllClicked}>All</Button>
                    <Button color="primary" style={{margin: "0 1em 0 1em"}} variant="contained" onClick={handleNoneClicked}>None</Button>
            </Box>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid container item direction="column" justifyContent="center" alignItems="flex-start" xs={6}>
                    {
                      recurringShows
                            .slice(0, Math.ceil(recurringShows.length / 2))
                            .map((show, index) => (
                                    <Grid key={show.displayTime} item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={show.checked} onChange={(event) => handleRecurringChange(index, event)} name={show.displayTime} />}
                                            label={show.displayTime}
                                        />
                                    </Grid>
                                )
                            )
                    }
                </Grid>
                <Grid container item direction="column" justifyContent="center" alignItems="flex-start" xs={6}>
                    {
                       recurringShows
                            .slice(Math.ceil(recurringShows.length / 2))
                            .map((show, index) => (
                                <Grid key={show.displayTime} item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={show.checked} onChange={(event) => handleRecurringChange(index + Math.ceil(recurringShows.length / 2), event)} name={show.displayTime} />}
                                    label={show.displayTime}
                                />
                            </Grid>
                            ))}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={() => saveRecurringShow()}>
                <Save /> Save
            </Button>
            <Button color="primary" onClick={handleRecurringShowConfirmationCancel}>
                <Cancel /> Cancel
            </Button>
        </DialogActions>
    </Dialog>
</>;
}

export default DialogCreateEdit;