import { getTimeZones } from "@vvo/tzdb";
import moment from 'moment';
import 'moment-timezone';

const timeZone = moment.tz.guess();
const offset = moment.tz(timeZone).format('Z');
const label = `(GMT${offset}) ${timeZone.replace("_", " ")}`

const guestViewState = {
    shows: [],
    showPayForm: false,
    showGeneralInfo: true,
    showGeneralTicketInfo: true,
    timeZoneValue: {label: label, value: timeZone},
    showOrder: false,
    showToOrder: null,
    showCalendar: true,
    calendarValue: null,
    searchTerms: null,
    hostImages: {},
    experienceImages: {},
    rectangle: {}
}

export default guestViewState;